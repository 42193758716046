import {FieldArray} from 'redux-form';
import {Banner, Rule} from '@shipwell/shipwell-ui';
import {
  CompanyInternationalPreferencesCurrencyEnum,
  CustomFieldEntityTypesEnum,
  ShipmentChargeLineItem
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {GrandTotals} from '../types';
import RenderMultipleFinancials, {FinancialsColumnHeaders} from 'App/formComponents/renderMultipleFinancials';
import {formatCurrencyValue} from 'App/utils/globals';
import {formatCurrency} from 'App/utils/internationalConstants';
import '../_financials.scss';
import Loader from 'App/common/shipwellLoader';
import {useCustomFields, useSortedChargeCategories} from 'App/data-hooks';

export const NewFinancialsExpandedForm = ({
  prepaidTotal,
  mode,
  lineItemTotals,
  prepaidTotals,
  grandTotals,
  shipmentCurrency,
  changeField,
  chargeCodesValues,
  form
}: {
  prepaidTotal: number;
  mode: 'vendor' | 'customer';
  lineItemTotals: number[];
  prepaidTotals: number[];
  grandTotals: GrandTotals;
  shipmentCurrency: CompanyInternationalPreferencesCurrencyEnum;
  changeField: (form: string, field: string, value: string) => void;
  chargeCodesValues: ShipmentChargeLineItem[] | null;
  form: string;
}) => {
  const {data: chargeCategories, isLoading: isLoadingChargeCategories} = useSortedChargeCategories();
  const {customFields: shipmentChargeLineItemCustomFields} = useCustomFields(
    CustomFieldEntityTypesEnum.ShipmentChargeLineItem
  );
  if (shipmentCurrency && chargeCategories) {
    const visibleChargeLineItemCustomFields =
      shipmentChargeLineItemCustomFields?.filter((customField) => customField.ui_enabled) || [];
    const customFieldLabels = visibleChargeLineItemCustomFields?.map((customField) =>
      customField.required ? `${customField.label}*` : customField.label
    );

    // backend enforces each charge item to have the same currency, using the first one should be okay
    // failover to shipment.preferred_currency if for some reason no charge items have a currency
    const currency =
      chargeCodesValues?.find((chargeCode) => !!chargeCode.unit_amount_currency)?.unit_amount_currency ||
      shipmentCurrency;

    return (
      <div>
        {mode === 'vendor' ? (
          <div>
            <>
              <FinancialsColumnHeaders
                labels={['Charge Item', 'Description', ...customFieldLabels, 'Rate', 'Qty', 'Prepaid', 'Total'].map(
                  (str) => str.toUpperCase()
                )}
                mode={mode}
                additionalGridTemplateColumns
              />
              <Rule />
            </>
            <div className="new-financials-modal-form">
              <FieldArray
                name="financials"
                component={RenderMultipleFinancials}
                props={{
                  financialTotals: lineItemTotals,
                  prepaidTotals,
                  chargeCategories,
                  currency,
                  mode,
                  chargeLineItemCustomFields: visibleChargeLineItemCustomFields,
                  changeField,
                  additionalGridTemplateColumns: true,
                  chargeCodesValues,
                  form
                }}
              />
            </div>
          </div>
        ) : (
          <div>
            <>
              <FinancialsColumnHeaders
                labels={['Charge Item', 'Description', ...customFieldLabels, 'Rate', 'Qty', 'Total'].map((str) =>
                  str.toUpperCase()
                )}
                mode={mode}
                additionalGridTemplateColumns
              />
              <Rule />
            </>
            <div className="new-financials-modal-form">
              <FieldArray
                name="financials"
                component={RenderMultipleFinancials}
                props={{
                  financialTotals: lineItemTotals,
                  prepaidTotals,
                  chargeCategories,
                  currency,
                  mode,
                  chargeLineItemCustomFields: visibleChargeLineItemCustomFields,
                  changeField,
                  additionalGridTemplateColumns: true,
                  chargeCodesValues,
                  form
                }}
              />
            </div>
          </div>
        )}

        {mode === 'vendor' ? (
          <div className="mb-[70px] mt-3.5 rounded bg-sw-success/10 p-2 text-sm font-bold">
            <div className="m-0 flex p-0">
              <div className="flex-[1_1_75%] text-left">Shipment Total</div>
              <div className="flex-[1_1_25%] text-right text-base">{formatCurrency(grandTotals.vendor, currency)}</div>
            </div>
            <div className="m-0 flex p-0">
              <div className="flex-[1_1_75%] text-left text-sw-success">Amount Due to Carrier</div>
              <div className="flex-[1_1_25%] text-right text-base text-sw-success">
                {formatCurrency((grandTotals.vendor || 0) - prepaidTotal, currency)}
              </div>
            </div>
          </div>
        ) : null}

        {mode === 'customer' ? (
          <div className="mb-[70px] mt-3.5 rounded bg-sw-success/10 p-2 text-sm font-bold">
            <div className="m-0 flex p-0">
              <div className="flex-[1_1_75%] text-left"> Total Markup </div>
              <span className="flex-[1_1_25%] text-right text-base">
                {parseFloat((grandTotals.customerMarkup || 0).toString()) > 0 && '+'}
                {formatCurrency(grandTotals.customerMarkup, currency) +
                  ' (' +
                  formatCurrencyValue(grandTotals.customerMargin) +
                  '%)'}
              </span>
            </div>
            <div className="m-0 flex p-0">
              <div className="flex-[1_1_75%] text-left text-sw-success">Customer Total</div>
              <span className="flex-[1_1_25%] text-right text-base text-sw-success">
                {formatCurrency(grandTotals.customer, currency)}
              </span>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  if (isLoadingChargeCategories) {
    return <Loader loading />;
  }

  return <Banner title={'Error'}>There was an error loading financials. Try reloading the page.</Banner>;
};
