import {PropsWithChildren} from 'react';
import isNil from 'lodash/isNil';
import {compose} from 'recompose';
import {connect} from 'react-redux';
import {ShipmentMode} from '@shipwell/backend-core-sdk';
import {ShipmentModeEnum} from 'App/utils/globalsTyped';
import withFlags from 'App/utils/withFlags';
import InstantRatesCard from 'App/containers/Marketplace/components/InstantRates/legacy';
import DigitialFreightMarketplaceRatesCard from 'App/containers/Book/FormSections/InstantRatesCard';

interface InstantRatesCardStateProps {
  shipments?: {
    selectedShipment?: {
      mode?: ShipmentMode;
    };
    selectedRFQ?: {
      shipment_modes?: ShipmentMode[];
    };
  };
}

export interface InstantRatesCardWrapperProps {
  connDigitalFreightMarketplace?: boolean;
  shipmentModes?: ShipmentModeEnum[];
}

const InstantRatesCardWrapper = (props: PropsWithChildren<InstantRatesCardWrapperProps>) => {
  // TODO: handle multi mode shipments for users that ALSO have carrier integrations setup and enabled.
  if (
    props.connDigitalFreightMarketplace &&
    props.shipmentModes?.length === 1 &&
    props.shipmentModes.includes(ShipmentModeEnum.FTL)
  ) {
    return <DigitialFreightMarketplaceRatesCard />;
  }

  return <InstantRatesCard />;
};

/**
 * Maps shipmentModes determined between the shipment and the Request for Quote. If the shipment has no mode available
 * then the Request for Quote shipment mode(s) are used as the determining factor. If no mode(s) can be determined an empty
 * array is returned.
 */
const mapsStateToProps = (state: InstantRatesCardStateProps): {shipmentModes?: ShipmentModeEnum[]} => {
  // a shipment can only have ONE mode when created so we can validate a singular mode
  const shipmentMode: ShipmentModeEnum | undefined | null = state.shipments?.selectedShipment?.mode
    ?.code as ShipmentModeEnum;
  if (!isNil(shipmentMode)) {
    return {
      shipmentModes: [shipmentMode]
    };
  }
  // fall through to trying to look at the selected request for quote object.
  // RFQs can have more than one mode so we need to check for an array
  const rfqModes = state.shipments?.selectedRFQ?.shipment_modes;
  if (!isNil(rfqModes) && rfqModes.length) {
    return {
      shipmentModes: rfqModes.filter((mode) => !isNil(mode.code)).map((mode) => mode.code) as ShipmentModeEnum[]
    };
  }
  // just output to the console for easier troubleshooting if there are issues.
  console.error('Could not determine transporation mode from Shipment or RFQ.');
  return {
    shipmentModes: []
  };
};

export default compose<InstantRatesCardWrapperProps, InstantRatesCardWrapperProps>(
  withFlags('connDigitalFreightMarketplace'),
  connect(mapsStateToProps)
)(InstantRatesCardWrapper);
