import {Field, useFormikContext} from 'formik';
import {useCallback} from 'react';
import {Button, FormikSelect} from '@shipwell/shipwell-ui';

import {ContractFormValues} from '../constants';
import {ContractDefaultAccessorialsFields} from './components/ContractDefaultAccessorialsFields/ContractDefaultAccessorialsFields';
import {getAccessorialChargeTables} from 'App/api/rateTables/typed';

export function SelectAccessorialRateTableField() {
  const {values, setFieldValue} = useFormikContext<ContractFormValues>();

  const getAccessorialDetails = useCallback(async (q: string) => {
    const response = await getAccessorialChargeTables({q});
    return response?.results;
  }, []);

  const onAddAccessorial = () => {
    setFieldValue('defaultAccessorials', [
      ...(Array.isArray(values.defaultAccessorials) ? values.defaultAccessorials : []),
      {accessorial: {description: ''}, rate: '', description: ''}
    ]);
  };

  return (
    <>
      <div className="grid w-full grid-cols-4 items-center gap-2">
        <div className="col-span-3">
          <Field
            async
            name="accessorial_charge_table"
            label="Accessorial Charge Table"
            loadOptions={(q: string) => getAccessorialDetails(q)}
            getOptionLabel={(opts?: {name: string}) => opts?.name}
            getOptionValue={(opts?: {id: string}) => opts?.id}
            component={FormikSelect}
          />
        </div>
        <div className="col-span-1">
          <Button variant="tertiary" iconSide="left" iconName="AddCircleOutlined" onClick={onAddAccessorial}>
            Add Accessorial
          </Button>
        </div>
      </div>
      {values.defaultAccessorials && values.defaultAccessorials.length > 0 && <ContractDefaultAccessorialsFields />}
    </>
  );
}
