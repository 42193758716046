import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import {AxiosError} from 'axios';
import {
  FtlRate,
  FtlRateTransportationModeEnum,
  RateRequestFailure,
  ProviderCode,
  RateListResponseDataInner,
  TransportationMode,
  GenesisCommonSchemasCharge
} from '@shipwell/genesis-sdk';
import {Quote, RFQ, Shipment, ShipmentStatesValues, SlimRFQ} from '@shipwell/backend-core-singlerequestparam-sdk';
import {isNil, isUndefined, isNull} from 'lodash';
import invariant from 'tiny-invariant';
import {RatingApiCreateFtlRateRequestRatesFtlPostRequest} from '@shipwell/genesis-sdk/api';
import {updateRate, getRate} from 'App/api/genesis';
import {createRFQPromise} from 'App/api/quoting';
import {createCarrierConnectionQuote, getRfqDetails} from 'App/api/quoting/typed';
import {COMPANY_INTEGRATION_CARRIERS, RATING_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {transformFtlPayload} from 'App/utils/transformFtlPayload';
import {Rate} from 'src/@types/quotingTypes';
import {fetchEquipmentTypesPromise} from 'App/api/shipment/typed';
import {useUserMe} from 'App/data-hooks';
import {ShipmentModeEnum} from 'App/utils/globalsTyped';
import {createFtlRates, getRates} from 'App/api/genesis/typed';
import {getCapacityProvidersByMode} from 'App/api/integrations/typed';

const getScac = (providerCode: ProviderCode): {scac: string; displayText: string} => {
  const defaultDisplayScac = {
    scac: 'unknown',
    displayText: 'unknown'
  };
  const carrierIdentificationMap: {[key: string]: string} = {
    UBER: 'Uber Freight',
    AMAZON: 'Amazon Freight',
    CHROBINSON: 'CH Robinson'
  };

  if (isNil(providerCode)) {
    return defaultDisplayScac;
  }

  const carrierIdentificationCode: string = providerCode;
  return {
    scac: carrierIdentificationCode,
    displayText: carrierIdentificationMap[carrierIdentificationCode]
  };
};

export function transformFtlRate(ftlRate: FtlRate, rateRequestId: string, legacyRfqId?: string | null): Rate {
  const carrierScacInfo = getScac(ftlRate.provider_code);
  return {
    rateId: ftlRate.rate_id as string,
    rateRequestId,
    legacyRfqId,
    rate: {
      amount: ftlRate.charges?.total_charges?.amount ?? -1,
      currency: ftlRate.charges?.total_charges?.currency ?? 'USD'
    },
    charge_breakdown: ftlRate.charges.charge_breakdown?.map((charge) => transformChargeBreakDown(charge)),
    charge_total: {
      currency: ftlRate.charges.total_charges.currency,
      amount: ftlRate.charges.total_charges.amount
    },
    carrier: {
      scac: carrierScacInfo.scac,
      displayName: carrierScacInfo.displayText
    },
    transitDays: ftlRate.transit_time?.transit_days,
    deliveryDate: ftlRate.transit_time?.delivery?.earliest || ftlRate.transit_time?.delivery?.latest,
    pickupDate: ftlRate.transit_time?.pickup?.earliest || ftlRate.transit_time?.pickup?.latest,
    expirationDate: ftlRate.expiry,
    messages: ftlRate.messages,
    recommendations: ftlRate.recommendations,
    errors: ftlRate.messages?.flatMap((value) => value.detail)
  } as Rate;
}

const transformChargeBreakDown = (charge: GenesisCommonSchemasCharge) => {
  return {
    charge_code: charge.charge_code,
    description: charge.description,
    charge_detail: charge.charge_detail
  };
};

export function transformFtlRateError(error: RateRequestFailure): FtlRate {
  return {
    provider_code: error.provider_code,
    charges: {
      total_charges: {
        currency: '',
        amount: 0
      }
    },
    rate_id: error.rate_request_id,
    transportation_mode: FtlRateTransportationModeEnum.Ftl,
    capacity_provider: {
      identification_codes: [
        {
          qualifier: error?.carrier_identification.qualifier,
          value: error?.carrier_identification.value
        }
      ]
    },
    messages: [
      {
        detail: error.error
      }
    ]
  };
}

/**
 * react-query hook for creating a rate request against the rating and quoting api.
 * The query will continue requesting data from the rating and quoting API until
 * the response contains a body with valid data.
 *
 * The hook will not trigger until a valid rateRequestId parameter has been provided.
 * @param {string|undefined} rateRequestId the original identifier that was created when requesting rate quotes from carriers.
 * @param {string|undefined} legacyRfqId should come from backend-core
 * @param connections
 * @param options
 */
export const useRatesPolling = (
  rateRequestId?: string,
  legacyRfqId?: string | null,
  connections?: boolean[],
  options?: Omit<UseQueryOptions<unknown, AxiosError, Rate[], (string | undefined)[]>, 'queryKey'>
): UseQueryResult<Rate[], AxiosError> => {
  const refetchIntervalCallback = function (data: Rate[] | undefined): number {
    if (data?.length !== connections?.length) {
      return 1500;
    }
    return 0;
  };

  return useQuery(
    [RATING_QUERY_KEY, rateRequestId],
    async () => {
      if (rateRequestId === undefined) {
        return [];
      }
      const axiosResponse = await getRates(rateRequestId);
      const data: RateListResponseDataInner[] = axiosResponse.data?.data || [];
      const errors = axiosResponse.data?.errors || [];
      const ftlErrors = errors.map((error: RateRequestFailure) => transformFtlRateError(error));

      const rates: Rate[] = data
        .filter((value) => value.transportation_mode === 'FTL')
        .map<Rate>((value) => transformFtlRate(value as FtlRate, rateRequestId, legacyRfqId));

      ftlErrors.forEach((error: FtlRate) => {
        rates.push(transformFtlRate(error, rateRequestId, legacyRfqId));
      });

      return rates;
    },
    {
      ...options,
      refetchInterval: options?.refetchInterval ?? refetchIntervalCallback,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
      retry: 0
    }
  );
};

export const useConnectionsQuery = (
  options?: Omit<UseQueryOptions<unknown, AxiosError, boolean[], (string | undefined)[]>, 'queryKey'>
): UseQueryResult<boolean[], AxiosError> => {
  return useQuery(
    [COMPANY_INTEGRATION_CARRIERS],
    async () => {
      return getCapacityProvidersByMode(ShipmentModeEnum.FTL);
    },
    options
  );
};

export const getActiveConnections = (mode: TransportationMode) => {
  return getCapacityProvidersByMode(mode);
};

/**
 * Creates a new requset for rates from capacity providers without the need for a shipment and
 * returns the rate request id to poll for rates.
 *
 * A rate will be created in the new world rating and quoting system and optionally in the legacy system
 * utalizing the `useLegacyCreateRateRequestMutation` if `shipment.id` is present.
 */
export const useCreateRateRequestMutation = (
  options?: Omit<UseMutationOptions<{rfq?: RFQ; rateRequestId: string}, AxiosError, Shipment, unknown>, 'queryFn'>
): UseMutationResult<{rfq?: RFQ; rateRequestId: string}, AxiosError, Shipment, unknown> => {
  const legacyCreateRateRequestMutation = useLegacyCreateRateRequestMutation();
  const {data: {company: userCompany} = {}} = useUserMe();

  return useMutation(async (shipment) => {
    const connections = await getActiveConnections(ShipmentModeEnum.FTL);
    if (connections.length !== 0) {
      const equipmentTypesResp = await fetchEquipmentTypesPromise();
      const request: RatingApiCreateFtlRateRequestRatesFtlPostRequest = transformFtlPayload(
        equipmentTypesResp.data,
        shipment
      );
      const resp = await createFtlRates(request);
      const rateRequestId = resp.data?.id;
      if (rateRequestId === undefined || rateRequestId === null) {
        throw Error('Rate Request did not return an identifier.');
      }
      invariant(shipment.state, 'Missing required shipment state.');
      //The statuses where auto-generating an RFQ is allowed.
      //RFQ creation can trigger backend logic that updates the shipment status to 'quoting',
      //so this prevents us from also changing the shipment status as part of the mutation below.
      const rfqCreationStatuses: string[] = [ShipmentStatesValues.Quoting];
      // this is legacy logic to stitch together the legacy rating/quoting system and the new world
      // rating and quoting system.
      if (!isNil(shipment.id) && rfqCreationStatuses.includes(shipment.state)) {
        const matchedRfq = shipment.rfqs?.find((rfq) => rfq.company_owner_id === userCompany?.id);
        // can only create an RFQ in the legacy system when there is a shipment to tie it to and there are no matching RFQ exists
        if (!matchedRfq) {
          const rfq: RFQ = await legacyCreateRateRequestMutation.mutateAsync({
            autoquote: false,
            shipment: shipment.id,
            shipment_modes: shipment.mode ? [shipment.mode] : [],
            has_parent_rfq: false,
            parent_rfq: null as unknown as SlimRFQ // super gross casting. But backend-core complains if we dont send null...? strange.
          });

          return {rateRequestId, rfq};
        }
        //return the matching RFQ, use xCompanyId if rating on behalf of the customer
        const rfqId = matchedRfq?.id;
        const {customer: {id: customerId} = {}} = shipment;
        if (rfqId && customerId && userCompany) {
          try {
            const {data: rfq} = await getRfqDetails({
              rfqId,
              ...(userCompany.id !== customerId ? {xCompanyId: customerId} : {})
            });
            return {rateRequestId, rfq};
          } catch (error) {
            console.error(error);
          }
        }
      }
      return {rateRequestId};
    }
    throw Error('There is no enabling provider to obtain rates.');
  }, options);
};

export interface ActivateRateMutation {
  legacyQuote?: Quote;
  rate: Rate;
}
/**
 * Creates a rate appending to an request for rate in backend core. This function
 * is meant to stitch together genesis and backend-core which don't
 * communicate rates.
 */
export const useActiveRateMutation = (
  options?: Omit<UseMutationOptions<ActivateRateMutation, AxiosError, Rate, unknown | Promise<unknown>>, 'mutationFn'>
) => {
  return useMutation(async (variables: Rate) => {
    const resp = await getRate(variables.rateRequestId, variables.rateId);
    const ftlRate: FtlRate = resp.data;
    ftlRate.is_active = true; // set to active for new world logic
    const rateResp = await updateRate(variables.rateRequestId, variables.rateId, ftlRate);
    const rate = transformFtlRate(rateResp.data, variables.rateRequestId, variables.legacyRfqId);
    if (isUndefined(variables.legacyRfqId)) {
      return {
        rate
      };
    }
    const legacyQuote: Quote = await createCarrierConnectionQuote(variables);
    return {
      rate,
      legacyQuote
    };
  }, options);
};

/**
 * Creates a new request for rates from shipwell (only). Does not support
 * external capacity providers. An existing shipment is required before
 * the Request for quote can be sent.
 */
export const useLegacyCreateRateRequestMutation = (
  options?: Omit<UseMutationOptions<RFQ, AxiosError, RFQ, unknown>, 'mutationFn'>
): UseMutationResult<RFQ, AxiosError, RFQ, unknown> => {
  return useMutation(async (rfq: RFQ) => {
    const resp = await createRFQPromise(rfq);

    const rfqBody: RFQ = resp.body;
    if (isNull(rfqBody) || isNull(rfqBody?.id) || isUndefined(rfqBody?.id)) {
      throw Error('Could not create legacy RFQ');
    }
    return rfqBody;
  }, options);
};
