import {
  Modal,
  FormikTextInput,
  DeprecatedButton,
  FormikSelect,
  FormikDateTimePicker,
  Loader
} from '@shipwell/shipwell-ui';
import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Field, Formik} from 'formik';
import {array, object, string, TestContext} from 'yup';
import moment from 'moment';
import {halfHourOptions} from 'App/utils/dateTimeGlobalsTyped';
import AddressSearch from 'App/formComponents/fields/_addressSearch';
import {Rate} from 'src/@types/quotingTypes';

export interface InstantRateUpdateProps {
  shipment?: Shipment;
  show?: boolean;
  rate?: Rate;
  loading?: boolean;
  onUpdate: OnShipmentSelectUpdateEvent;
  close?: OnCloseModalEvent;
}

export type OnShipmentSelectUpdateEvent = (shipment: Shipment, rate: Rate | undefined) => void;

export type OnCloseModalEvent = () => void;

const validationSchema = object().shape({
  stops: array().of(
    object().shape({
      planned_date: string()
        .nullable()
        .test('planned_date', 'Date must be after previous stop dates.', function (plannedDate) {
          const {options} = this as Omit<TestContext, 'options'> & {
            options: {index: number; from: {value: Shipment}[]};
          };
          if (
            options.index > 0 &&
            moment(plannedDate, 'YYYY-MM-DD').isBefore(
              //from[1].value gives us the values of the entire shipment stops which we can use to validate against other stops
              moment(options.from[1].value.stops?.[options.index - 1]?.planned_date, 'YYYY-MM-DD')
            )
          ) {
            return false;
          }

          return true;
        })
        .required('A valid date is required.')
    })
  )
});

const InstantRatesUpdateModal = (props: InstantRateUpdateProps) => {
  return (
    <Modal title="Edit Shipment stops" show={props.show} footerComponent={null} onClose={props.close}>
      {props.loading ? (
        <Loader show={Boolean(props.loading)}>
          <span>Saving Shipment</span>
        </Loader>
      ) : null}
      <Formik
        initialValues={{
          id: props?.shipment?.id || '',
          ...props.shipment
        }}
        validationSchema={validationSchema}
        onSubmit={(shipment) => props.onUpdate(shipment, props.rate)}
      >
        {({handleSubmit}) => (
          <form onSubmit={handleSubmit}>
            <div className="divide-slate-100 grid grid-cols-1 divide-y">
              {props?.shipment?.stops?.map((stop, index) => (
                <div key={index}>
                  <div className="py-2">
                    <div className="flex">
                      <div className="w-6 rounded-full border-2 text-center">{index + 1}</div>
                      <span className="ml-2 font-bold">Stop {index + 1}</span>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-3 pb-3">
                    <Field
                      label={`${stop.is_pickup ? 'Pickup ' : 'Drop Off '} Location`}
                      name={`stops[${index}].location.address`}
                      disabled
                      component={AddressSearch}
                    />
                    <Field
                      label={`${stop.is_pickup ? 'Pickup ' : 'Drop Off '} Date`}
                      name={`stops[${index}].planned_date`}
                      component={FormikDateTimePicker}
                      showTimeSelect={false}
                      portalId="portal"
                    />
                    <Field
                      label="Appointment Type"
                      name={`stops[${index}].appointment_type`}
                      disabled
                      component={FormikTextInput}
                    />
                    <Field
                      label="Scheduled For Time"
                      name={`stops[${index}].scheduled_for_time`}
                      disabled
                      component={FormikTextInput}
                    />
                    <Field
                      label="Start Time"
                      name={`stops[${index}].planned_time_window_start`}
                      component={FormikSelect}
                      required
                      clearable={false}
                      options={halfHourOptions}
                      simpleValue
                      menuPortalTarget={document.body}
                      styles={{menuPortal: (base: {[prop: string]: string | number}) => ({...base, zIndex: 9999})}}
                    />
                    <Field
                      label="End Time"
                      name={`stops[${index}].planned_time_window_end`}
                      component={FormikSelect}
                      required
                      clearable={false}
                      options={halfHourOptions}
                      simpleValue
                      menuPortalTarget={document.body}
                      styles={{menuPortal: (base: {[prop: string]: string | number}) => ({...base, zIndex: 9999})}}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-end gap-2">
              <DeprecatedButton variant="secondary" type="button" onClick={props.close}>
                Cancel
              </DeprecatedButton>
              <DeprecatedButton type="submit" variant="primary">
                Save
              </DeprecatedButton>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default InstantRatesUpdateModal;
