import {useState} from 'react';
import {Card, CollapsibleCardContent} from '@shipwell/shipwell-ui';
import PropTypes from 'prop-types';
import InstantRatesTable from 'App/containers/InstantRates/components/InstantRatesTable';
import InstantRatesModal from 'App/containers/InstantRates/components/InstantRatesModal';

const InstantRatesCard = ({isNewShipmentForm, newShipmentFormValues}) => {
  const [showInstantRatesModal, setInstantRatesModal] = useState(false);

  const [selectedRowId, setSelectedRowId] = useState('');

  return (
    <>
      <Card title="Instant Rates" bodyClassName="p-0" isCollapsible>
        <CollapsibleCardContent>
          <InstantRatesTable
            viewAllColumns={false}
            isNewShipmentForm={isNewShipmentForm}
            selectedRowId={selectedRowId}
            setSelectedRowId={setSelectedRowId}
            expandedColumnSet
            newShipmentInformation={newShipmentFormValues}
          />
        </CollapsibleCardContent>
      </Card>

      <InstantRatesModal
        show={showInstantRatesModal}
        onClose={() => setInstantRatesModal(false)}
        isNewShipmentForm
        selectedRowId={selectedRowId}
        setSelectedRowId={setSelectedRowId}
        newShipmentInformation={newShipmentFormValues}
      />
    </>
  );
};

InstantRatesCard.propTypes = {
  isNewShipmentForm: PropTypes.bool,
  newShipmentFormValues: PropTypes.shape({
    transporation_mode: PropTypes.string,
    equipment: PropTypes.any,
    line_items: PropTypes.array,
    stops: PropTypes.array
  })
};

export default InstantRatesCard;
