import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Modal, SvgIcon} from '@shipwell/shipwell-ui';
import {
  AppointmentStatusEnum,
  Facility,
  FacilityAppointmentType,
  FacilityDock,
  LoadType,
  LoadTypeDockRuleMatchResults,
  ScheduledResourceTypeEnum
} from '@shipwell/tempus-sdk';
import cloneDeep from 'lodash/cloneDeep';
import invariant from 'tiny-invariant';
import moment from 'moment';

import {
  CalendarApi,
  EventClickArg,
  EventContentArg,
  EventDropArg,
  EventHoveringArg,
  EventInput
} from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import interactionPlugin, {DateClickArg, DropArg, EventDragStartArg} from '@fullcalendar/interaction';
import classNames from 'classnames';

import {
  FULL_CALENDAR_LICENSE_KEY,
  BigCalendarCurrentDateTimeId,
  FacilityAppointmentResourceId,
  BigCalendarAllDayEventClass
} from '../../constants';
import {
  AppointmentEntry,
  CalendarAppointmentEvent,
  ViewMode,
  Filters,
  AppointmentAvailabilityWindow,
  AppointmentAvailabilityRestriction
} from '../../../../data-hooks/appointments/types';
import {roundToAvailable, getAppointmentMinutesAndLoadTypeId} from '../../utils';
import {AppointmentVerbContext} from '../../AppointmentVerbContext';

import {AppointmentCalendarEntry} from './AppointmentCalendarEvent';
import {AllDayEventsToggle} from './AllDayEventsToggle';
import {
  MinimumCalendarAppointmentDurationMs,
  appointmentToEvent,
  fCalendarAppointmentPartitionKey,
  fixTZOffsetOfClickedTime,
  fixTZOffsetForFCBackward,
  fixTZOffsetForFCForward,
  updateEvents,
  fixTZOffsetOfInputTime
} from './full-calendar-utils';

import useCurrentTime from 'App/utils/hooks/useCurrentTime';
import {compareIds} from 'App/utils/cmp';

import './BigCalendar.scss';
import {groupBy, isNotNull, objectEntries, objectValues} from 'App/utils/betterTypedArrayMethods';
import {addMinutes, timeZoneAwareParse, withTimeOfDay, SECONDS_PER_DAY} from 'App/utils/dateTimeGlobalsTyped';
import {useAppointmentPerms} from 'App/data-hooks';
import {getFacilitiesShipmentMatching} from 'App/api/facilities';

export type BigCalendarProps = {
  facility: Facility | null;
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
  viewMode: ViewMode;
  setViewMode: (viewMode: ViewMode) => void;
  appointments: AppointmentEntry[];
  allDayAppointments: AppointmentEntry[];
  fcfsAppointments: AppointmentEntry[];
  availabilityWindows: AppointmentAvailabilityWindow[];
  availabilityRestrictions: AppointmentAvailabilityRestriction[];
  docks: FacilityDock[];
  filters: Filters;
  loadTypes: LoadType[];
  timeOfDayStart: Date;
  timeOfDayEnd: Date;
  draggingAppointment: AppointmentEntry | null;
  setDraggingAppointment: (x: AppointmentEntry | null) => void;
  selectedAppointment: AppointmentEntry | null;
  setSelectedAppointment: (x: AppointmentEntry | null) => void;
  setShowModal: (arg: {show: boolean; clickedTime: Date | null}) => void;
  openFCFS: () => void;
  apiRef?: (api: CalendarApi) => unknown;
  loadTypeDockRuleMatchResults?: LoadTypeDockRuleMatchResults;
};

const MillisecondsPerDay = 24 * 60 * 60 * 1000;

const BigCalendar = (props: BigCalendarProps) => {
  const {
    facility,
    selectedDate,
    setSelectedDate,
    viewMode,
    setViewMode,
    filters,
    appointments,
    allDayAppointments,
    fcfsAppointments,
    docks,
    availabilityWindows,
    availabilityRestrictions,
    // timeOfDayStart,
    // timeOfDayEnd,
    draggingAppointment,
    setDraggingAppointment,
    setSelectedAppointment,
    setShowModal,
    openFCFS,
    loadTypes,
    apiRef,
    loadTypeDockRuleMatchResults
  } = props;

  const [reschedulingOverride, setReschedulingOverride] = useState<{
    event: CalendarAppointmentEvent;
    revert: () => void;
  } | null>(null);

  const permissions = useAppointmentPerms();
  const {canRescheduleAppointments, canOverrideRestrictions} = permissions;
  const appointment = reschedulingOverride?.event.extendedProps.appointment;

  const calendarRef = useRef<FullCalendar | null>(null);
  const shipmentId =
    appointment?.scheduledResourceMetadata?.resource_type === ScheduledResourceTypeEnum.Shipment
      ? appointment.scheduledResourceMetadata?.shipment_id
      : '';

  useEffect(() => {
    if (calendarRef.current) {
      const api = calendarRef.current.getApi();
      apiRef?.(api);
    }
  });

  const [allDayExpanded, setAllDayExpanded] = useState(false);

  useEffect(() => {
    if (calendarRef.current) {
      const api = calendarRef.current.getApi();
      api.gotoDate(selectedDate);
    }
  }, [selectedDate]);

  const currentDateTime = useCurrentTime();

  const navLinkDayClick = useCallback(
    (date: Date) => {
      date = fixTZOffsetOfClickedTime(facility, date);
      setSelectedDate(date);
      setViewMode(ViewMode.Day);
      setSelectedAppointment(null);
    },
    [facility, setSelectedDate, setViewMode, setSelectedAppointment]
  );

  const currentDateEvent: EventInput = useMemo(() => {
    let end = new Date(currentDateTime);
    end.setMinutes(end.getMinutes() + 1);
    end = fixTZOffsetOfInputTime(facility, end);
    return {
      id: BigCalendarCurrentDateTimeId,
      start: fixTZOffsetOfInputTime(facility, currentDateTime),
      end,
      editable: false,
      title: 'The current date time.',
      display: 'block'
    };
  }, [facility, currentDateTime]);

  const dateClick = useCallback(
    (info: DateClickArg) => {
      let {date: clickedTime} = info;
      clickedTime = fixTZOffsetOfClickedTime(facility, clickedTime);
      const date = withTimeOfDay(clickedTime, '00:00', facility?.address.timezone);
      if (date.toISOString() !== selectedDate.toISOString()) {
        setSelectedDate(date);
      }
      setShowModal({clickedTime, show: true});
      setSelectedAppointment(null);
    },
    [facility, selectedDate, setSelectedDate, setShowModal, setSelectedAppointment]
  );

  const {setError, reschedule, setSelectedTab} = useContext(AppointmentVerbContext);

  const onDragStart = useCallback(
    (info: EventDragStartArg) => {
      const appointment = info.event.extendedProps.appointment as AppointmentEntry;
      setDraggingAppointment(appointment);
      setSelectedAppointment(null);
      setSelectedTab(null);
    },
    [setDraggingAppointment, setSelectedAppointment, setSelectedTab]
  );

  const draggingAppointmentRef = useRef(draggingAppointment);
  useEffect(() => {
    draggingAppointmentRef.current = draggingAppointment;
  }, [draggingAppointment]);

  const onDragStop = useCallback(() => {
    const dragStopAppointment = draggingAppointment;
    if (dragStopAppointment) {
      // We must delay clearing the dragging appointment or else there will be
      // no availability windows by the time `onEventDrop` is called.
      setTimeout(() => {
        if (draggingAppointmentRef.current === dragStopAppointment) {
          setDraggingAppointment(null);
        }
      }, 100);
    }
  }, [draggingAppointment, setDraggingAppointment]);

  const onEventDrop = useCallback(
    (info: EventDropArg) => {
      if (!canRescheduleAppointments) {
        setError('Unable to reschedule appointment', 'You are not authorized to reschedule appointments.');
        info.revert();
        return;
      }
      invariant(info.event.start != null);
      const appointment = info.event.extendedProps.appointment as AppointmentEntry;
      let event0: CalendarAppointmentEvent | null;
      // A block so that only `event0` which has it's dates repaired remains in scope
      {
        const eventStart = info.event.start;
        const eventEndIfAllDay = new Date(+info.event.start + SECONDS_PER_DAY * 1000);
        const eventEndIfNotAllDay = new Date(
          +info.event.start + Math.max(MinimumCalendarAppointmentDurationMs, appointment.durationMs || 0)
        );
        const eventEnd = info.event.allDay ? eventEndIfAllDay : eventEndIfNotAllDay;
        event0 = fixTZOffsetForFCBackward({
          id: info.event.id,
          resourceId: info.newResource?.id ?? FacilityAppointmentResourceId,
          start: eventStart,
          end: eventEnd,
          allDay: info.event.allDay,
          title: info.event.title,
          classNames: Array.from(info.event.classNames),
          editable: true,
          backgroundColor: info.event.backgroundColor,
          borderColor: info.event.borderColor,
          extendedProps: {
            appointment: info.event.extendedProps.appointment as AppointmentEntry,
            viewMode: info.event.extendedProps.viewMode as ViewMode
          }
        });
      }
      if (event0?.allDay && !event0.extendedProps.appointment.allDay) {
        info.revert();
        return;
      }
      const event = roundToAvailable(event0, availabilityWindows);
      try {
        if (event == null) {
          if (canOverrideRestrictions) {
            setReschedulingOverride({event: event0, revert: info.revert});
            return;
          }
          info.revert();
          return;
        }

        const {
          start,
          end,
          extendedProps: {appointment}
        } = event;

        if (!start || !end || !appointment) {
          info.revert();
          return;
        }

        const restrictions = availabilityRestrictions.filter(
          (r) =>
            r.startDate.original < end &&
            r.endDate.original > start &&
            ((!r.dockId && FacilityAppointmentResourceId === event?.resourceId) || r.dockId === event?.resourceId)
        );
        if (restrictions.length) {
          const r = restrictions
            .map((r) => ({...r, duration: r.endDate.original.getTime() - r.startDate.original.getTime()}))
            .reduce((a, b) => (a.duration > b.duration ? a : b));
          const {reason} = r;
          setError('Unable to reschedule appointment', reason);
          info.revert();
          return;
        }
        info.event.setStart(event.start);
        void reschedule(event, info.revert, event.resourceId);
      } finally {
        if (draggingAppointment?.id === info.event.id) {
          setDraggingAppointment(null);
        }
      }
    },
    [
      canOverrideRestrictions,
      setError,
      availabilityRestrictions,
      reschedule,
      availabilityWindows,
      draggingAppointment,
      setDraggingAppointment,
      canRescheduleAppointments
    ]
  );

  const resources = useMemo(
    () =>
      viewMode === ViewMode.Week
        ? []
        : docks.length > 0
        ? docks.map((dock) => ({id: dock.id, title: dock.name}))
        : [{id: FacilityAppointmentResourceId, title: ''}],
    [docks, viewMode]
  );

  const eventClick = useCallback(
    ({event}: EventClickArg) => {
      if (event.classNames.includes(BigCalendarAllDayEventClass)) {
        setSelectedAppointment(null);
        return;
      }
      if (event.classNames.includes('sw-fcfs-tally')) {
        openFCFS();
        return;
      }
      // NOTE that we do not use the event time fields and so we don't need a
      // call to `fixTZOffsetForFCBackward()` in this callback.
      const {appointment} = event.extendedProps as {appointment?: AppointmentEntry};
      setSelectedAppointment(appointment ?? null);
    },
    [setSelectedAppointment, openFCFS]
  );

  const {prefetchAvailability} = useContext(AppointmentVerbContext);
  const eventMouseEnter = useCallback(
    ({event}: EventHoveringArg) => {
      const {appointment} = event.extendedProps as {appointment?: AppointmentEntry};
      if (appointment) {
        prefetchAvailability(appointment);
      }
    },
    [prefetchAvailability]
  );

  const [sequenceNumber, setSequenceNumber] = useState(0);

  // Adjust event date based on DST
  const adjustEventForDST = useCallback((event: EventInput) => {
    if (!event.start || !event.end) return event;

    const eventStartDate = new Date(event.start as string);
    const adjustedStartDate = new Date(event.start as string);
    const adjustedEndDate = new Date(event.end as string);

    const isEventInDST = moment(eventStartDate).isDST();
    const currentDateOutsideDST = !moment(new Date()).isDST();
    const currentDateInsideDST = moment(new Date()).isDST();

    if (isEventInDST) {
      if (currentDateOutsideDST) {
        // Current time is outside DST, adjust event time to show correctly
        adjustedStartDate.setHours(adjustedStartDate.getHours() + 1);
        adjustedEndDate.setHours(adjustedStartDate.getHours() + 1);
      }
    } else {
      if (currentDateInsideDST) {
        // Current time is within DST, adjust event time to show correctly
        adjustedStartDate.setHours(adjustedStartDate.getHours() - 1);
        adjustedEndDate.setHours(adjustedStartDate.getHours() - 1);
      }
    }

    return {
      ...event,
      start: adjustedStartDate.toISOString(),
      end: adjustedEndDate.toISOString()
    };
  }, []);

  const events = useMemo(() => {
    const mapper0 = appointmentToEvent.bind(null, loadTypes, viewMode);
    const mapper = (appointment: AppointmentEntry) => {
      const event = mapper0(appointment);
      if (event) {
        event.extendedProps.filters = filters;
      }
      return event && fixTZOffsetForFCForward(event);
    };

    const predicate =
      viewMode === ViewMode.Week
        ? (event: CalendarAppointmentEvent | null) =>
            event &&
            (event.resourceId === FacilityAppointmentResourceId || filters.docks[event.resourceId]) &&
            (!event.extendedProps?.appointment?.deliveryType ||
              filters.deliveryTypes[event.extendedProps.appointment.deliveryType]) &&
            (!event.extendedProps?.appointment.matchedLoadTypeId ||
              filters.loadTypes[event.extendedProps.appointment.matchedLoadTypeId])
        : (event: CalendarAppointmentEvent | null) =>
            event &&
            event.resourceId !== FacilityAppointmentResourceId &&
            filters.docks[event.resourceId] &&
            (!event.extendedProps?.appointment.deliveryType ||
              filters.deliveryTypes[event.extendedProps.appointment.deliveryType]) &&
            (!event.extendedProps?.appointment.matchedLoadTypeId ||
              filters.loadTypes[event.extendedProps.appointment.matchedLoadTypeId]);

    const appointmentEvents = appointments.concat(fcfsAppointments).map(mapper).filter(predicate) as EventInput[];

    let availabilityEvents: EventInput[] = draggingAppointment
      ? availabilityWindows.map(({startDate, endDate, dockId}) =>
          fixTZOffsetForFCForward({
            start: new Date(startDate.original),
            end: new Date(endDate.original),
            resourceId: dockId ?? FacilityAppointmentResourceId,
            id: `available ${startDate.full} ${endDate.full} ${dockId ?? ''}`.replace(/\s+/g, '_'),
            classNames: ['sw-available-interval'],
            display: 'background',
            title: 'Available',
            backgroundColor: 'white',
            color: 'black',
            extendedProps: {
              appointment: {
                start: {
                  timezone: facility?.address?.timezone ?? 'UTC'
                },
                end: {
                  timezone: facility?.address?.timezone ?? 'UTC'
                }
              }
            }
          } as unknown as CalendarAppointmentEvent)
        )
      : [];

    const allDayEventKey = fCalendarAppointmentPartitionKey(viewMode);

    if (draggingAppointment?.allDay) {
      availabilityEvents = objectValues(groupBy(allDayEventKey, availabilityEvents)).map((group) => ({
        ...group[0],
        allDay: true
      }));
    }

    const allDayGroups: Record<string, CalendarAppointmentEvent[]> = groupBy(
      allDayEventKey,
      allDayAppointments.map(mapper).filter(predicate).filter(isNotNull)
    );

    const allDayEvents = objectEntries(allDayGroups).flatMap(([key, filtered]) => {
      const nFiltered = filtered.length;
      if (nFiltered === 0) return [];
      if (nFiltered === 1) return filtered;

      const title = `${nFiltered} All Day Appointments`;
      const allDayEvents: CalendarAppointmentEvent[] = [
        {
          ...filtered[0],
          id: `all-day-${key}`,
          title,
          resourceId: viewMode === ViewMode.Day ? key : '',
          allDay: true,
          classNames: [BigCalendarAllDayEventClass],
          editable: false,
          backgroundColor: '#0A6FDB', // blue man crew
          extendedProps: {
            ...filtered[0].extendedProps, // to make TS happy
            events: filtered,
            allDayExpanded,
            setAllDayExpanded
          }
        }
      ];
      return allDayEvents;
    });

    let events = appointmentEvents.concat(availabilityEvents).concat(allDayEvents);

    type TallyEntry = {
      appointment: AppointmentEntry;
      event: CalendarAppointmentEvent;
    };
    const tallyEntries: TallyEntry[] = events
      .filter((event) => !!event.extendedProps?.appointment)
      .map((event) => ({
        event: event as CalendarAppointmentEvent,
        appointment: event.extendedProps?.appointment as AppointmentEntry
      }));

    const fcfsTallies = tallyEntries.filter(
      ({appointment: {appointmentType}}) => appointmentType === FacilityAppointmentType.FirstComeFirstServe
    );
    const fcfsTallyGroups: Record<string, TallyEntry[]> = groupBy(({event}) => allDayEventKey(event), fcfsTallies);
    const fcfsTallyEvents = Object.entries(fcfsTallyGroups)
      .map(([key, entries]) => ({
        id: `fcfs-tally-${key}`,
        start: entries[0].appointment.start.timestamp,
        nCheckedIn: entries.filter(({appointment}) => appointment.checkedInAt).length,
        nTotal: entries.length
      }))
      .map(({id, start, nCheckedIn, nTotal}) => ({
        id,
        title: `${nCheckedIn} / ${nTotal}`,
        classNames: ['sw-fcfs-tally'],
        start,
        allDay: true,
        index: -1,
        // note: including sequenceNumber to satisfy the linter; sequenceNumber helps with a cleanup rerender
        extendedProps: {nCheckedIn, nTotal, sequenceNumber}
      }));

    events = events.concat(fcfsTallyEvents);

    // filter out FCFS until they're checked in
    events = events.filter((event) => {
      const a = event.extendedProps?.appointment as AppointmentEntry | undefined;
      return !a || a.appointmentType !== FacilityAppointmentType.FirstComeFirstServe || !!a.checkedInAt;
    });

    if (currentDateEvent) {
      const nDocks = docks.length;
      if (viewMode === ViewMode.Week || nDocks === 0) {
        events.push({
          ...currentDateEvent,
          classNames: ['sw-primary-current-time']
        });
      } else {
        for (let iDock = 0; iDock < nDocks; iDock++) {
          const dockId = docks[iDock].id;
          events.push({
            ...currentDateEvent,
            id: `${currentDateEvent.id ?? ''}_${dockId}`,
            resourceId: dockId,
            classNames: [iDock === 0 ? 'sw-primary-current-time' : 'sw-secondary-current-time']
          });
        }
      }
    }

    // `events` MUST be ordered by ID because `updateEvents(..)` requires that.
    events.sort(compareIds);

    // FIX events on DST
    return events.map(adjustEventForDST);
  }, [
    viewMode,
    docks,
    appointments,
    allDayAppointments,
    fcfsAppointments,
    loadTypes,
    allDayExpanded,
    currentDateEvent,
    availabilityWindows,
    draggingAppointment,
    facility?.address?.timezone,
    sequenceNumber,
    adjustEventForDST,
    filters
  ]);

  useEffect(() => {
    if (!calendarRef.current) {
      return;
    }
    const api = calendarRef.current.getApi();
    updateEvents(api, events as CalendarAppointmentEvent[]);
  }, [events]);

  const calendarView = useMemo(() => {
    if (viewMode === ViewMode.Week) {
      return 'timeGridWeek';
    }
    return 'resourceTimeGridDay';
  }, [viewMode]);

  useEffect(() => {
    if (calendarRef.current) {
      const api = calendarRef.current.getApi();
      api.changeView(calendarView);
    }
  }, [calendarView]);

  const [minTimeOfDayMs, maxTimeOfDayMs, hasTimeBoundEvent] = useMemo(
    () =>
      events.reduce(
        ([t0, t1, hasTimeBoundEvent]: [number, number, boolean], event): [number, number, boolean] => {
          if (event.allDay || !event.extendedProps) {
            return [t0, t1, hasTimeBoundEvent];
          }
          const {appointment} = (event as CalendarAppointmentEvent).extendedProps;
          if (!appointment || appointment.status === AppointmentStatusEnum.Unscheduled) {
            return [t0, t1, hasTimeBoundEvent];
          }
          const start = timeZoneAwareParse(appointment.start.timestamp, appointment.start.timezone);
          const end = timeZoneAwareParse(appointment.end.timestamp, appointment.end.timezone);
          const startTodMs = +start - +withTimeOfDay(start, '00:00:00', appointment.start.timezone);
          const endTodMs = +end - +withTimeOfDay(end, '00:00:00', appointment.end.timezone);
          return [Math.min(t0, startTodMs), Math.max(t1, endTodMs), true];
        },
        [MillisecondsPerDay, 0, false]
      ),
    [events]
  );

  // BEGIN auto-scrolling stuff

  // This doesn't work right now. That's OK because there is another ticket for
  // it that I can increase the story points for. I think I'm going to have to
  // write something yet more complicated to accomplish this.
  // - Joe 2023-09-18

  const [scrollState, setScrollState] = useState({
    // When we enter `doScrollToContent`, if the scroll top is not the same
    // value, that means that the user scrolled and we should NOT scroll.
    expectedScrollTop: 360
  });

  useEffect(() => {
    const scrollerEl = document.getElementById('div.fc-scroller');
    if (!scrollerEl) {
      return;
    }
    setScrollState({expectedScrollTop: scrollerEl.scrollTop});
  }, [selectedDate]);

  const doScrollToContent = useCallback(
    (minTimeOfDayMs: number, maxTimeOfDayMs: number) => {
      const KnownCalendarHeight = 1440;
      const scrollerEl = document.getElementById('div.fc-scroller');
      if (!scrollerEl) {
        return;
      }
      const scrollTop0 = scrollerEl.scrollTop;
      const boxHeight = scrollerEl.offsetHeight;
      const scrollMax = KnownCalendarHeight - boxHeight;

      if (scrollState.expectedScrollTop !== scrollTop0) {
        // The user scrolled. We should no longer scroll.
        setScrollState({
          expectedScrollTop: 0
        });
        return;
      }
      const yLow = (KnownCalendarHeight * minTimeOfDayMs) / MillisecondsPerDay;
      const yHigh = (KnownCalendarHeight * maxTimeOfDayMs) / MillisecondsPerDay - boxHeight;

      let scrollTop1 = scrollTop0;
      if (yLow >= scrollTop0 + boxHeight) {
        scrollTop1 = Math.min(yLow, scrollMax);
      } else if (yHigh < scrollTop0) {
        scrollTop1 = Math.max(yHigh, 0);
      }
      if (scrollTop1 !== scrollTop0) {
        scrollerEl.scrollTo({left: 0, top: scrollTop1, behavior: 'smooth'});
      }
      setScrollState({
        expectedScrollTop: scrollTop1
      });
    },
    [scrollState.expectedScrollTop]
  );

  const postCalendarRender = useCallback(() => {
    if (!hasTimeBoundEvent) {
      return;
    }

    doScrollToContent(minTimeOfDayMs, maxTimeOfDayMs);
  }, [doScrollToContent, hasTimeBoundEvent, maxTimeOfDayMs, minTimeOfDayMs]);

  const getAvailableDockId = async () => {
    const response = await getFacilitiesShipmentMatching({
      facilityId: facility?.id || '',
      shipmentId: shipmentId || '',
      stopId: appointment?.stopId || ''
    });

    return response.data?.matched_docks[0]?.dock_id;
  };

  const handleConfirm = async () => {
    invariant(reschedulingOverride);
    const {event, revert} = reschedulingOverride;

    if (event.extendedProps.appointment.dockId) {
      try {
        await reschedule(event, revert, event.resourceId, true);
      } catch {
        revert();
      }
    } else {
      const dockId = await getAvailableDockId();

      if (dockId) {
        const updatedEvent = cloneDeep(event);

        updatedEvent.extendedProps.appointment.dockId = dockId;

        try {
          await reschedule(updatedEvent, revert, event.resourceId, true);
        } catch {
          revert();
        }
      } else {
        setError(
          'Unable to find available dock!',
          "Unable to choose a dock for this appointment because there are no docks that can accommodate the appointment's load type"
        );
        revert();
      }
    }

    setReschedulingOverride(null);
  };

  // END auto-scrolling stuff

  return (
    <div id="sw-big-calendar" className="sw-big-calendar flex h-full flex-1">
      <FullCalendar
        windowResize={postCalendarRender}
        firstDay={0}
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin, resourceTimeGridPlugin]}
        headerToolbar={false}
        initialView={viewMode === ViewMode.Week ? 'timeGridWeek' : 'resourceTimeGridDay'}
        editable={false}
        selectable
        selectMirror
        dayMaxEvents={false}
        weekends
        allDaySlot
        slotEventOverlap={false}
        initialDate={selectedDate}
        initialEvents={events}
        dateClick={dateClick}
        eventContent={BigCalendarEvent} // custom render function
        resources={resources}
        navLinks
        navLinkDayClick={navLinkDayClick}
        rerenderDelay={1}
        schedulerLicenseKey={FULL_CALENDAR_LICENSE_KEY}
        slotLabelFormat={{
          hour12: false,
          hour: '2-digit',
          minute: '2-digit'
        }}
        eventDragStart={onDragStart}
        eventDragStop={onDragStop}
        eventDrop={onEventDrop}
        slotDuration="00:15:00"
        snapDuration="00:15:00"
        slotLabelInterval="01:00:00"
        expandRows
        dayHeaderClassNames="sw-fc-day-header"
        resourceGroupLabelClassNames="sw-fc-resource-header"
        eventClick={eventClick}
        eventMouseEnter={eventMouseEnter}
        // Passing the timezone to Full Calendar causes it to doubly apply
        // offsets and put calendar items at the wrong times :(
        // We work around that with the `fixTZOffset*()` family of functions
        // found in `./full-calendar-utils.ts`.
        timeZone={facility?.address?.timezone}
        droppable
        drop={(arg: DropArg) => {
          if (!draggingAppointment) {
            return null;
          }
          const revert = () => setSequenceNumber(sequenceNumber + 1);
          const startStr = arg.dateStr;
          const startDate = timeZoneAwareParse(startStr, draggingAppointment.start.timezone);

          const {minutes: durationInMinutes, matchingLoadTypeId} = getAppointmentMinutesAndLoadTypeId(
            draggingAppointment,
            loadTypes,
            loadTypeDockRuleMatchResults
          );
          const endDate = addMinutes(startDate, durationInMinutes);
          const updatedAppointmentWithStartAndEnd: AppointmentEntry = {
            ...draggingAppointment,
            start: {
              timezone: draggingAppointment.start.timezone,
              timestamp: startDate.toISOString()
            },
            end: {
              timezone: draggingAppointment.end.timezone,
              timestamp: endDate.toISOString()
            },
            durationMs: +endDate - +startDate,
            dockId: arg.resource?.id || '',
            matchedLoadTypeId: matchingLoadTypeId,
            allDay: loadTypes.find((loadType) => loadType.id === matchingLoadTypeId)?.all_day_appointment ?? false
          };
          const event = appointmentToEvent(loadTypes, viewMode, updatedAppointmentWithStartAndEnd);
          if (!event) {
            return null;
          }
          event.resourceId = updatedAppointmentWithStartAndEnd.dockId;
          const roundedEvent = roundToAvailable(event, availabilityWindows);
          try {
            if (roundedEvent == null) {
              if (canOverrideRestrictions) {
                setReschedulingOverride({event: event, revert: revert});
                return;
              }
              revert();
              return;
            }
            const {
              start,
              end,
              extendedProps: {appointment}
            } = roundedEvent;

            if (!start || !end || !appointment) {
              revert();
              return;
            }

            const restrictions = availabilityRestrictions.filter(
              (r) =>
                r.startDate.original < end &&
                r.endDate.original > start &&
                ((!r.dockId && FacilityAppointmentResourceId === roundedEvent?.resourceId) ||
                  (r.dockId && r.dockId === roundedEvent?.resourceId))
            );
            if (restrictions.length) {
              const r = restrictions
                .map((r) => ({...r, duration: r.endDate.original.getTime() - r.startDate.original.getTime()}))
                .reduce((a, b) => (a.duration > b.duration ? a : b));
              const {reason} = r;
              setError('Unable to reschedule appointment', reason);
              revert();
              return;
            }
            void reschedule(roundedEvent, revert, roundedEvent.resourceId);
          } finally {
            setDraggingAppointment(null);
          }
        }}
      />
      <Modal
        className="w-96"
        variant="warning"
        title="Warning"
        show={!!reschedulingOverride}
        primaryActions={
          <Button color="warning" variant="secondary" onClick={() => void handleConfirm()}>
            Yes, Book Appointment
          </Button>
        }
        secondaryActions={
          <Button
            color="primary"
            variant="secondary"
            onClick={() => {
              invariant(reschedulingOverride);
              const {revert} = reschedulingOverride;
              setReschedulingOverride(null);
              revert();
            }}
          >
            No, Go Back
          </Button>
        }
        onClose={() => {
          setReschedulingOverride(null);
        }}
      >
        <strong>Facility/Dock Capacity has been reached.</strong>
        <div className="text-justify">
          This appointment time either doesn&apos;t have capacity because the facility or the dock cannot accept any
          more loads. Would you like to override?
        </div>
      </Modal>
    </div>
  );
};

function BigCalendarEvent(props: EventContentArg) {
  const {event, timeText} = props;
  const appointment = event.extendedProps.appointment as AppointmentEntry;
  const filters = event.extendedProps.filters as Filters;
  const viewMode = event.extendedProps.viewMode as ViewMode;

  if (event.classNames.includes('sw-primary-current-time')) {
    return (
      <div id={event.id} className="sw-current-time">
        <SvgIcon name="CarrotRight" color="#b41d13" /> {/* red alert */}
      </div>
    );
  }
  if (event.classNames.includes('sw-secondary-current-time')) {
    return <div id={event.id} className="sw-current-time" />;
  }
  if (event.classNames.includes('sw-available-interval')) {
    return (
      <div className="sw-available-interval border-black m-5 h-full rounded-2xl border-4 border-dotted">&nbsp;</div>
    );
  }

  if (event.classNames.includes(BigCalendarAllDayEventClass)) {
    const {events, allDayExpanded, setAllDayExpanded} = event.extendedProps as {
      events: CalendarAppointmentEvent[];
      allDayExpanded: boolean;
      setAllDayExpanded: (allDayExpanded: boolean) => unknown;
    };
    return <AllDayEventsToggle events={events} allDayExpanded={allDayExpanded} setAllDayExpanded={setAllDayExpanded} />;
  }

  if (event.classNames.includes('sw-fcfs-tally')) {
    const {nCheckedIn, nTotal} = event.extendedProps as {nCheckedIn: number; nTotal: number};
    return (
      <div
        className={classNames(
          'fc-event-main-frame sw-fcfs-tally',
          'cursor-pointer',
          'bg-sw-background p-1 text-sw-info font-weight-normal',
          'rounded border-1 border-dashed border-sw-disabled-alternate'
        )}
      >
        <strong>
          {nCheckedIn}/{nTotal}
        </strong>{' '}
        &nbsp; FCFS Appts
      </div>
    );
  }

  if (appointment && viewMode) {
    return <AppointmentCalendarEntry appointment={appointment} viewMode={viewMode} filters={filters} />;
  }

  return (
    <div className="fc-event-main-frame">
      <div className="fc-event-time">{timeText}</div>
      <div className="fc-event-title-container">
        <div className="fc-event-title fc-sticky">{event.title}</div>
      </div>
    </div>
  );
}

export default BigCalendar;
