/* eslint-disable import/no-namespace */
import {Component} from 'react';
import {connect} from 'react-redux';
import {Form} from 'react-bootstrap';
import {reduxForm, change, formValueSelector, getFormValues, Field} from 'redux-form';
import Paper from '@material-ui/core/Paper';
import {compose, renameProp} from 'recompose';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import has from 'lodash/has';
import get from 'lodash/get';
import {DeprecatedButton, Dropdown, Checkbox} from '@shipwell/shipwell-ui';
import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import isEqual from 'lodash/isEqual';
import * as formActions from '../../actions/forms';
import * as actions from '../../actions/users';
import * as billingActions from '../../actions/billing';
import * as shipmentActions from '../../actions/shipments';
import * as authActions from '../../actions/auth';
import * as addressBookActions from '../../actions/addressBook';
import * as productActions from '../../actions/products';
import * as carrierActions from '../../actions/vendors';
// eslint-disable-next-line import/no-named-as-default
import StopsExpanded from './FormSections/StopsExpanded';
import {LineItems} from './FormSections/LineItems';
import Header from './FormSections/Header';
import References from './FormSections/References';
import {Financials} from './FormSections/Financials';
// eslint-disable-next-line import/no-named-as-default
import Carrier from './FormSections/Carrier';
import ModeEquipment from './FormSections/ModeEquipment';
import BillTo from './FormSections/BillTo';
import Customer from './FormSections/Customer';
import Multiplier from './FormSections/Multiplier';
import ShipmentTagsCard from 'App/components/shipmentTagsCard';
import * as brokerActions from 'App/actions/brokers';
import validate from 'App/utils/validateNewShipmentForm';
import warn from 'App/utils/warningsQuotingForm';
import ShipmentItemTotals from 'App/containers/quotes/create/components/shipmentItemTotals';
import {cleanPayload} from 'App/containers/quotes/create/utils/createQuote';
import {getPurchaseOrderByShipment} from 'App/containers/purchaseOrders/actions/async';
import {
  scrollToFirstErrorField,
  transformShipmentToForm,
  permViewOrders,
  permViewCarriers,
  isBillToFormEmpty,
  permCreateCarrierRelationship
} from 'App/utils/globals';
import {checkShipmentModes, calculateShipmentTotals} from 'App/utils/globalsTyped';
import {updateFormBillTo} from 'App/utils/shipmentFormHelpers';
import withFlags from 'App/utils/withFlags';
import textArea from 'App/formComponents/fields/textArea';
import {withCustomFieldsProvider} from 'App/data-hooks';
import {getCustomDataPath} from 'App/utils/customDataPath';
import getNil from 'App/utils/getNil';
import {userHasDefaultShipmentTags} from 'App/utils/shipmentTags';
import {allowedEquipmentTypes} from 'App/components/pricingIntelChart/pricingIntelConstants';
import {showPricingIntel} from 'App/containers/pricingIntel/utils';
import NewShipmentEmbeddedPricingIntel from 'App/components/NewShipmentEmbeddedPricingIntel';
import {defaultStopsFieldsLtlVltl} from 'App/formComponents/formSections/shipmentStopsFields/utils/constants';
import {
  SHIPMENT_ASSIGN_CARRIER,
  CREATE_SHIPMENT_DIRECT_TENDER,
  SHIPMENT_INITIATE_ROUTING_GUIDE,
  SHIPMENT_CREATE_SPOT_NEGOTIATIONS
} from 'App/components/permissions/PermissionsFallback/constants';
import {TenderingUserPermissionFallback} from 'App/components/permissions/PermissionsFallback/TenderingUserPermissionFallback';

@connect(
  (state) => ({
    newShipmentForm: state.form.newShipmentForm,
    goToStops: state.shipments.goToStops,
    company: state.auth.company,
    addressBook: state.addresses.addressbook,
    locationTypes: state.addresses.locationTypes,
    accessorials: state.shipments.accessorialCodes,
    user: state.auth.user,
    userCompany: state.userCompany,
    shipmentModes: state.shipments.shipmentModes,
    shipperRelationships: state.brokers.shipperRelationshipsForHeader,
    carrierRelationships: state.vendors.carrierRelationships,
    cloneTrigger: state.shipments.cloneTrigger,
    cloneShipment: state.shipments.cloneShipment,
    products: state.products.products,
    hazmatCodes: state.shipments.hazmatCodes,
    chargeCategories: state.shipments.chargeCategories,
    purchaseOrders: state.purchaseOrders.details,
    unitPreferences: state.userCompany.unitPreferences,
    values: getFormValues('newShipmentForm')(state),
    companyTags: getNil(state, 'shipments.tags', []),
    defaultShipmentTags: getNil(state, 'auth.user.default_shipment_tags', []),
    featureFlags: state.auth.company?.feature_flags
  }),
  {
    ...formActions,
    ...actions,
    ...billingActions,
    ...shipmentActions,
    ...authActions,
    ...addressBookActions,
    ...carrierActions,
    ...productActions,
    ...brokerActions,
    getPurchaseOrderByShipment
  }
)
class NewShipmentFormComponent extends Component {
  constructor(props) {
    super(props);
    this.dispatchMarkupChange = this.dispatchMarkupChange.bind(this);
    this.ignoreValidation = this.ignoreValidation.bind(this);
    this.cloneShipment = this.cloneShipment.bind(this);
    this.triggerBillToEdit = this.triggerBillToEdit.bind(this);
    this.changeVendor = this.changeVendor.bind(this);
    this.initialize = this.initialize.bind(this);
    this.shouldSetBillToOverride = this.shouldSetBillToOverride.bind(this);
    this.shouldResetBillToOverride = this.shouldResetBillToOverride.bind(this);
    this.calculateCarrierInsuranceAmount = this.calculateCarrierInsuranceAmount.bind(this);
    this.calculateIsInsuranceInvalid = this.calculateIsInsuranceInvalid.bind(this);
    this.setDefaultDeclaredValue = this.setDefaultDeclaredValue.bind(this);
    this.state = {
      isBroker: false,
      setCustomerDefault: false,
      setRefDefault: false,
      setMargin: '',
      cloning: false,
      setBillToDefault: false,
      showBillTo: false,
      showCustomerField: false,
      canViewOrders: false,
      canViewCarriers: false,
      canCreateCarrier: false,
      pushToLoadboard: false,
      isInsuranceInvalid: false
    };
  }

  componentDidMount() {
    const {
      company,
      values,
      shipmentModes,
      equipmentTypes,
      isEditForm,
      fiCarrierComplianceInsurance,
      decimalSupportForShipmentLineItems
    } = this.props;
    const purchaseOrdersEnabled = company && company.feature_flags && company.feature_flags.purchase_orders_enabled;
    // In case the user navigates from one draft shipment to another we clear stale references.
    if (isEditForm) {
      this.clearReferences();
    }

    // NOTE: fetch only when directly navigating, not reload
    // ApiKeyAuth.apiKey needs to exist
    // is valid if company is defined
    if (!isEmpty(this.props.company)) {
      this.initialize();
      if (this.props.user?.permissions?.includes('customer_relationships.view')) {
        this.initialFetchCustomers(this.props.company);
      }
      if (this.props.user?.permissions?.includes(permViewOrders)) {
        this.setState({canViewOrders: true});
      }
      if (this.props.user?.permissions?.includes(permViewCarriers)) {
        this.setState({canViewCarriers: true});
      }
      this.setState({canCreateCarrier: !!this.props.user?.permissions?.includes(permCreateCarrierRelationship)});
    }

    // if the necessary props data has loaded, set the default mode & equip
    if (shipmentModes && equipmentTypes) {
      this.setDefaultModeAndEquipment();
    }

    if (values && values.id && purchaseOrdersEnabled) {
      this.props.getPurchaseOrderByShipment(values.id);
    }

    if (
      this.props.newShipmentForm &&
      (!this.props.newShipmentForm.values || !this.props.newShipmentForm.values.customer_reference_number)
    ) {
      this.setState({setRefDefault: true});
    }
    if (this.shouldSetBillToOverride()) {
      if (this.props.user && this.props.company?.name && this.props.company?.billing_address) {
        const defaultBillTo = {};

        defaultBillTo.company_name = this.props.company.name;
        if (this.props.company.billing_address) {
          defaultBillTo.company_address = {
            formatted_address: this.props.company.billing_address.formatted_address
          };
        }

        defaultBillTo.direction = 'PREPAID';
        defaultBillTo.contact_email = this.props.company.primary_email;
        defaultBillTo.contact_phone = this.props.company.primary_phone_number;
        this.props.dispatch(change('newShipmentForm', 'bill_to_override', defaultBillTo));
        this.setState({setBillToDefault: true});
      }
    }

    if (this.props.cloneTrigger === true) {
      this.cloneShipment(this.props.cloneShipment);
      this.props.setCloneShipmentTrigger(false, {});
    }

    /***** COMMENTING THIS OUT SINCE IT IS FILLING IN THE FORM WHEN NAVIGATING FROM SHIPMENT DETAILS

    if (this.props.shipment && this.props.shipment.id) {
      console.log(this.props.shipment);
      this.cloneShipment(cleanPayload(this.props.shipment, {oldValue: ['', null, 'null', NaN], hardDelete: true}));
      this.props.setCloneShipmentTrigger(false, {});
    }
    ****/

    if (this.props.company && this.props.user?.permissions?.includes('customer_relationships.view')) {
      this.setState({isBroker: true});
    }
    if (!this.props.newShipmentForm.values.book_as_customer) {
      if (this.props.company && this.props.user?.permissions?.includes('customer_relationships.view')) {
        this.setState({isBroker: true, setCustomerDefault: true});
        if (this.props.shipperRelationships?.results?.length === 1) {
          //only set customer if there is only the house account
          this.props.dispatch(
            change('newShipmentForm', 'book_as_customer', {
              value: this.props.company.id,
              label: this.props.company.name
            })
          );
        }
      }
    }

    if (this.props.internationalPreferences) {
      const preferredCurrency =
        this.props.newShipmentForm.values.preferred_currency || this.props.internationalPreferences.currency;
      this.props.dispatch(change('newShipmentForm', 'preferred_currency', preferredCurrency));
    }

    this.props.clearSelectedShipment();
    if (fiCarrierComplianceInsurance) {
      this.setDefaultDeclaredValue();
    }

    //after all processes, set default stops (LTL and VLTL cases only)
    this.setDefaultStops();
  }

  componentDidUpdate(prevProps) {
    const {isEditForm, newShipmentForm, location, shipmentModes, equipmentTypes, fiCarrierComplianceInsurance} =
      this.props;
    const {pathname} = location;
    const {pathname: prevPathname} = prevProps.location;
    const pathnameHasChanged = prevPathname !== pathname;

    if (
      this.props.shipment &&
      prevProps.shipment &&
      this.props.shipment?.related_orders?.length !== prevProps.shipment.related_orders?.length
    ) {
      this.setReferences();
    }

    // wth ¯\_(ツ)_/¯
    if (
      (this.props.shipment?.id && prevProps.shipment && prevProps.shipment.id !== this.props.shipment.id) ||
      (isEditForm && newShipmentForm.values && !newShipmentForm.values.id && this.props.shipment?.id)
    ) {
      this.cloneShipment(cleanPayload(this.props.shipment, {oldValue: ['', null, 'null', NaN], hardDelete: true}));
      this.props.setCloneShipmentTrigger(false, {});
      this.props.getPurchaseOrderByShipment(this.props.shipment.id);
    } else if (pathnameHasChanged) {
      /*
        This is to handle the case where the user is navigating back and forth between a draft shipment, and the new shipment form, we can check the length of the pathname to determine this since all drafts have a UUID. References should be cleared on a new form, and set on a draft form.

        ex new shipment: /new-shipment-ftl
        ex draft shipment: /new-shipment-ftl/{UUID}
      */
      if (prevPathname.length < pathname.length) {
        this.setReferences();
      } else {
        this.clearReferences();
      }
    }
    // if mode hasn't been set but we have all the props data and there is a default mode, set the default mode & equip
    if (
      (!newShipmentForm.values?.mode || pathnameHasChanged) &&
      shipmentModes &&
      equipmentTypes &&
      this.getDefaultShipmentMode(location.pathname)
    ) {
      this.setDefaultModeAndEquipment();
    }

    if (
      this.props.internationalPreferences &&
      !isEqual(prevProps.internationalPreferences, this.props.internationalPreferences)
    ) {
      const preferredCurrency =
        this.props.newShipmentForm.values.preferred_currency || this.props.internationalPreferences.currency;
      this.props.dispatch(change('newShipmentForm', 'preferred_currency', preferredCurrency));
    }

    if (newShipmentForm.values?.preferred_currency !== prevProps.newShipmentForm.values?.preferred_currency) {
      const lineItems = newShipmentForm.values?.line_items?.map((lineItem) => ({
        ...lineItem,
        // do not alter the value_per_piece_currency if using a saved product that has a value_per_piece
        ...((!lineItem.products?.value?.id || (lineItem.products?.value?.id && !lineItem.value_per_piece)) && {
          value_per_piece_currency: newShipmentForm.values?.preferred_currency
        })
      }));
      if (lineItems?.length) {
        this.props.dispatch(change('newShipmentForm', 'line_items', lineItems));
      }
    }

    if (fiCarrierComplianceInsurance) {
      const isInvalid = this.calculateIsInsuranceInvalid();

      const calculateIsInsuranceInvalid = () => {
        if (
          !newShipmentForm?.values?.line_items ||
          !newShipmentForm?.values?.carrier ||
          !fiCarrierComplianceInsurance
        ) {
          return false;
        }

        const {value: totalShipmentValue} = calculateShipmentTotals({line_items: newShipmentForm?.values?.line_items});
        if (!totalShipmentValue || totalShipmentValue <= 0 || !selectedCarrier) {
          return false;
        }
        if (!selectedCarrier?.vendor?.insurance_records?.length) {
          return true;
        }

        const carrierInsuranceAmount = calculateCarrierInsuranceAmount(selectedCarrier?.vendor?.insurance_records);
        return carrierInsuranceAmount < totalShipmentValue;
      };

      if (isInvalid !== this.state.isInsuranceInvalid) {
        this.setState({isInsuranceInvalid: isInvalid});
      }
      this.setDefaultDeclaredValue();
    }
  }

  calculateCarrierInsuranceAmount(insuranceRecords) {
    return insuranceRecords.reduce((acc, {insurance_expires_at, insurance_limit, insurance_policy_type}) => {
      const insuranceExpirationDate = new Date(insurance_expires_at);
      const currentDate = new Date();
      if (insuranceExpirationDate > currentDate && insurance_policy_type === 'cargo') {
        return acc + parseInt(insurance_limit, 10);
      }
      return acc;
    }, 0);
  }

  /**
   * Checks that the assigned carrier has insurance and that the shipment value is greater than the carrier's insurance limit amount.
   * @returns {boolean} true if the bill to override form should be set
   */
  calculateIsInsuranceInvalid() {
    const {newShipmentForm, selectedCarrier} = this.props;
    if (
      !newShipmentForm ||
      !newShipmentForm.values ||
      !newShipmentForm.values.line_items ||
      !newShipmentForm.values.carrier
    ) {
      return false;
    }

    const {total_declared_value} = newShipmentForm.values;
    if (!total_declared_value || total_declared_value <= 0 || !selectedCarrier) {
      return false;
    }
    if (!selectedCarrier.vendor.insurance_records.length) {
      return true;
    }

    const carrierInsuranceAmount = this.calculateCarrierInsuranceAmount(selectedCarrier.vendor.insurance_records);
    return carrierInsuranceAmount < total_declared_value;
  }

  componentWillReceiveProps(nextProps) {
    // NOTE: fetch only on reload or account change
    if (nextProps.company && nextProps.company !== this.props.company) {
      this.initialize();
      if (nextProps.user?.permissions?.includes('customer_relationships.view')) {
        this.initialFetchCustomers(nextProps.company);
      }
      if (nextProps.user?.permissions?.includes(permViewOrders)) {
        this.setState({canViewOrders: true});
      }

      if (nextProps.user?.permissions?.includes(permViewCarriers)) {
        this.setState({canViewCarriers: true});
      }
      const canCreateCarrier = !!nextProps.user?.permissions?.includes(permCreateCarrierRelationship);
      if (canCreateCarrier !== this.state.canCreateCarrier) {
        this.setState({canCreateCarrier});
      }
    }

    if (nextProps.newShipmentForm?.values) {
      if (!nextProps.newShipmentForm.values.customer_reference_number && !this.state.setRefDefault) {
        this.setState({setRefDefault: true});
      }
      if (this.shouldSetBillToOverride()) {
        if (nextProps.company && nextProps.company.name && nextProps.company.billing_address && nextProps.user) {
          const defaultBillTo = {};

          defaultBillTo.company_name = nextProps.company.name;
          if (nextProps.company.billing_address) {
            defaultBillTo.company_address = {
              formatted_address: nextProps.company.billing_address.formatted_address
            };
          }
          defaultBillTo.direction = 'PREPAID';
          defaultBillTo.contact_email = nextProps.company.billing_emails?.[0] || nextProps.company.primary_email;
          defaultBillTo.contact_phone = nextProps.company.primary_phone_number;

          this.props.dispatch(change('newShipmentForm', 'bill_to_override', defaultBillTo));
          this.setState({setBillToDefault: true});
        }
      } else if (this.shouldResetBillToOverride()) {
        this.handleClearBillToForm();
        this.setState({setBillToDefault: false});
      }
      if (
        !nextProps.newShipmentForm.values.book_as_customer ||
        nextProps.newShipmentForm.values.book_as_customer === null
      ) {
        if (
          nextProps.company &&
          nextProps.user &&
          nextProps.user.permissions &&
          nextProps.user.permissions.includes('customer_relationships.view') &&
          !this.state.setCustomerDefault
        ) {
          this.setState({isBroker: true, setCustomerDefault: true});
          if (
            nextProps.shipperRelationships &&
            nextProps.shipperRelationships.results &&
            nextProps.shipperRelationships.results.length === 1
          ) {
            this.props.dispatch(
              change('newShipmentForm', 'book_as_customer', {
                value: nextProps.props.company.id,
                label: nextProps.props.company.name
              })
            );
          }
        }
      }
    }
    if (
      this.props.user &&
      nextProps.user.permissions &&
      nextProps.user.permissions !== this.props.user.permissions &&
      nextProps.user.permissions.includes('customer_relationships.view')
    ) {
      //then this is a broker
      this.setState({isBroker: true});
    }
    if (
      nextProps.newShipmentForm.values &&
      nextProps.newShipmentForm.values.mode &&
      (!this.props.newShipmentForm.values ||
        !this.props.newShipmentForm.values.mode ||
        this.props.newShipmentForm.values.mode !== nextProps.newShipmentForm.values.mode)
    ) {
      if (
        nextProps.newShipmentForm.values.mode &&
        (nextProps.newShipmentForm.values.mode === '2' || nextProps.newShipmentForm.values.mode === '4')
      ) {
        //mode was changed to LTL or VLTL, if there are no line items, dispatch one
        if (
          !nextProps.newShipmentForm.values.line_items ||
          (nextProps.newShipmentForm.values.line_items && nextProps.newShipmentForm.values.line_items.length === 0)
        ) {
          this.props.dispatch(
            change('newShipmentForm', 'line_items', [
              {
                length: '40',
                width: '48',
                package_type: 'PLT',
                length_unit: 'IN',
                weight_unit: 'LB',
                stackable: false
              }
            ])
          );
        }
      }
    }

    //if margin or markup changes, calculate the other

    if (this.props.markup !== nextProps.markup) {
      let carrierTotal = 0;
      let calculatedMargin = 0;
      if (nextProps.financialTotals && nextProps.financialTotals.length > 0) {
        carrierTotal = nextProps.financialTotals.reduce((acc, cur) => parseFloat(acc) + parseFloat(cur));
        calculatedMargin = parseFloat((parseFloat(nextProps.markup) / carrierTotal) * 100).toFixed(2);
        if (!isNaN(calculatedMargin) && isFinite(calculatedMargin)) {
          this.setState({setMargin: calculatedMargin + '%'});
        }
      }
    }

    //check if financial line items have changed, default descriptions when category is selected;
    if (
      this.props.newShipmentForm &&
      this.props.newShipmentForm.values &&
      this.props.newShipmentForm.values.financials &&
      nextProps.newShipmentForm &&
      nextProps.newShipmentForm.values &&
      nextProps.newShipmentForm.values.financials
    ) {
      const oldValues = this.props.newShipmentForm.values.financials;
      const newValues = nextProps.newShipmentForm.values.financials;

      for (var i = 0; i < newValues.length; i++) {
        if (
          newValues[i].category &&
          (!oldValues[i] ||
            (oldValues[i] && oldValues[i].category && newValues[i].category !== oldValues[i].category) ||
            (oldValues[i] && !oldValues[i].category))
        ) {
          if (
            !newValues[i].unit_name ||
            nextProps.chargeCategories.filter((c) => c.name === newValues[i].unit_name).length > 0
          ) {
            const description = nextProps.chargeCategories.filter((c) => c.id === newValues[i].category)[0].name;
            this.props.dispatch(change('newShipmentForm', 'financials[' + i + '].unit_name', description));
          }
        }
      }
    }
  }

  initialize() {
    this.props.getCarrierRelationships({
      page: 1,
      pageSize: 1000000,
      ordering: 'company'
    });
    this.props.fetchEquipmentTypes();
    this.props.fetchChargeCategories();
    this.props.fetchNMFCCodes();
    this.props.fetchServiceLevels();
    this.props.fetchShipmentModes();
    this.props.fetchAccessorialCodes();
    this.props.fetchAppointmentTypes();
    this.props.addressBookGet();
    this.props.getCompanyPreferences();
  }

  clearReferences = () => {
    const {dispatch, referencesCustomFields} = this.props;

    dispatch(change('newShipmentForm', 'name', ''));
    dispatch(change('newShipmentForm', 'bol_number', ''));
    dispatch(change('newShipmentForm', 'purchase_order_number', ''));
    dispatch(change('newShipmentForm', 'pickup_number', ''));
    dispatch(change('newShipmentForm', 'pro_number', ''));
    dispatch(change('newShipmentForm', 'drayage_booking_number', ''));
    dispatch(change('newShipmentForm', 'drayage_container_number', ''));
    dispatch(change('newShipmentForm', 'drayage_seal_number', ''));
    dispatch(change('newShipmentForm', 'drayage_house_bol_number', ''));
    dispatch(change('newShipmentForm', 'drayage_chassis_number', ''));
    dispatch(change('newShipmentForm', 'drayage_carrier_scac_code', ''));
    dispatch(change('newShipmentForm', 'customer_reference_number', ''));
    referencesCustomFields.length > 0 &&
      referencesCustomFields.forEach((cf) => {
        dispatch(
          change(
            'newShipmentForm',
            `custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.Shipment)}.${cf.id}`,
            ''
          )
        );
      });
  };

  setReferences = () => {
    const {dispatch, shipment, referencesCustomFields, location, values} = this.props;
    const isConsolidation = location.query.ordersConsolidated;

    // schedule-select is not part of the shipment object. is set in cloneShipment
    // need to set it here otherwise it gets overridden
    const stops = shipment?.stops?.map((stop, index) => {
      const scheduleSelect = values?.stops?.[index]?.['schedule-select'];
      return {
        ...stop,
        ...(scheduleSelect ? {'schedule-select': scheduleSelect} : {})
      };
    });

    dispatch(change('newShipmentForm', 'name', shipment?.name));
    dispatch(change('newShipmentForm', 'bol_number', shipment?.bol_number));
    dispatch(change('newShipmentForm', 'purchase_order_number', shipment?.purchase_order_number));
    dispatch(change('newShipmentForm', 'pickup_number', shipment?.pickup_number));
    dispatch(change('newShipmentForm', 'pro_number', shipment?.pro_number));
    dispatch(change('newShipmentForm', 'drayage_booking_number', shipment?.drayage_booking_number));
    dispatch(change('newShipmentForm', 'drayage_container_number', shipment?.drayage_container_number));
    dispatch(change('newShipmentForm', 'drayage_seal_number', shipment?.drayage_seal_number));
    dispatch(change('newShipmentForm', 'drayage_house_bol_number', shipment?.drayage_house_bol_number));
    dispatch(change('newShipmentForm', 'drayage_chassis_number', shipment?.drayage_chassis_number));
    dispatch(change('newShipmentForm', 'drayage_carrier_scac_code', shipment?.drayage_carrier_scac_code));
    dispatch(change('newShipmentForm', 'customer_reference_number', shipment?.customer_reference_number));
    referencesCustomFields.length > 0 &&
      !isConsolidation &&
      referencesCustomFields.forEach((cf) => {
        dispatch(
          change(
            'newShipmentForm',
            `custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.Shipment)}.${cf.id}`,
            shipment?.custom_data?.shipwell_custom_data?.shipment?.[cf.id]
          )
        );
      });
  };

  /**
   * Sets the declared value of the shipment based on the current userCompany preferences.
   * If the mode_preference field is enabled for the mode of the shipment then the `total_declared_value` field
   * will be set. If the `total_declared_value` field is already set then this function will do nothing.
   * @returns {void}
   */
  setDefaultDeclaredValue() {
    const {userCompany, dispatch, newShipmentForm} = this.props;
    if (!newShipmentForm || !newShipmentForm.values) {
      // when the shipment creation form is submit these values are empty. These are here to prevent errors on the redirect.
      return; // no form yet
    }
    if (newShipmentForm.values.total_declared_value) {
      return; // no need to set this value because there is one already that the user has entered
    }
    if (!userCompany) {
      return; // cannot set for the user
    }
    const {preferences} = userCompany;
    if (!preferences) {
      return; // cannot set for the user
    }
    const {mode_preferences} = preferences;
    if (!mode_preferences) {
      return; // cannot set for the user
    }
    const {mode} = newShipmentForm.values;
    if (!mode) {
      return; // cannot set for the user
    }
    const {hasDrayage, hasFTL, hasLTL, hasRail, hasVLTL} = checkShipmentModes(mode);
    let defaultDeclaredValue = null;
    if (hasFTL && mode_preferences.is_ftl_default_declared_value_enabled) {
      defaultDeclaredValue = mode_preferences.ftl_default_declared_value;
    } else if (hasLTL && mode_preferences.is_ltl_default_declared_value_enabled) {
      defaultDeclaredValue = mode_preferences.ltl_default_declared_value;
    } else if (hasVLTL && mode_preferences.is_vltl_default_declared_value_enabled) {
      defaultDeclaredValue = mode_preferences.vltl_default_declared_value;
    } else if (hasDrayage && mode_preferences.is_drayage_default_declared_value_enabled) {
      defaultDeclaredValue = mode_preferences.drayage_default_declared_value;
    } else if (hasRail && mode_preferences.is_rail_default_declared_value_enabled) {
      defaultDeclaredValue = mode_preferences.rail_default_declared_value;
    }
    if (!defaultDeclaredValue) {
      return; // there is no default declared value set for this mode
    }

    dispatch(change('newShipmentForm', 'total_declared_value', defaultDeclaredValue));
  }

  setDefaultModeAndEquipment = () => {
    const {shipmentModes = [], equipmentTypes = [], dispatch} = this.props;
    const defaultShipmentMode = this.getDefaultShipmentMode(location.pathname);
    const drayageModeId = shipmentModes.find((mode) => mode.code === 'DRAYAGE')?.id;
    const dryVanEquipmentTypeId = equipmentTypes.find((type) => type.machine_readable === 'DRY_VAN')?.id;

    if (defaultShipmentMode) {
      dispatch(change('newShipmentForm', 'mode', defaultShipmentMode));
      if (defaultShipmentMode !== drayageModeId) {
        dispatch(change('newShipmentForm', 'equipment_type', dryVanEquipmentTypeId));
      }
    }
  };

  setDefaultStops = () => {
    const {dispatch} = this.props;
    const defaultShipmentMode = this.getDefaultShipmentMode(location.pathname);
    if (defaultShipmentMode === 2 || defaultShipmentMode === 4) {
      dispatch(change('newShipmentForm', 'stops', defaultStopsFieldsLtlVltl));
    }
  };

  getDefaultShipmentMode = (pathName) => {
    const {shipmentModes = []} = this.props;
    switch (pathName) {
      case '/new-shipment-ftl':
        return shipmentModes.find((mode) => mode.code === 'FTL')?.id;
      case '/new-shipment-ltl':
        return shipmentModes.find((mode) => mode.code === 'LTL')?.id;
      case '/new-shipment-vltl':
        return shipmentModes.find((mode) => mode.code === 'VLTL')?.id;
      case '/new-shipment-drayage':
        return shipmentModes.find((mode) => mode.code === 'DRAYAGE')?.id;
      case '/new-shipment-intermodal':
        return shipmentModes.find((mode) => mode.code === 'INTERMODAL')?.id;
      case '/new-shipment-rail':
        return shipmentModes.find((mode) => mode.code === 'RAIL_CAR')?.id;
      default:
        return;
    }
  };

  async initialFetchCustomers(company) {
    const response = await this.props.fetchBrokerShipperRelationshipsForTypeaheads(company?.brokerage?.id ?? '', {
      page: 1,
      pageSize: 5,
      ordering: 'company',
      q: ''
    });
    if (response?.status === 200) {
      if (response.details?.results?.length > 1) {
        this.setState({showCustomerField: true});
      }
    }
  }

  dispatchMarkupChange(margin) {
    if (parseFloat(margin)) {
      let carrierTotal = 0;
      let calculatedMarkup = 0;
      if (this.props.financialTotals && this.props.financialTotals.length > 0) {
        carrierTotal = this.props.financialTotals.reduce((acc, cur) => parseFloat(acc) + parseFloat(cur));
        calculatedMarkup = parseFloat(parseFloat(margin / 100) * carrierTotal).toFixed(2);
        if (!isNaN(calculatedMarkup)) {
          this.props.dispatch(change('newShipmentForm', 'markup', calculatedMarkup));
        }
      }
    }
  }

  ignoreValidation(bool) {
    this.props.dispatch(change('newShipmentForm', 'ignoreValidation', bool));
  }

  changeVendor(vendor) {
    const vendorToUse = {label: vendor.name, value: vendor.id};
    this.props.dispatch(change('newShipmentForm', 'carrier', vendorToUse));
  }

  cloneShipment(shipment) {
    const {shipmentModes = []} = this.props;
    let rfqs = [];
    if (shipment.rfqs) {
      rfqs = JSON.parse(JSON.stringify(shipment.rfqs));
    }
    let mode = '';
    let equipment_types = [];
    if (rfqs.length > 0) {
      //for LTL shipmentToClones, the parent rfq should be null on the parent rfq obj
      //filter down to the parent RFQ on this page
      rfqs = rfqs.filter((e) => e.has_parent_rfq === false);
      rfqs = rfqs.sort(function (a, b) {
        return a.updated_at > b.updated_at ? -1 : 1;
      });
      const lastRFQ = rfqs[0];
      if (lastRFQ.autoquote === true) {
        mode = '2';
      } else {
        mode = '1';
      }
      if (lastRFQ.shipment_modes && lastRFQ.shipment_modes.length > 0) {
        //just take the first one in the list
        mode = lastRFQ.shipment_modes[0].id;
      }
      if (lastRFQ.equipment_types) {
        equipment_types = lastRFQ.equipment_types;
      }
    }
    //if MODE or Equipment are on the shipment itself, use that as the data to clone
    if (shipment.mode) {
      mode = shipment.mode.id;
    }
    if (shipment.equipment_type) {
      equipment_types = [shipment.equipment_type];
    }

    const shipmentToClone = transformShipmentToForm(
      JSON.parse(JSON.stringify(shipment)),
      this.props.hazmatCodes,
      mode,
      equipment_types,
      false,
      true
    );

    if (shipmentToClone.carrier_assignment) {
      const carrierToAssign = {
        label: shipmentToClone.carrier_assignment.carrier.display_name,
        value: shipmentToClone.carrier_assignment.carrier.id
      };
      if (carrierToAssign) {
        shipmentToClone.carrier = carrierToAssign;
      }
    }
    if (shipmentToClone.stops) {
      shipmentToClone.stops = shipmentToClone.stops.map(
        ({confirmed_arrival_at, confirmed_departure_at, ...stop}) => stop
      );
      const isConsolidation = this.props.location.query.ordersConsolidated;
      if (isConsolidation) {
        //we want to set this field deliberately when user lands here after order consolidation
        shipmentToClone.stops.forEach((stop) => (stop['schedule-select'] = 2));
      }
    }
    this.setState({cloning: true}, () => {
      //first reset the current form
      this.props.reset();

      if (this.props.isEditForm) {
        this.props.dispatch(change('newShipmentForm', 'id', shipment.id));
      }

      // map shipment tag ids and user default tag ids to full tag objects and dispatch the changes to form values
      const {companyTags, defaultShipmentTags} = this.props;
      const userDefaultShipmentTags = userHasDefaultShipmentTags({companyTags, defaultShipmentTags})
        ? companyTags.filter(({id}) => defaultShipmentTags.includes(id))
        : [];
      const shipmentTags =
        shipmentToClone.metadata?.tags?.length > 0
          ? shipmentToClone.metadata.tags.map((tag) => (tag.id ? tag : companyTags.find(({id}) => id === tag)))
          : [];

      this.props.dispatch(
        change('newShipmentForm', 'metadata', {
          ...shipmentToClone.metadata,
          tags: uniqBy([...shipmentTags, ...userDefaultShipmentTags], 'id')
        })
      );

      //dispatch the changes
      if (shipmentToClone.stops?.length > 0) {
        const shipmentStopsWithoutArrivalDeparture = shipmentToClone.stops.map((stop) => {
          return {
            ...stop,
            unconfirmed_arrival_at: null,
            unconfirmed_departure_at: null,
            confirmed_arrival_at: null,
            confirmed_departure_at: null,
            trip_management_eta: null,
            trip_managent_eta_last_updated: null,
            status: null
          };
        });
        this.props.dispatch(change('newShipmentForm', 'stops', shipmentStopsWithoutArrivalDeparture));
      }

      this.props.dispatch(change('newShipmentForm', 'book_as_customer', shipmentToClone.book_as_customer));
      if (shipmentToClone.mode) {
        this.props.dispatch(change('newShipmentForm', 'mode', shipmentToClone.mode));
      } else {
        //default to FTL
        this.props.dispatch(change('newShipmentForm', 'mode', shipmentModes.find((mode) => mode.code === 'FTL')?.id));
      }
      this.props.dispatch(change('newShipmentForm', 'equipment_type', shipmentToClone.equipment_type));
      this.props.dispatch(
        change('newShipmentForm', 'temperature_lower_limit', shipmentToClone.temperature_lower_limit)
      );
      this.props.dispatch(
        change('newShipmentForm', 'temperature_upper_limit', shipmentToClone.temperature_upper_limit)
      );

      this.props.dispatch(change('newShipmentForm', 'accessorials', shipmentToClone.accessorials));
      this.props.dispatch(change('newShipmentForm', 'service_level', shipmentToClone.service_level));
      if (shipmentToClone.bill_to_override) {
        this.props.dispatch(change('newShipmentForm', 'bill_to_override', shipmentToClone.bill_to_override));
      } else {
        this.setState({setBillToDefault: false});
      }
      this.props.dispatch(change('newShipmentForm', 'carrier', shipmentToClone.carrier));
      this.props.dispatch(change('newShipmentForm', 'notes_for_carrier', shipmentToClone.notes_for_carrier));
      this.props.dispatch(change('newShipmentForm', 'markup', shipmentToClone.markup));

      this.props.dispatch(change('newShipmentForm', 'line_items', shipmentToClone.line_items));
      this.props.dispatch(change('newShipmentForm', 'delayed', false));

      this.props.dispatch(change('newShipmentForm', 'total_declared_value', shipmentToClone.total_declared_value));
      this.props.dispatch(change('newShipmentForm', 'total_weight_override', shipmentToClone.total_weight_override));
      this.props.dispatch(
        change('newShipmentForm', 'total_quantity_override', shipmentToClone.total_quantity_override)
      );

      this.props.dispatch(change('newShipmentForm', 'custom_data', shipmentToClone.custom_data));

      //toggle override checkboxes
      this.props.dispatch(
        change(
          'newShipmentForm',
          'manual_total_weight',
          shipmentToClone.total_weight_override?.value && shipmentToClone.total_weight_override?.value > 0
        )
      );
      this.props.dispatch(change('newShipmentForm', 'preferred_currency', shipmentToClone.preferred_currency));
      this.props.dispatch(
        change(
          'newShipmentForm',
          'manual_total_value',
          shipmentToClone.total_declared_value && shipmentToClone.total_declared_value > 0
        )
      );
      this.props.dispatch(
        change(
          'newShipmentForm',
          'manual_quantity',
          shipmentToClone.total_quantity_override && shipmentToClone.total_quantity_override > 0
        )
      );

      /*
        When the shipmentToClone is running and (!this.props.cloneTrigger && this.props.isEditForm) is true
        it means we are on a draft and should set references. Otherwise references will be cleared for a clone.
      */
      if (!this.props.cloneTrigger && this.props.isEditForm) {
        this.setReferences();
      }
      this.setState({cloning: false});
    });
  }

  triggerBillToEdit() {
    this.setState({showBillTo: !this.state.showBillTo});
  }

  handleClearBillToForm() {
    const {form} = this.props;

    this.props.dispatch(change(form, 'bill_to_override', null));
  }

  handleSelectPriceSuggestion = (field, price) => {
    this.props.dispatch(change('newShipmentForm', field, price));
  };

  hasNewShipmentFormValues = (newShipmentForm = {}) => has(newShipmentForm, 'values');

  /**
   * We want to set the bill to override form values to the current company in the case of non-LTL
   * shipments for users with the modeBillToOptionalShipmentCreation feature flag on.
   */
  shouldSetBillToOverride() {
    const {newShipmentForm, modeBillToOptionalShipmentCreation} = this.props;
    const {setBillToDefault} = this.state;
    if (!this.hasNewShipmentFormValues(newShipmentForm)) {
      return false;
    }
    const {hasLTL, hasVLTL} = checkShipmentModes(newShipmentForm.values.mode);

    return (
      // is the mode V/LTL? check the feature flag; otherwise skip this check entirely
      (hasLTL || hasVLTL ? !modeBillToOptionalShipmentCreation : true) &&
      // set the bill to override info if it's currently empty and we haven't filled it yet
      isBillToFormEmpty(newShipmentForm.values.bill_to_override) &&
      !setBillToDefault
    );
  }

  /**
   * Similarly, we want to reset the bill to form when switching to V/LTL shipments and the
   * modeBillToOptionalShipmentCreation feature flag is on.
   */
  shouldResetBillToOverride() {
    const {newShipmentForm, modeBillToOptionalShipmentCreation} = this.props;
    const {setBillToDefault} = this.state;
    if (!this.hasNewShipmentFormValues(newShipmentForm)) {
      return false;
    }
    const {hasLTL, hasVLTL} = checkShipmentModes(newShipmentForm.values.mode);

    return setBillToDefault && (hasLTL || hasVLTL) && modeBillToOptionalShipmentCreation;
  }

  render() {
    const {
      carrier,
      handleSubmit,
      shipmentModes,
      submitting,
      values,
      company,
      purchaseOrders,
      unitPreferences,
      multipleError,
      created,
      totalToCreate,
      newShipmentForm,
      dispatch,
      showDriverField,
      financialTotals,
      markup,
      setDefaultVendorPOC,
      errorOverride,
      onSubmit,
      hasContractsViewPermission,
      isContractsEnabled,
      isRoutingGuidesEnabled,
      referencesCustomFields,
      stopCustomFields,
      isLoadBoardEnabled,
      featureFlags,
      pdfGeneratorApiForDocumentGeneration
    } = this.props;
    const unitPreferencesWithPreferredCurrency = {
      ...unitPreferences,
      ...(values?.preferred_currency && {currency: values.preferred_currency})
    };
    const is3plQuoting = company && company.feature_flags && company.feature_flags.uses_3pl_quoting_flow;
    const mode = values && values.mode;
    const {hasFTL, hasLTL, hasVLTL, hasParcel, hasDrayage, hasIntermodal, hasRail} = checkShipmentModes(mode);
    const hasMultiplier = values && values.multiplier;
    const equipmentTypesObject = allowedEquipmentTypes.find(
      (equipmentType) => equipmentType.id === parseInt(newShipmentForm.values?.equipment_type)
    );
    const shouldShowPricingIntel = showPricingIntel({
      hasFTL,
      historicalPricingEnabled: featureFlags?.historical_pricing_enabled,
      equipmentType: [equipmentTypesObject],
      stops: newShipmentForm.values?.stops
    });
    const canSendToCarriers = [
      CREATE_SHIPMENT_DIRECT_TENDER,
      SHIPMENT_INITIATE_ROUTING_GUIDE,
      SHIPMENT_CREATE_SPOT_NEGOTIATIONS
    ].some((permission) => this.props.user?.permissions?.includes(permission));

    return (
      <>
        <div className="newshipment">
          <Form onSubmit={handleSubmit} className="newShipmentForm">
            <Header
              form="newShipmentForm"
              showCloneLink
              cloneShipment={this.cloneShipment}
              cloning={this.state.cloning}
              formValues={newShipmentForm}
              label={'New Manual Shipment Order'}
              showField
              showCustomerField
              hideLabel
            />
            <div>
              <div className="card-container">
                <ShipmentTagsCard
                  className="newshipment__card full-width"
                  formValues={newShipmentForm.values}
                  form="newShipmentForm"
                  dispatch={dispatch}
                />
              </div>

              {this.state.isBroker ? (
                <div className="card-container two-objects">
                  <div className="half-width card-container-col">
                    <Paper className="newshipment__card full-width">
                      <Customer
                        showCustomerField={this.state.showCustomerField}
                        formValues={newShipmentForm}
                        form="newShipmentForm"
                      />
                    </Paper>

                    <Paper className="newshipment__card full-width">
                      <TenderingUserPermissionFallback permissions={[SHIPMENT_ASSIGN_CARRIER]}>
                        <Carrier
                          setDefaultVendorPOC={(poc) => {
                            if (poc) {
                              this.props.dispatch(
                                change('newShipmentForm', 'vendor_point_of_contact', {
                                  id: poc.id,
                                  name: poc.first_name + ' ' + (poc.last_name ? poc.last_name : '')
                                })
                              );
                            } else {
                              this.props.dispatch(change('newShipmentForm', 'vendor_point_of_contact', null));
                            }
                            setDefaultVendorPOC(poc);
                          }}
                          changeVendor={this.changeVendor}
                          form="newShipmentForm"
                          formValues={newShipmentForm}
                          showDriverField={showDriverField}
                          canViewCarriers={this.state.canViewCarriers}
                          canCreateCarrier={this.state.canCreateCarrier}
                          isInsuranceInvalid={this.state.isInsuranceInvalid}
                        />
                      </TenderingUserPermissionFallback>
                      {!hasParcel && (
                        <Field
                          name="notes_for_carrier"
                          label="Notes for Carrier"
                          component={textArea}
                          className="notes-for-carrier"
                          minRows={4}
                          placeholder="E.g., go to dock #4"
                        />
                      )}
                    </Paper>
                  </div>
                  <div className="card-container half-width">
                    <Paper className="newshipment__card full-width">
                      <ModeEquipment form="newShipmentForm" formValues={newShipmentForm} />
                    </Paper>
                  </div>
                </div>
              ) : (
                <div className="card-container two-objects">
                  <Paper className="newshipment__card half-width">
                    <ModeEquipment form="newShipmentForm" formValues={newShipmentForm} />
                  </Paper>
                  <Paper className="newshipment__card half-width">
                    <Carrier
                      setDefaultVendorPOC={(id) => {
                        setDefaultVendorPOC(id);
                      }}
                      form="newShipmentForm"
                      formValues={newShipmentForm}
                      canCreateCarrier={this.state.canCreateCarrier}
                    />
                  </Paper>
                </div>
              )}
              <div className="card-container">
                <Paper className="newshipment__card full-width">
                  <StopsExpanded
                    form="newShipmentForm"
                    canViewOrders={this.state.canViewOrders}
                    formValues={newShipmentForm}
                    showPickupDateField
                    purchaseOrders={purchaseOrders.purchaseOrdersByStop}
                    customFields={stopCustomFields}
                  />
                </Paper>
              </div>
              <div className="card-container">
                <Paper className="newshipment__card full-width">
                  <LineItems
                    form="newShipmentForm"
                    formValues={newShipmentForm}
                    purchaseOrders={purchaseOrders.purchaseOrdersById}
                  />
                  {newShipmentForm && newShipmentForm.values && newShipmentForm.values.line_items && (
                    <ShipmentItemTotals
                      form="newShipmentForm"
                      totalValue={newShipmentForm.values.total_declared_value}
                      unitPreferences={unitPreferencesWithPreferredCurrency}
                      lineItems={newShipmentForm.values.line_items}
                      totalWeight={newShipmentForm.values?.total_weight_override}
                      totalQuantity={newShipmentForm.values?.total_quantity_override}
                      hasFTL={hasFTL}
                      hasLTL={hasLTL}
                      hasVLTL={hasVLTL}
                    />
                  )}
                </Paper>
              </div>
              <div className="card-container two-objects">
                <Paper className="newshipment__card half-width">
                  <References
                    form="newShipmentForm"
                    formValues={newShipmentForm}
                    shipmentModes={shipmentModes}
                    customFields={referencesCustomFields}
                  />
                </Paper>
                <div className="half-width">
                  {shouldShowPricingIntel ? (
                    <div className="mb-5">
                      <NewShipmentEmbeddedPricingIntel
                        stops={newShipmentForm.values?.stops}
                        equipmentType={equipmentTypesObject}
                        preferredCurrency={newShipmentForm.values?.preferred_currency}
                        lineItems={newShipmentForm.values?.line_items}
                        totalWeightOverride={newShipmentForm.values?.total_weight_override}
                      />
                    </div>
                  ) : null}
                  <Paper className="newshipment__card full-width">
                    <Financials
                      dispatchMarkupChange={this.dispatchMarkupChange}
                      form="newShipmentForm"
                      formValues={newShipmentForm.values}
                      financialTotals={financialTotals}
                      markup={markup}
                      setMargin={this.state.setMargin}
                      triggerBillToEdit={this.triggerBillToEdit}
                    />
                    {this.state.showBillTo && (
                      <BillTo
                        triggerBillToEdit={this.triggerBillToEdit}
                        form="newShipmentForm"
                        formValues={newShipmentForm}
                        onClear={this.handleClearBillToForm.bind(this)}
                      />
                    )}
                  </Paper>
                </div>
              </div>
              <div className="card-container two-objects">
                <Paper className="newshipment__card half-width">
                  <Multiplier form="newShipmentForm" formValues={newShipmentForm} />
                </Paper>
              </div>

              {/*error && (
                <p className="error-text-form-level pad-top">
                  <i className="icon icon-Delayed pad-right" />
                  {error}
                </p>
              )*/}
              {errorOverride && (
                <p className="error-text-form-level">
                  <i className="icon icon-Delayed pad-right" />
                  {errorOverride}
                </p>
              )}

              <div className="quote-flow__submit text-right">
                <DeprecatedButton
                  disabled={submitting}
                  variant="secondary"
                  onMouseDown={() => this.ignoreValidation(false)}
                  onClick={handleSubmit((values) => onSubmit({...values, option: 'save-only'}))}
                >
                  Save Shipment for Later
                </DeprecatedButton>
                {is3plQuoting && hasFTL && (
                  <DeprecatedButton
                    disabled={submitting}
                    onMouseDown={() => this.ignoreValidation(false)}
                    onClick={handleSubmit((values) =>
                      onSubmit({...values, option: 'push-dashboard', pushToLoadboard: this.state.pushToLoadboard})
                    )}
                    variant="secondary"
                  >
                    Push to Dashboard
                  </DeprecatedButton>
                )}
                <DeprecatedButton
                  disabled={submitting}
                  onMouseDown={() => this.ignoreValidation(false)}
                  onClick={handleSubmit((values) =>
                    onSubmit({...values, option: 2, pushToLoadboard: this.state.pushToLoadboard})
                  )}
                  id="schedulePickupOnly"
                  variant="secondary"
                >
                  Schedule Pickup Only
                </DeprecatedButton>
                {isLoadBoardEnabled && !hasParcel && !hasDrayage && !hasLTL && (
                  <TenderingUserPermissionFallback permissions={[SHIPMENT_CREATE_SPOT_NEGOTIATIONS]}>
                    <Checkbox
                      checked={this.state.pushToLoadboard}
                      onChange={() => this.setState({pushToLoadboard: !this.state.pushToLoadboard})}
                      label="Push to Load Board"
                    />
                  </TenderingUserPermissionFallback>
                )}
                {canSendToCarriers ? (
                  <Dropdown disabled={hasMultiplier || submitting} variant="secondary" title="Send to Carriers">
                    {({onClick}) => {
                      const options = [];

                      if (isContractsEnabled && hasContractsViewPermission && isRoutingGuidesEnabled) {
                        options.push(
                          <TenderingUserPermissionFallback permissions={[SHIPMENT_INITIATE_ROUTING_GUIDE]}>
                            <li
                              key="routing-guide"
                              onClick={() => {
                                onClick();
                                handleSubmit((values) =>
                                  onSubmit({
                                    ...values,
                                    option: 'routing-guide',
                                    pushToLoadboard: this.state.pushToLoadboard
                                  })
                                )();
                              }}
                            >
                              Push to Routing Guide
                            </li>
                          </TenderingUserPermissionFallback>
                        );
                      }

                      if (hasLTL || hasFTL || hasDrayage || hasIntermodal || hasRail) {
                        options.push(
                          <TenderingUserPermissionFallback permissions={[CREATE_SHIPMENT_DIRECT_TENDER]}>
                            <li
                              key="request-tender"
                              onClick={() => {
                                onClick();
                                handleSubmit((values) =>
                                  onSubmit({
                                    ...values,
                                    option: 'request-tender',
                                    pushToLoadboard: this.state.pushToLoadboard
                                  })
                                )();
                              }}
                            >
                              Tender to Carrier(s)
                            </li>
                          </TenderingUserPermissionFallback>
                        );
                      }
                      options.push(
                        <TenderingUserPermissionFallback permissions={[SHIPMENT_CREATE_SPOT_NEGOTIATIONS]}>
                          <li
                            key="get-quotes"
                            onClick={() => {
                              onClick();
                              handleSubmit((values, ...args) =>
                                onSubmit(
                                  {...values, option: 'get-quotes', pushToLoadboard: this.state.pushToLoadboard},
                                  ...args
                                )
                              )();
                            }}
                          >
                            Get Quotes
                          </li>
                        </TenderingUserPermissionFallback>
                      );
                      return options;
                    }}
                  </Dropdown>
                ) : null}

                {!pdfGeneratorApiForDocumentGeneration && (
                  <DeprecatedButton
                    disabled={hasMultiplier || submitting}
                    onMouseDown={() => this.ignoreValidation(false)}
                    onClick={handleSubmit((values) =>
                      onSubmit({...values, option: 'print-bol', pushToLoadboard: this.state.pushToLoadboard})
                    )}
                    variant="primary"
                  >
                    {submitting ? (
                      <span>
                        <i className="icon icon-Restart rotate" /> Dispatching
                      </span>
                    ) : (
                      'Schedule Pickup and Print BOL 10'
                    )}
                  </DeprecatedButton>
                )}
              </div>
            </div>
          </Form>
        </div>
      </>
    );
  }
}

let NewShipmentForm = reduxForm({
  form: 'newShipmentForm',
  destroyOnUnmount: false,
  touchOnChange: true,
  ignoreValidation: false,
  enableReinitialize: false,
  validate: (values, props) => {
    const result = validate(values, {
      customFields: props.customFields, // line items custom fields
      stopCustomFields: props.stopCustomFields,
      referencesCustomFields: props.referencesCustomFields,
      flagsToValidate: {decimalSupportForShipmentLineItems: props?.decimalSupportForShipmentLineItems}
    });
    return result;
  },
  warn,
  onSubmitFail: scrollToFirstErrorField,
  onChange: async (values, dispatch, props, previousValues) => {
    if (values?.carrier?.value !== previousValues?.carrier?.value) {
      const response = await props.searchForCarrierByID(values.carrier?.value);
      if (response?.status === 200 && response?.details?.results?.length > 0) {
        const getVendorRelationshipResponse = await props.getCarrierRelationshipsCarrierRelationshipId(
          response.details.results[0].id
        );
        if (getVendorRelationshipResponse?.status === 200) {
          updateFormBillTo(dispatch, 'newShipmentForm', previousValues, values, true);
        }
      }
    } else if (values?.bill_to_override?.direction !== previousValues?.bill_to_override?.direction) {
      updateFormBillTo(dispatch, 'newShipmentForm', previousValues, values, false);
    }
  }
})(NewShipmentFormComponent);

/**
 * Order matters here: the component passed to withCustomFieldsProvider must be already wrapped in reduxForm
 * if the form is expected to receive and validate provided props
 *
 * The context provider for three different custom data contexts (references, line items, stops) is all the way up here
 * so the form can recieve and validate these props. Since each context collides with and overwrites the first,
 * we're renaming each prop (except the last) and passing it down to the child/consumer the old fashioned way.
 * Necessary until we're not using class components + reduxForm here and can provide and consume this data more elegantly.
 */

NewShipmentForm = compose(
  withCustomFieldsProvider(CustomFieldEntityTypesEnum.Shipment),
  renameProp('customFields', 'referencesCustomFields'),
  withCustomFieldsProvider(CustomFieldEntityTypesEnum.ShipmentStop),
  renameProp('customFields', 'stopCustomFields'),
  withCustomFieldsProvider(CustomFieldEntityTypesEnum.ShipmentLineItem)
)(NewShipmentForm);

NewShipmentForm = connect((state) => {
  const {unitPreferences} = state.userCompany;
  // use the user's company preferences to determine default units to prepopulate
  const lineItemDefaults = {
    line_items: [
      {
        package_type: 'PLT',
        length_unit: unitPreferences.lengthUnit,
        weight_unit: unitPreferences.weightUnit,
        temp_unit: unitPreferences.temperatureUnit,
        value_per_piece_currency: unitPreferences.currency,
        length: '40',
        width: '48',
        stackable: false
      }
    ]
  };
  const initialValues = Object.assign(
    {},
    JSON.parse(JSON.stringify(state.shipments.newShipmentFormInitial)),
    lineItemDefaults,
    {notes_for_carrier: state.company.preferences.default_carrier_notes},
    {}
  );

  return {
    initialValues,
    hasContractsViewPermission:
      state.userProfile.user.permissions && state.userProfile.user.permissions.includes('contracts.view_contracts'),
    isContractsEnabled:
      state.userCompany.company.feature_flags && state.userCompany.company.feature_flags.contracts_enabled,
    isRoutingGuidesEnabled:
      state.userCompany.company.feature_flags && state.userCompany.company.feature_flags.policies_enabled,
    isLoadBoardEnabled: get(state, 'userCompany.company.feature_flags.load_board_enabled'),
    equipmentTypes: state.shipments.equipmentTypes
  };
})(NewShipmentForm);

const selector = formValueSelector('newShipmentForm');

NewShipmentForm = connect((state) => {
  // can select values individually
  const stopData = selector(state, 'stops');
  const stopTimes = [];
  const scheduleToggles = [];
  if (stopData) {
    for (var i = 0; i < stopData.length; i++) {
      stopTimes.push({});
      if (stopData[i] && (stopData[i].planned_time_window_start || stopData[i].planned_time_window_end)) {
        stopTimes[i].start = stopData[i].planned_time_window_start;
        stopTimes[i].stop = stopData[i].planned_time_window_end;
      }
      if (stopData[i]) {
        scheduleToggles.push({
          date: stopData[i].schedule_date_toggle,
          time: stopData[i].schedule_time_toggle
        });
      }
    }
  }
  const financialData = selector(state, 'financials');
  const financialTotals = [];
  if (financialData) {
    for (var i = 0; i < financialData.length; i++) {
      if (financialData[i] && financialData[i].unit_amount && financialData[i].unit_quantity) {
        let value = financialData[i].unit_amount;
        if (value && typeof value === 'string' && value.startsWith('$')) {
          value = value.slice(1);
        }
        if (value && typeof value === 'string') {
          value = value.replace(/,/g, '');
        }

        const valToAdd = parseFloat(value * financialData[i].unit_quantity);

        if (!isNaN(valToAdd)) {
          financialTotals.push(valToAdd);
        } else {
          financialTotals.push(0);
        }
      } else {
        financialTotals.push(0);
      }
    }
  }
  let markup = selector(state, 'markup');

  if (markup && markup.startsWith('$')) {
    markup = markup.slice(1);
  }
  if (markup) {
    markup = markup.replace(/,/g, '');
  }

  const margin = selector(state, 'margin');

  const carrier = selector(state, 'carrier');

  return {
    stopTimes,
    financialTotals,
    markup,
    margin,
    scheduleToggles,
    carrier,
    selectedCarrier: state.vendors.selectedCarrier
  };
})(NewShipmentForm);

export default withFlags(
  'modeBillToOptionalShipmentCreation',
  'fiCarrierComplianceInsurance',
  'decimalSupportForShipmentLineItems',
  'pdfGeneratorApiForDocumentGeneration'
)(NewShipmentForm);
