import {ReactNode, useCallback, useContext, useMemo, useState} from 'react';
import pluralize from 'pluralize';
import {
  AppointmentStatusEnum,
  FacilityDock,
  LoadType,
  ScheduledResourceTypeEnum,
  TemperatureUnitEnum,
  Temperature,
  Facility,
  FacilityAppointmentType
} from '@shipwell/tempus-sdk';

import {Button, IconButton, Select, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {CircularProgress} from '@material-ui/core';
import classNames from 'classnames';

import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useQueryClient} from '@tanstack/react-query';
import {StatusTexts, ReferenceAbbreviatedLabels, ReferencePreferenceOrder, SvgIconColors} from '../../constants';

import {AppointmentVerbContext} from '../../AppointmentVerbContext';
import {AppointmentCreationModal} from '../modals';
import {AppointmentVerbConfirmation} from './AppointmentVerbConfirmations';

import {useAppointmentPerms, useDynamicAvailability} from 'App/data-hooks';
import {AppointmentEntry} from 'App/data-hooks/appointments/types';
import {arrayIncludes} from 'App/utils/betterTypedArrayMethods';

import {formatTimeOfDay} from 'App/utils/dateTimeGlobalsTyped';
import {ellipsis} from 'App/utils/grammar';
import {calculateShipmentTotals} from 'App/utils/globalsTyped';
import {METRIC, displayTotalWeight} from 'App/utils/internationalConstants';
import {useGetCompanyPreferences} from 'App/containers/Dashboard/cells/useGetCompanyPreferences';
import {RescheduleFCFS} from 'App/containers/Shipment/components/RescheduleFCFS';
import {FACILITY_APPOINTMENTS_AND_ALL} from 'App/data-hooks/queryKeys';

export type AppointmentDetailsProps = {
  facility: Facility | null;
  appointment: AppointmentEntry;
  shipment?: Shipment;
  docks: FacilityDock[];
  loadTypes: LoadType[];
  setSelectedAppointment: (appointment: null) => unknown;
  className: string;
  onClose?: () => unknown;
  shipmentId?: string | undefined;
};

export function AppointmentDetails({
  facility,
  appointment,
  shipment,
  docks,
  loadTypes,
  setSelectedAppointment,
  className,
  onClose,
  shipmentId
}: AppointmentDetailsProps) {
  const queryClient = useQueryClient();
  const verbs = useContext(AppointmentVerbContext);

  const [showEditModal, setShowEditModal] = useState(false);
  const [confirmingVerb, setConfirmingVerb] = useState<'checkIn' | 'checkOut' | 'reject' | null>(null);

  const {id, status, checkedInAt, checkedOutAt} = appointment;
  const loadType = loadTypes.find((loadType) => loadType.id === appointment.matchedLoadTypeId);

  const isShipmentLoading = !shipment && appointment.scheduledResourceType === ScheduledResourceTypeEnum.Shipment;
  const shipmentReferenceId = shipment?.reference_id ?? '';

  const checkedInAtDate = useMemo(() => (checkedInAt ? new Date(checkedInAt.timestamp) : null), [checkedInAt]);
  const checkedOutAtDate = useMemo(() => (checkedOutAt ? new Date(checkedOutAt.timestamp) : null), [checkedOutAt]);
  const start = useMemo(() => new Date(appointment.start.timestamp), [appointment]);
  const end = useMemo(() => new Date(appointment.end.timestamp), [appointment]);

  const isTerminal = arrayIncludes(
    [
      AppointmentStatusEnum.Completed,
      AppointmentStatusEnum.Rejected,
      AppointmentStatusEnum.NoShow,
      AppointmentStatusEnum.Cancelled
    ],
    status
  );
  const permissions = useAppointmentPerms();

  const isCheckedIn = !!checkedInAt && !checkedOutAt;
  const canCheckIn = !checkedInAt && !isTerminal && permissions.canCheckInAppointments;
  const canCheckOut = isCheckedIn && !isTerminal && permissions.canCheckOutAppointments;
  const canReject = !isTerminal && permissions.canRejectAppointments;

  const availability = useDynamicAvailability(start, end, appointment);

  const dockOptions = availability.isValid
    ? docks.map((dock) => {
        const disabled =
          dock.id !== appointment.dockId &&
          !availability.availabilityWindowsByDock.some(
            (w) =>
              w.dockId === dock.id &&
              (!start || +w.startDate.original <= +start) &&
              (!end || +w.startDate.original >= +end)
          );
        return {
          label: dock.name,
          id: dock.id,
          disabled
        };
      })
    : [{value: appointment.dockId, label: appointment.dockId}];

  const [selectedDock, setSelectedDock] = useState(appointment.dockId);

  const handleDockChange = useCallback(
    (dockId: string) => {
      // The reschedule function handles messaging the user for success and
      // failure and updating the data of record, so we don't need to handle
      // this promise.
      const previousSelectedDock = appointment.dockId;
      setSelectedDock(dockId);
      //TODO-FI-1827: handle scheduling instead of rescheduling if appointment is in unscheduled status
      void verbs.reschedule(
        {
          ...appointment,
          dockId
        },
        () => {
          setSelectedDock(previousSelectedDock);
        }
      );
    },
    [appointment, verbs]
  );

  appointment.references?.sort(
    (a, b) => ReferencePreferenceOrder.indexOf(a.qualifier) - ReferencePreferenceOrder.indexOf(b.qualifier)
  );
  const firstReference = appointment.references?.[0];
  const References = () =>
    firstReference ? (
      <div key={firstReference.qualifier} className="flex">
        {ReferenceAbbreviatedLabels[firstReference.qualifier]}: {firstReference.value}
        {appointment.references && appointment.references.length > 1 ? (
          <Tooltip
            tooltipContent={
              <div>
                {appointment.references.map((ref) => (
                  <div key={ref.qualifier} className="flex">
                    {ReferenceAbbreviatedLabels[ref.qualifier]}: {ref.value}
                  </div>
                ))}
              </div>
            }
          >
            <span className="italic text-sw-disabled-text">+ {appointment.references.length - 1}</span>
          </Tooltip>
        ) : null}
      </div>
    ) : null;

  let temperature: string | undefined;
  if (shipment?.temperature_lower_limit != null || shipment?.temperature_lower_limit != null) {
    temperature = formatTemperature({
      minimum: shipment?.temperature_lower_limit?.toString() ?? undefined,
      maximum: shipment?.temperature_upper_limit?.toString() ?? undefined,
      unit: TemperatureUnitEnum.F
    });
  }

  const {unitPreferences} = useGetCompanyPreferences();
  const updatedShipment = shipment?.id === shipmentId ? shipment : undefined;

  const {
    volume,
    density: calculatedDensity,
    system
  } = calculateShipmentTotals({line_items: updatedShipment?.line_items || [], unitPreferences});

  /**
   * In the near future we'll need to update this to better handle freight appointments.
   * Appointments will be introducing more variability over time and so we probably won't
   * use the same UX for all.
   */
  const metadata = useMemo(() => {
    return {
      mode: (loadType?.mode ??
        updatedShipment?.mode?.code ??
        (appointment.scheduledResourceMetadata?.resource_type === ScheduledResourceTypeEnum.FreightGeneral
          ? appointment.scheduledResourceMetadata.mode
          : undefined)) as string,
      equipmentType: (loadType?.equipment_type ??
        updatedShipment?.equipment_type?.name ??
        (appointment.scheduledResourceMetadata?.resource_type === ScheduledResourceTypeEnum.FreightGeneral
          ? appointment.scheduledResourceMetadata.equipment_type
          : undefined)) as string,
      trailerNo: updatedShipment?.trailer_name ?? updatedShipment?.equipment_config?.trailer?.name,
      driverCell: updatedShipment?.equipment_config?.driver?.phone_number,
      temperature,
      sealNo: updatedShipment?.drayage_seal_number,
      notes:
        appointment.scheduledResourceMetadata?.resource_type === ScheduledResourceTypeEnum.FreightGeneral
          ? (appointment.scheduledResourceMetadata?.notes as string)
          : undefined,
      weight: updatedShipment
        ? displayTotalWeight({
            shipment: updatedShipment,
            unitPreferences,
            returnNullWeight: true,
            returnWeightLabel: true,
            keepOnlyUnit: true
          })
        : undefined,
      quantity:
        updatedShipment?.line_items
          ?.map((item) => {
            if (!item.total_packages) return '';
            return `${String(item.total_packages || '')} ${pluralize(
              String(item.package_type || ''),
              item.total_packages
            )}`;
          })
          .join(', ') || undefined,
      density: calculatedDensity
        ? `${calculatedDensity.toFixed(2)} ${system === METRIC ? 'kg/m³' : 'lbs/ft³'}`
        : undefined,
      volume: volume ? `${volume.toFixed(2)} ${system === METRIC ? 'm³' : 'ft³'}` : undefined,
      pieces:
        updatedShipment?.line_items
          ?.map((item) => {
            if (!item.total_pieces) return '';
            return `${String(item.total_pieces || '')} ${pluralize(String(item.piece_type || ''), item.total_pieces)}`;
          })
          .join(', ') || undefined
    };
  }, [appointment, calculatedDensity, volume, updatedShipment, loadType, system, temperature, unitPreferences]);

  /** See `metadata` above. */
  const isLoading = {
    mode: !loadType && isShipmentLoading,
    equipmentType: !loadType && isShipmentLoading,
    trailerNo: isShipmentLoading,
    driverCell: false,
    temperature: isShipmentLoading,
    sealNo: isShipmentLoading,
    weight: isShipmentLoading,
    quantity: isShipmentLoading,
    density: isShipmentLoading,
    volume: isShipmentLoading,
    pieces: isShipmentLoading
  };
  const timezone = facility?.address.timezone;

  return (
    <div className={classNames('flex flex-col bg-sw-background-component w-[347px] h-full', className)}>
      <div className="flex w-full flex-row">
        <h5 className="flex-0 ml-1 flex">Appointment Details</h5>
        <div
          className={classNames(
            'flex flex-0 m-2 px-3 py-1',
            'rounded-xl bg-sw-active',
            'uppercase text-[8px] text-sw-text-reverse'
          )}
          style={{backgroundColor: SvgIconColors[status]}}
        >
          {StatusTexts[status]}
        </div>
        <div className="flex flex-1" />
        {appointment.stopId &&
        appointment.status !== AppointmentStatusEnum.Unscheduled &&
        appointment.appointmentType !== FacilityAppointmentType.FirstComeFirstServe ? (
          <div className="flex-0 m-1 flex cursor-pointer ">
            <Tooltip tooltipClassname="whitespace-nowrap" tooltipContent={'Reschedule'} placement="top" portal>
              <IconButton iconName="Calendar" onClick={() => setShowEditModal(true)} aria-label="Reschedule" />
            </Tooltip>
          </div>
        ) : appointment.appointmentType === FacilityAppointmentType.FirstComeFirstServe ? (
          <div className="flex-0 m-1 mr-4 flex cursor-pointer">
            <Tooltip tooltipClassname="whitespace-nowrap" tooltipContent={'Reschedule'} placement="top" portal>
              <RescheduleFCFS
                refetch={() => queryClient.invalidateQueries([FACILITY_APPOINTMENTS_AND_ALL])}
                appointment={appointment}
              />
            </Tooltip>
          </div>
        ) : null}
        {permissions.canCancelAppointments &&
        appointment.status !== AppointmentStatusEnum.Unscheduled &&
        appointment.appointmentType !== FacilityAppointmentType.FirstComeFirstServe ? (
          <div className="flex-0 m-1 flex cursor-pointer ">
            <Tooltip tooltipClassname="whitespace-nowrap" tooltipContent={'Cancel'} placement="top" portal>
              <IconButton iconName="Cancel" onClick={() => verbs.cancel(id)} aria-label="Cancel" />
            </Tooltip>
          </div>
        ) : null}
        <div className="flex-0 m-1 flex cursor-pointer ">
          <IconButton
            iconName="Close"
            onClick={() => {
              onClose?.();
              setSelectedAppointment(null);
            }}
            aria-label="Close"
          />
        </div>
      </div>
      {confirmingVerb ? (
        <AppointmentVerbConfirmation
          timezone={timezone}
          verb={confirmingVerb}
          onCancel={() => setConfirmingVerb(null)}
          onSave={(time: Date, reason?: string) => {
            void verbs[confirmingVerb](appointment.id, time, reason);
            setConfirmingVerb(null);
          }}
        />
      ) : (
        <>
          {appointment.status !== AppointmentStatusEnum.Unscheduled ? (
            <>
              <div className="flex h-[60px] w-full flex-row justify-center border-t-1 border-sw-divider bg-sw-active-light p-2">
                <div className="flex w-40 flex-col">
                  <div className="flex text-xxs">CHECK IN</div>
                  <div className="flex text-sm">
                    {checkedInAtDate ? formatTimeOfDay(checkedInAtDate, timezone) : '--'}
                  </div>
                </div>
                <div className="flex w-40 flex-col">
                  <div className="flex text-xxs">CHECK OUT</div>
                  <div className="flex text-sm">
                    {checkedOutAtDate ? formatTimeOfDay(checkedOutAtDate, timezone) : '--'}
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <div className="p-2 text-xs">
            <Select
              required
              disabled={!dockOptions.length || appointment.status === AppointmentStatusEnum.Unscheduled}
              name="dock_select"
              label="Dock"
              value={selectedDock || appointment.dockId}
              options={dockOptions}
              simpleValue
              onChange={(dockId: string) => handleDockChange(dockId)}
            />
          </div>

          {appointment.status === AppointmentStatusEnum.Rejected ? (
            <div className="flex-0 m-2 flex flex-row justify-start rounded bg-sw-error-background p-2">
              <SvgIcon name="ErrorFilled" color="red-alert" />
              <div className="ml-2 text-xs leading-4">
                <span className="text-bold">Appointment Rejected</span>
                <br />
                {appointment.rejectedReasons}
              </div>
            </div>
          ) : null}
          {appointment.status === AppointmentStatusEnum.DockInUse ? (
            <div className="flex-0 m-2 flex flex-row justify-start rounded bg-sw-warning-opacity p-2">
              {/* I do not know why I need to specify a size of 40 below to
               * make the icon look like it is correctly 24, especially since
               * the SAME ICON above renders just fine, but what the heck this
               * seems to work. - Joe
               */}
              <SvgIcon name="ErrorFilled" color="saffron-submarine" width="40" height="40" />
              <div className="ml-2 text-xs leading-4">
                <span className="text-bold">Dock Occupied</span>
                <br />A driver cell number is required to received appointment updates.
                <a onClick={() => alert('Coming Soon')} className="mx-1 cursor-pointer">
                  Mark Dock as Ready
                </a>
              </div>
            </div>
          ) : null}
          <FieldRow>
            <AppointmentField name="REFERENCE #s" value={<References />} />
            <AppointmentField name="DELIVERY TYPE" value={appointment.deliveryType} />
          </FieldRow>
          <FieldRow>
            <AppointmentField name="CARRIER" value={ellipsis(appointment.carrierName, 18)} />
            <AppointmentField name="MODE" value={metadata.mode} />
          </FieldRow>
          <FieldRow className="border-b-sw-active-light">
            <AppointmentField name="EQUIPMENT" loading={isLoading.equipmentType} value={metadata.equipmentType} />
            <AppointmentField
              name="SHIPMENT ID"
              value={shipmentId ? <a href={`/shipments/${shipmentId}`}>{shipmentReferenceId}</a> : '--'}
            />
          </FieldRow>

          <div className="mx-3 my-2 border-t-1 border-sw-divider text-xs" />

          <FieldRow>
            <AppointmentField name="TRAILER" loading={isLoading.trailerNo} value={metadata.trailerNo} />
            <AppointmentField name="DRIVER CELL" loading={isLoading.driverCell} value={metadata.driverCell} />
          </FieldRow>
          <FieldRow>
            <AppointmentField name="TEMPERATURE" loading={isLoading.temperature} value={metadata.temperature} />
            <AppointmentField name="SEAL #" loading={isLoading.sealNo} value={metadata.sealNo} />
          </FieldRow>
          <FieldRow>
            <AppointmentNotesField name="NOTES" value={metadata.notes} />
          </FieldRow>

          <div className="mx-3 my-2 border-t-1 border-sw-divider text-xs" />

          <FieldRow>
            <AppointmentField name="TOTAL WEIGHT" loading={isLoading.weight} value={metadata.weight} />
            <AppointmentField name="QUANTITY" loading={isLoading.quantity} value={metadata.quantity} />
          </FieldRow>
          <FieldRow>
            <AppointmentField name="TOTAL VOLUME" loading={isLoading.volume} value={metadata.volume} />
            <AppointmentField name="TOTAL DENSITY" loading={isLoading.density} value={metadata.density} />
          </FieldRow>
          <FieldRow>
            <AppointmentField name="TOTAL PIECES" loading={isLoading.pieces} value={metadata.pieces} />
          </FieldRow>

          {/* The following div fills space to put buttons at the bottom */}
          <div className="flex-1">&nbsp;</div>
          {appointment.status !== AppointmentStatusEnum.Unscheduled ? (
            <div className="flex-0 grid grid-cols-2 gap-2 p-2">
              {[
                canReject && (
                  <Button key="reject" onClick={() => setConfirmingVerb('reject')} color="warning" variant="secondary">
                    Reject
                  </Button>
                ),
                canCheckIn && (
                  <Button key="checkIn" onClick={() => setConfirmingVerb('checkIn')} color="primary">
                    Check In
                  </Button>
                ),
                canCheckOut && (
                  <Button key="checkOut" onClick={() => setConfirmingVerb('checkOut')} color="primary">
                    Check Out
                  </Button>
                )
              ].filter(Boolean)}
            </div>
          ) : null}

          <AppointmentCreationModal
            loadTypes={loadTypes}
            facilityId={facility?.id}
            shipmentId={shipment?.id}
            stopId={appointment.stopId}
            showModal={showEditModal}
            onClose={() => setShowEditModal(false)}
          />
        </>
      )}
    </div>
  );
}

function FieldRow({children, className}: {children: ReactNode | ReactNode[]; className?: string}) {
  return <div className={classNames('flex-0 flex flex-row mx-2 pt-2 justify-start w-full', className)}>{children}</div>;
}

function AppointmentField(props: {name: string; loading?: boolean; value?: string | ReactNode}) {
  return (
    <div className="flex-0 ml-1 flex w-40 flex-col">
      <div className="text-xxs">{props.name}</div>
      <div className="text-sm">{props.loading ? <CircularProgress size={12} /> : props.value ?? '--'}</div>
    </div>
  );
}
function AppointmentNotesField(props: {name: string; value?: string}) {
  return (
    <div className="ml-1 flex flex-col flex-wrap">
      <div className="text-xxs">{props.name}</div>
      <div className="text-sm">{props.value ?? '--'}</div>
    </div>
  );
}

function formatTemperature(temperature?: Temperature) {
  if (!temperature) {
    return '--';
  }
  const {minimum: min, maximum: max, unit} = temperature;
  let s = '';
  if (min != null && max != null) {
    s = `${min} - ${max}`;
  } else if (min) {
    s = `+${min}`;
  } else if (max) {
    s = `-${max}`;
  } else {
    return '--';
  }
  if (unit === TemperatureUnitEnum.F) {
    s += ` °F`;
  } else if (unit === TemperatureUnitEnum.C) {
    s += ` °C`;
  }
  return s;
}
