import {Link} from 'react-router';
import get from 'lodash/get';
import {CustomField} from '@shipwell/backend-core-sdk';
import {CustomFieldEntityTypesEnum, Shipment, User} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useQuery} from '@tanstack/react-query';
import {DisplayValue, Title, Rule, TypographyValue, Modal, SvgIcon} from '@shipwell/shipwell-ui';
import type {ReferenceItem} from '../utils/constants';
import Edit from 'App/containers/Shipment/components/References/Edit';
import ReferenceColumns from 'App/containers/Shipment/components/References/View/ReferenceColumns';
import ReferenceFields from 'App/containers/Shipment/components/References/View/ReferenceFields';
import {checkShipmentModes} from 'App/utils/globalsTyped';
import {useCustomFields, CustomFieldsContextConsumer, useUserMe} from 'App/data-hooks';
import {getCustomDataPath} from 'App/utils/customDataPath';
import {BROKER_SHIPPER_RELATIONSHIP_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getBrokerShipperRelationship} from 'App/api/brokers/typed';
import {getOrderUrl} from 'App/containers/Shipment/utils/orderUtils';
import {isCustomFieldOwner} from 'App/utils/customData';

const collapsedReferences: ReferenceItem[] = [
  {label: 'customer', fieldName: 'relationship_to_customer.customer.name'},
  {label: 'customer reference #', fieldName: 'customer_reference_number'}
];

const CustomReferenceFields = ({
  shipment,
  companyId,
  companyName
}: {
  shipment: Shipment;
  companyId: string;
  companyName?: string;
}) => (
  <CustomFieldsContextConsumer>
    {(consumer) => {
      const {customFields} = consumer as unknown as {
        customFields: {id: string; label: string; company: string; allow_carrier_view: boolean}[];
      };
      return (
        customFields
          ?.filter(
            (cf) =>
              isCustomFieldOwner(cf.company, companyId) ||
              cf.allow_carrier_view ||
              companyName === 'SYSTEM-WIDE SUPPLIER TENANT'
          )
          ?.map((cf) => {
            const value = get(
              shipment,
              `custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.Shipment)}.${cf.id}`,
              null
            );
            return (
              <DisplayValue key={cf.id} label={cf.label}>
                {value ? <div className="break-words">{value}</div> : '--'}
              </DisplayValue>
            );
          }) || []
      );
    }}
  </CustomFieldsContextConsumer>
);

const View = ({
  shipment,
  expanded,
  editing,
  onCancelEdit,
  ...props
}: {
  shipment: Shipment;
  expanded: boolean;
  editing: boolean;
  onCancelEdit: () => void;
  onSubmit: () => void;
  user: User;
}) => {
  const user = useUserMe();

  const customerId = shipment.customer?.id;
  const companyId = user.data?.company?.id;
  const companyName = user.data?.company?.name;
  const brokerageId = user.data?.company?.brokerage?.id;
  const {customFields}: {customFields: CustomField[]} = useCustomFields(CustomFieldEntityTypesEnum.Customer);
  const brokerShipperQuery = useQuery({
    queryKey: [BROKER_SHIPPER_RELATIONSHIP_QUERY_KEY, brokerageId, companyId],
    queryFn: async () => {
      const data = await getBrokerShipperRelationship({
        brokerageId: brokerageId || '',
        customerId: customerId || ''
      });
      return data;
    },
    enabled: Boolean(brokerageId && customerId)
  });

  const brokerCustomData = brokerShipperQuery.data?.results?.[0]?.custom_data as unknown as
    | {shipwell_custom_data: {customer: Record<string, string | null>}}
    | undefined;
  const shipwellBrokerCustomCustomerData = brokerCustomData?.shipwell_custom_data.customer;

  const customCustomerFields = customFields.map((customField) => {
    return {
      id: customField.id,
      label: customField.label,
      value: shipwellBrokerCustomCustomerData?.[customField.id || ''] || '--'
    };
  });

  const {hasDrayage, hasParcel, hasRail} = checkShipmentModes(shipment.mode);
  const hasCarrier = shipment?.relationship_to_vendor?.vendor;
  return (
    <>
      {expanded && (
        <>
          <ReferenceColumns title="Customers" references={collapsedReferences} context={shipment} />
          <div className="grid grid-cols-2 gap-2">
            {brokerShipperQuery.isInitialLoading ? (
              <SvgIcon name="LoadingDots" />
            ) : brokerShipperQuery.isSuccess ? (
              customCustomerFields.map((customCustomerField) => (
                <DisplayValue key={customCustomerField.id} label={customCustomerField.label}>
                  {customCustomerField.value}
                </DisplayValue>
              ))
            ) : null}
          </div>
          <Rule />
          <ReferenceFields shipment={shipment} />
          {hasCarrier && (
            <DisplayValue label="Carrier Reference Code #">
              {shipment.carrier_reference_code ? (
                <div className="break-words">{shipment.carrier_reference_code}</div>
              ) : (
                '--'
              )}
            </DisplayValue>
          )}
          <CustomReferenceFields
            shipment={shipment}
            companyId={user.data?.company?.id || ''}
            companyName={companyName}
          />
          {!hasParcel && !hasDrayage && !hasRail ? (
            <>
              <Rule />
              <Title variant="sectionTitle">Orders</Title>
              {shipment.related_orders ? (
                <div className="flex flex-wrap gap-2">
                  {shipment.related_orders?.map((order) => (
                    <Link
                      key={order.order_id}
                      to={getOrderUrl(order.self_link, order.order_id)}
                      title={order.order_number}
                      className="max-w-[115px] truncate rounded bg-sw-form-field px-3 py-1 text-xxs font-bold text-sw-text-reverse"
                    >
                      {order.order_number}
                    </Link>
                  ))}
                </div>
              ) : (
                <TypographyValue>--</TypographyValue>
              )}
            </>
          ) : null}
        </>
      )}
      <Modal show={editing} title="References" footerComponent={null} onClose={onCancelEdit}>
        <Edit shipment={shipment} onCancelEdit={onCancelEdit} {...props} />
      </Modal>
    </>
  );
};

View.defaultProps = {
  shipment: {},
  editing: false
};

export default View;
