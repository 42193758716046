import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {connect} from 'react-redux';
import {StepIndicator} from '@shipwell/shipwell-ui';
import {useMutation} from '@tanstack/react-query';
import {createPolicy, getPolicyConditionalTrigger} from 'App/api/policies';
import {getWorkflow, createWorkflow, updateWorkflow} from 'App/api/workflows';
import {workflowFormSteps, POLICY_TYPE_ROUTING_GUIDE} from 'App/containers/workflows/workflowConstants';
import {formatAddresses, getWorkflowFormValues, buildWorkflow} from 'App/containers/workflows/utils';
import RoutingGuideActions from 'App/formComponents/forms/routingGuideActions';
import PolicyForm from 'App/formComponents/forms/policy';
import './styles.scss';

/**
 * Contract Creation
 * @param {*} props
 */

const RoutingGuideCreateContainer = (props) => {
  const {onCancel, onSubmitSuccess, company, cloningGuide, defaultValues, setError} = props;
  const [activeStep, setActiveStep] = useState(0);
  const [policy, setPolicy] = useState(
    cloningGuide ||
      defaultValues?.policy ||
      get(company, 'custom_data.shipwell_custom_data.company_default_values.routingGuides', null) ||
      null
  );
  const [selectedActions, setSelectedActions] = useState(defaultValues?.actions || []);

  const routingGuideActionsDefaults = get(
    company,
    'custom_data.shipwell_custom_data.company_default_values.routingGuideActions',
    null
  );

  useEffect(() => {
    if (cloningGuide?.workflow_id) {
      fetchWorkflowDetails(cloningGuide.workflow_id);
    }
  }, [cloningGuide]);

  /**
   * Fetch workflow information for use in the actions
   * @param {string} workflowId
   */
  const fetchWorkflowDetails = async (workflowId) => {
    try {
      const workflowResponse = await getWorkflow(workflowId);
      const transformedActions = await getWorkflowFormValues({
        workflow: workflowResponse.data,
        policyType: POLICY_TYPE_ROUTING_GUIDE
      });
      setSelectedActions(transformedActions.actions);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Create workflow actions
   * @param {*} values
   * @param {*} Formik props
   */

  const createPolicyMutation = useMutation((values) => createPolicy(values), {
    onSuccess: (data) => setPolicy((prevPolicy) => ({...prevPolicy, ...data.body})),
    onError: (error) => setError('Error Saving Routing Guide!', error.body?.errors?.[0]?.detail || '')
  });
  const createWorkflowMutation = useMutation((values) => createWorkflow(values), {
    onError: (error) => setError('Error Saving Routing Guide!', error.body?.errors?.[0]?.detail || '')
  });
  const updateWorkflowMutation = useMutation(({id, values}) => updateWorkflow(id, values), {
    onError: (error) => setError('Error Saving Routing Guide!', error.body?.errors?.[0]?.detail || '')
  });

  const handleSaveWorkflow = (values, {setSubmitting}) => {
    setSubmitting(true);
    let workflowId = '';
    const parsedValues = JSON.parse(JSON.stringify(values));
    const workflowValues = buildWorkflow({values: parsedValues, policyType: POLICY_TYPE_ROUTING_GUIDE});
    // create workflow. on success, create policy with workflow id
    createWorkflowMutation.mutate(workflowValues, {
      onSuccess: (data) => {
        workflowId = data.data?.id;
        const updatedPolicy = {
          ...policy,
          workflow_id: workflowId
        };
        createPolicyMutation.mutate(updatedPolicy, {
          onSuccess: async (data) => {
            const originalParsedValues = JSON.parse(JSON.stringify(values));
            const policyConditional = await getPolicyConditionalTrigger(data.body?.id);
            const newWorkflowValues = buildWorkflow({
              values: {...originalParsedValues},
              policyType: POLICY_TYPE_ROUTING_GUIDE,
              workflowConditional: policyConditional.body?.conditional
            });
            updateWorkflowMutation.mutate({id: workflowId, values: newWorkflowValues});

            if (onSubmitSuccess) {
              const policySansWorkflowId = Object.keys(data.body).reduce((newPolicy, key) => {
                if (key === 'workflow_id') {
                  return newPolicy;
                }
                return {
                  ...newPolicy,
                  [key]: data.body?.[key]
                };
              }, {});
              setPolicy(policySansWorkflowId);
              onSubmitSuccess(
                values.clone_on_save ? {...data.body, id: null, name: 'Copy of ' + data.body?.name} : null
              );
              if (values.clone_on_save) {
                setActiveStep(0);
              }
            }
          },
          onSettled: () => {
            setSubmitting(false);
          }
        });
      },
      onError: (error) => {
        setError('Error!', error?.error_description || 'There was an error while creating the workflow');
        setSubmitting(false);
      }
    });
  };

  const handleSavePolicy = (values) => {
    setPolicy((prevPolicy) => ({
      ...prevPolicy,
      ...values,
      origins: formatAddresses(values.origins),
      destinations: formatAddresses(values.destinations),
      policy_type: POLICY_TYPE_ROUTING_GUIDE
    }));
    setActiveStep(1);
  };

  return (
    <>
      <div className="stepIndicatorWrapper">
        <StepIndicator
          steps={workflowFormSteps.map((step) => step.title)}
          width={'100%'}
          alternativeLabel
          activeStep={activeStep}
        />
      </div>
      {cloningGuide && activeStep === 0 && (
        <div className="stepExplainer">
          This is a clone of an existing routing guide. Please update your routing guide name and details accordingly.
        </div>
      )}
      {cloningGuide && activeStep === 1 && (
        <div className="stepExplainer">Update your routing guide steps for shipments that match this policy.</div>
      )}
      {activeStep === 0 && (
        <PolicyForm cloningGuide={cloningGuide} onCancel={onCancel} values={policy || {}} onSubmit={handleSavePolicy} />
      )}
      {activeStep === 1 && (
        <RoutingGuideActions
          onCancel={onCancel}
          onSubmit={handleSaveWorkflow}
          cloningGuide={cloningGuide}
          onBack={() => setActiveStep(0)}
          values={{
            actions: selectedActions,
            name: policy?.name,
            clone_on_save: policy?.clone_on_save ?? null,
            ...policy
          }}
          defaultValues={{
            mode: policy.modes && policy.modes.length === 1 ? policy.modes[0] : [],
            equipment_type: policy.equipment_types?.length === 1 ? policy.equipment_types[0] : [],
            rate_type: routingGuideActionsDefaults?.rate_type || null,
            info_message: routingGuideActionsDefaults?.info_message || null,
            expires_after_seconds: routingGuideActionsDefaults?.expires_after_seconds || null,
            step_timer: routingGuideActionsDefaults?.step_timer || null
          }}
          selectedPolicy={policy}
        />
      )}
    </>
  );
};

RoutingGuideCreateContainer.propTypes = {
  cloningGuide: PropTypes.object,
  company: PropTypes.object,
  defaultValues: PropTypes.shape({
    actions: PropTypes.array,
    policy: PropTypes.object
  }),
  onCancel: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  setError: PropTypes.func
};

export default connect((state) => ({
  user: state.auth.user,
  company: state.auth.company
}))(RoutingGuideCreateContainer);
