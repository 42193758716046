import {stringify} from 'query-string';
import {useState} from 'react';
import {browserHistory} from 'react-router';

// If routerProps are supplied, state will be derived from the browser's searchParams
// If no routerProps are supplied, state will be held in react via useState
export function useTableFilters<T extends Record<string, string | string[]>>(
  defaultFilters: T,
  isUsingRouter?: boolean,
  defaultFiltersOpen?: boolean
) {
  const [filters, setFilters] = useState(defaultFilters);
  const defaultFilterStates = Object.keys(defaultFilters).reduce((acc: Record<keyof T, boolean>, key) => {
    return {
      ...acc,
      [key]: defaultFiltersOpen || false
    };
  }, {} as Record<keyof T, boolean>);

  const [openFilters, setOpenFilters] = useState(defaultFilterStates);
  const isAllExpanded = Object.values(openFilters).every((filter) => filter);
  const isAllCollapsed = Object.values(openFilters).every((filter) => !filter);

  const searchParamURL = new URLSearchParams(location.search);

  const getExistingParams = () => {
    const filterParams = Object.keys(defaultFilters).reduce((acc: T, key) => {
      if (typeof defaultFilters[key] === 'string') {
        const value = searchParamURL.get(key);
        return {
          ...acc,
          ...(value ? {[key]: value} : {})
        };
      }
      if (Array.isArray(defaultFilters[key])) {
        const values = searchParamURL.getAll(key);
        return {
          ...acc,
          [key]: values
        };
      }
      return acc;
    }, {} as T);
    return filterParams;
  };

  const onFilterSearchParamChange = (filterName: keyof T, value: string | string[]) => {
    if (!isUsingRouter) {
      return;
    }
    const name = filterName.toString();
    if (typeof value === 'string') {
      if (value) {
        searchParamURL.set(name, value);
      } else {
        searchParamURL.delete(name);
      }
    }
    if (Array.isArray(value)) {
      // remove current values current values
      searchParamURL.delete(name);
      // replace with new
      value.forEach((item) => {
        searchParamURL.append(name, item);
      });
    }
    browserHistory.push({
      pathname: window.location.pathname,
      search: `?${searchParamURL.toString()}`
    });
  };

  const onFilterStateChange = (filterName: keyof typeof defaultFilters, value: unknown) => {
    setFilters((prevFilters) => {
      const updatedFilters = {
        ...prevFilters,
        [filterName]: value
      };
      return updatedFilters;
    });
  };

  const onDashboardParamChange = (dashboardFilters?: Record<string, string | string[]>) => {
    if (!dashboardFilters) {
      return browserHistory.push({
        pathname: window.location.pathname
      });
    }
    return browserHistory.push({
      pathname: window.location.pathname,
      search: `?${stringify(dashboardFilters)}`
    });
  };

  const onDashboardStateChange = (dashboardFilters?: T) => setFilters(() => dashboardFilters || ({} as T));

  const updateAllFilters = (open: boolean) => {
    setOpenFilters((prevOpen) => {
      return Object.keys(prevOpen).reduce((acc: Record<keyof T, boolean>, key) => {
        return {
          ...acc,
          [key]: open
        };
      }, {} as Record<keyof T, boolean>);
    });
  };

  const onToggleFilter = (key: keyof T) => {
    setOpenFilters((prevOpen) => {
      return {
        ...prevOpen,
        [key]: !prevOpen[key]
      };
    });
  };

  // If using query params over state, check if there are default filters to apply and apply them...
  // ...as long as we are not overwriting existing params
  const hasParams = (params: T) =>
    Object.keys(params).reduce((current: boolean, paramKey) => {
      if (Array.isArray(params[paramKey])) {
        return params[paramKey].length > 0 || current;
      }
      return Boolean(params[paramKey]) || current;
    }, false);

  const hasExistingParams = hasParams(getExistingParams());
  const hasDefaultFilters = hasParams(defaultFilters);
  if (isUsingRouter && hasDefaultFilters && !hasExistingParams) {
    Object.keys(filters).forEach((filter) => onFilterSearchParamChange(filter, filters[filter]));
  }

  return {
    filters: isUsingRouter ? getExistingParams() : filters,
    onFilterChange: isUsingRouter ? onFilterSearchParamChange : onFilterStateChange,
    onDashboardChange: isUsingRouter ? onDashboardParamChange : onDashboardStateChange,
    openFilters,
    onToggleFilter,
    onExpandAllFilters: () => updateAllFilters(true),
    onCollapseAllFilters: () => updateAllFilters(false),
    isAllExpanded,
    isAllCollapsed,
    setFilters
  };
}
