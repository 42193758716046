import classNames from 'classnames';
import {ReactNode} from 'react';

export const Table = ({
  head,
  body,
  isFixedTableLayout = false
}: {
  head: ReactNode;
  body: ReactNode;
  isFixedTableLayout?: boolean;
}) => {
  return (
    <table
      className={classNames('relative overflow-auto border-separate', {
        'w-full table-fixed': isFixedTableLayout,
        //min-w-full prevents tables from only taking up part of the viewport
        //width if the sum column width is less than the viewport width.
        'w-max min-w-full': !isFixedTableLayout
      })}
    >
      <thead className="sticky top-0 z-10">{head}</thead>
      <tbody>{body}</tbody>
    </table>
  );
};
