import {ShipmentPreferredCurrencyEnum} from '@shipwell/backend-core-sdk';

const currencyLabelLookup: Record<ShipmentPreferredCurrencyEnum, string> = {
  CAD: 'CAD - Canadian Dollar',
  CNY: 'CNY - Yuan Renminbi',
  EUR: 'EUR - Euro',
  MXN: 'MXN - Mexican Peso',
  USD: 'USD - US Dollar'
};

// https://ourcodeworld.com/articles/read/958/how-to-sort-an-array-of-strings-alphabetically-with-special-characters-properly-with-javascript
const internationalSortAlphabetically = (a: string, b: string) => Intl.Collator().compare(a, b);

export const preferredCurrencyOptions = Object.values(ShipmentPreferredCurrencyEnum)
  .sort(internationalSortAlphabetically)
  .map((currencyCode) => ({
    id: currencyCode,
    name: currencyLabelLookup[currencyCode] || currencyCode
  }));

export const preferredCurrencyOptionsShipwellUiSelect = Object.values(ShipmentPreferredCurrencyEnum)
  .sort(internationalSortAlphabetically)
  .map((currencyCode) => ({
    value: currencyCode,
    label: currencyLabelLookup[currencyCode] || currencyCode
  }));

export const storeRecentChargeCodes = (chargeCodes: string[], mode: string) => {
  const storedChargeCodesLimit = 10;
  const storedChargeCodes = window.localStorage.getItem(`${mode}-charge-codes`);
  const parsedChargeCodes = storedChargeCodes ? JSON.parse(storedChargeCodes) : [];
  const newChargeCodes = chargeCodes.filter((chargeCode) => !parsedChargeCodes.includes(chargeCode)).reverse();
  const combinedArray = [...newChargeCodes, ...parsedChargeCodes];
  window.localStorage.setItem(
    `${mode}-charge-codes`,
    JSON.stringify([...combinedArray.slice(0, storedChargeCodesLimit)])
  );
};
