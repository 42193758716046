import {ReactNode} from 'react';
import Datadog from 'react-datadog';
import {useUserMe} from 'App/data-hooks';

export const DataDogProvider = ({children}: {children: ReactNode}) => {
  const user = useUserMe();
  const hostname = window.location.hostname;
  const dataDogEnv = process.env.DATADOG_ENV;
  const validEnvironments = ['dev', 'prod'];

  if (
    !(
      dataDogEnv &&
      process.env.DATADOG_CLIENT_TOKEN &&
      process.env.DATADOG_APPLICATION_ID &&
      process.env.DATADOG_TRACE_ORIGINS
    )
  ) {
    // technically dotenv doesn't allow empty values in these variables anyway, but this is a simpler check
    // for typescript instead of checking each individual variable in the usage below
    return children;
  }

  // if DATADOG_ENV === dev but the hostname is not dev, we are on a feature branch and should not report
  const isFeatureBranch =
    (dataDogEnv === 'dev' || dataDogEnv === 'local') &&
    (hostname.includes('frontend-web.shipwell-dev') || hostname.includes('localhost'));
  // We are only tracking dev and prod for now. If we want more environments tracked, we can expand validEnvironments above
  const isValidEnvironment = validEnvironments.some((env) => env === dataDogEnv);

  if (isFeatureBranch || !isValidEnvironment) {
    return children;
  }
  const userName = `${user.data?.user?.first_name || ''} ${user.data?.user?.last_name || ''}`;
  return (
    <Datadog
      applicationId={process.env.DATADOG_APPLICATION_ID}
      clientToken={process.env.DATADOG_CLIENT_TOKEN}
      site={'datadoghq.com'}
      service={'frontend-web'}
      env={dataDogEnv}
      sessionSampleRate={100}
      sessionReplaySampleRate={100}
      sessionReplayRecording
      trackUserInteractions
      trackResources
      trackLongTasks
      allowedTracingUrls={[process.env.DATADOG_TRACE_ORIGINS]}
      traceSampleRate={100}
      user={{
        email: user.data?.user?.email,
        name: userName,
        id: user.data?.user?.id || '',
        tenant_id: user.data?.company?.id
      }}
    >
      {children}
    </Datadog>
  );
};
