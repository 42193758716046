import {
  Configuration,
  PaymentsApi,
  TriumphPayAuthSettings,
  IntegrationsApi,
  NetsuiteConfig,
  NetsuiteConfigCategoryMappings,
  NetsuiteConfigFieldMappings,
  NetsuiteSuiteAppConfig,
  CarrierComplianceApi
} from '@shipwell/backend-core-sdk';
import {
  InvoicingApi,
  InvoicingApiInvoicingShipmentsShipmentIdInvoicesGetRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {
  CapacityProviderSchema,
  CreateCapacityProviderSchema,
  ProviderCode,
  TransportationMode
} from '@shipwell/genesis-sdk';
import {getAccessToken} from 'App/api/utils';
import {getCarrierIntegrationApi} from 'App/api/integrations/index';

interface Account {
  modes: Array<string>;
}

function createPaymentsApi() {
  return new PaymentsApi(
    new Configuration({
      basePath: process.env.SHIPWELL_API_BASE_PATH,
      apiKey: getAccessToken
    })
  );
}

function createIntegrationsApi() {
  return new IntegrationsApi(
    new Configuration({
      basePath: process.env.SHIPWELL_API_BASE_PATH,
      apiKey: getAccessToken
    })
  );
}

function createInvoicingApi() {
  return new InvoicingApi(
    new Configuration({
      basePath: process.env.SHIPWELL_API_BASE_PATH,
      apiKey: getAccessToken
    })
  );
}

function createCarrierComplianceApi() {
  return new CarrierComplianceApi(
    new Configuration({
      basePath: process.env.SHIPWELL_API_BASE_PATH,
      apiKey: getAccessToken
    })
  );
}

const paymentsApi = createPaymentsApi();
const integrationsApi = createIntegrationsApi();
const invoicingApi = createInvoicingApi();
const carrierComplianceApi = createCarrierComplianceApi();

export const getTriumphPaySettingsApprovedDateSourceOptions = (opts = {}) =>
  paymentsApi.paymentsIntegrationsTriumphPaySettingsApprovedDateSourceGet(opts);

export const getTriumphPaySettings = (opts = {}) => paymentsApi.paymentsIntegrationsTriumphPaySettingsGet(opts);

export const updateTriumphPaySettings = (body: TriumphPayAuthSettings, opts = {}) =>
  paymentsApi.paymentsIntegrationsTriumphPaySettingsPut(body, opts);

export const getNetSuiteConfig = (opts = {}) => integrationsApi.integrationsNetsuiteConfigGet(opts);

export const postNetSuiteConfig = (body: NetsuiteConfig, opts = {}) =>
  integrationsApi.integrationsNetsuiteConfigPost(body, opts);

export const updateNetSuiteConfig = (body: NetsuiteConfig, opts = {}) =>
  integrationsApi.integrationsNetsuiteConfigPut(body, opts);

export const deleteNetSuiteConfig = (opts = {}) => integrationsApi.integrationsNetsuiteConfigDelete(opts);

export const getNetSuiteCategoryOptions = (opts = {}) =>
  integrationsApi.integrationsNetsuiteConfigOptionsCategoriesGet(opts);

export const getNetSuiteCustomOptions = (opts = {}) =>
  integrationsApi.integrationsNetsuiteConfigOptionsCustomFieldsGet(opts);

export const getNetSuiteCompanyOptions = (opts = {}) =>
  integrationsApi.integrationsNetsuiteConfigOptionsCompanyGet(opts);

export const getNetSuiteCategoryMappings = (opts = {}) =>
  integrationsApi.integrationsNetsuiteConfigCategoryMappingsGet(opts);

export const updateNetSuiteCategoryMappings = (body: NetsuiteConfigCategoryMappings, opts = {}) =>
  integrationsApi.integrationsNetsuiteConfigCategoryMappingsPut(body, opts);

export const getNetSuiteFieldMappings = (opts = {}) => integrationsApi.integrationsNetsuiteConfigFieldMappingsGet(opts);

export const updateNetSuiteFieldMappings = (body: NetsuiteConfigFieldMappings, opts = {}) =>
  integrationsApi.integrationsNetsuiteConfigFieldMappingsPut(body, opts);

export const getSuiteAppConfig = (opts = {}) => integrationsApi.integrationsNetsuiteConfigSuiteappGet(opts);

export const postSuiteAppConfig = (body: NetsuiteSuiteAppConfig, opts = {}) =>
  integrationsApi.integrationsNetsuiteConfigSuiteappPost(body, opts);

export const updateSuiteAppConfig = (body: NetsuiteSuiteAppConfig, opts = {}) =>
  integrationsApi.integrationsNetsuiteConfigSuiteappPut(body, opts);

export const deleteSuiteAppConfig = (opts = {}) => integrationsApi.integrationsNetsuiteConfigSuiteappDelete(opts);
export const refreshSuiteAppConfig = () => integrationsApi.integrationsNetsuiteConfigSuiteappRefreshPut();

export const getSuiteAppToken = (opts = {}) => integrationsApi.integrationsNetsuiteConfigSuiteappTokenGet(opts);

export const getProviderProvidersProviderCodeGet = (providerCode: ProviderCode) => {
  return getCarrierIntegrationApi().getProviderProvidersProviderCodeGet({
    providerCode
  });
};

export const createProviderProvidersPost = (createCapacityProviderSchema: CreateCapacityProviderSchema) => {
  return getCarrierIntegrationApi().createProviderProvidersPost({
    createCapacityProviderSchema
  });
};

export const updateProviderProvidersPut = (createCapacityProviderSchema: CreateCapacityProviderSchema) => {
  return getCarrierIntegrationApi().updateProviderProvidersPut({createCapacityProviderSchema});
};

export const deleteProvider = async (providerCode: ProviderCode, opts = {}) => {
  void (await getCarrierIntegrationApi().deleteProviderProvidersProviderCodeDelete({providerCode}, opts));
};

export const getShipmentInvoices = (request: InvoicingApiInvoicingShipmentsShipmentIdInvoicesGetRequest) => {
  return invoicingApi.invoicingShipmentsShipmentIdInvoicesGet(request);
};

export const getCapacityProviders = async () => {
  const axiosResponse = await getCarrierIntegrationApi().getAllProvidersProvidersGet();
  return axiosResponse.data;
};
export const getCapacityProvidersByMode = async (mode: TransportationMode) => {
  const listConnections = await getCarrierIntegrationApi().getAllProvidersProvidersGet();

  return listConnections.data
    .filter((currentCarrier: CapacityProviderSchema) =>
      currentCarrier.accounts?.some(
        (account: Account) => account?.modes?.includes(mode) || currentCarrier?.modes?.includes(mode)
      )
    )
    .map((currentCarrier) => Boolean(currentCarrier));
};

export const checkRMISAuthentication = () => {
  return carrierComplianceApi.carrierComplianceIntegrationsRmisAuthCheckGet();
};
