import {InlineResponse2004, ShipwellError} from '@shipwell/backend-core-singlerequestparam-sdk';
import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {reverifyUPSPost} from 'App/api/registration/typed';
import {upsPopupSizeOptions} from 'App/containers/ups/registration/constants';
import {openPopupWindow} from 'App/utils/globalsTyped';

export const useGetUpsAuthStatus = (options: UseMutationOptions<InlineResponse2004, ShipwellError, string>) => {
  const {mutate: verifyAccount, isLoading: isFetchingVerifyLink} = useMutation(
    (upsAccountId: string) => reverifyUPSPost(upsAccountId),
    {
      onSuccess: ({oauth_url}) => {
        if (oauth_url) openPopupWindow({url: oauth_url, ...upsPopupSizeOptions});
      },
      ...options
    }
  );

  return {verifyAccount, isFetchingVerifyLink};
};
