import {SvgIcon, ToggleSwitch} from '@shipwell/shipwell-ui';
import {Column, ColumnOrderState, OnChangeFn} from '@tanstack/react-table';
import {ChangeEvent, ReactNode} from 'react';
import startCase from 'lodash/startCase';
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd';
import {TableContainer} from '../baseComponents';
import {reorder} from 'App/utils/dragAndDrop';

export const TableColumnsTab = <T,>({
  onColumnOrderChange,
  columns,
  footer,
  disabledIds,
  labels
}: {
  columns: Pick<Column<T, unknown>, 'id' | 'getToggleVisibilityHandler' | 'getIsVisible'>[];
  onColumnOrderChange: OnChangeFn<ColumnOrderState>;
  footer?: ReactNode;
  disabledIds?: string[];
  labels?: Record<string, string>;
}) => {
  const handleDropEnd = (result: DropResult) => {
    const draggedFromIndex = result?.source?.index;
    const draggedToIndex = result?.destination?.index;
    if (draggedFromIndex === undefined || draggedToIndex === undefined || draggedFromIndex === draggedToIndex) {
      return;
    }
    onColumnOrderChange((prevOrder) => {
      return reorder(
        prevOrder,
        draggedFromIndex + (disabledIds?.length || 0),
        draggedToIndex + (disabledIds?.length || 0)
      );
    });
  };

  // Labels default to the id of the column, the id is parsed to startCase via lodash
  // if a `labels` prop is provided and a key matches a column's id, that label is used instead
  const getLabel = (id: string) => {
    if (!labels || !labels?.[id]) {
      return startCase(id);
    }
    return labels[id];
  };

  return (
    <TableContainer>
      <div className="border-b border-sw-border p-2 text-xxs text-sw-disabled-text">
        <span className="uppercase">Drag to reorder columns</span>
      </div>
      <TableContainer>
        <DragDropContext onDragEnd={handleDropEnd}>
          <Droppable droppableId="tableColumns">
            {(provided) => (
              <div ref={provided.innerRef}>
                {columns
                  .filter((col) => !disabledIds?.includes(col.id))
                  .map((column, index) => {
                    return (
                      <Draggable key={column.id} index={index} draggableId={column.id || ''}>
                        {(provided) => {
                          return (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <ColumnItem
                                id={column.id}
                                label={getLabel(column.id)}
                                onChange={column.getToggleVisibilityHandler()}
                                isChecked={column.getIsVisible()}
                              >
                                <div {...provided.dragHandleProps}>
                                  <SvgIcon height="20" name="Drag" color="sw-icon" />
                                </div>
                              </ColumnItem>
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </TableContainer>
      {footer}
    </TableContainer>
  );
};

const ColumnItem = ({
  id,
  label,
  children,
  onChange,
  isChecked
}: {
  id: string;
  label: string;
  children: ReactNode;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
}) => {
  return (
    <div className="flex items-center gap-2 border-b border-sw-border bg-sw-background-component p-2">
      {children}
      <label htmlFor={id} className="m-0 flex w-full items-center justify-between gap-2 font-normal">
        <span>{label}</span>
        <ToggleSwitch name={id} onChange={onChange} checked={isChecked} fixedHeight={false} />
      </label>
    </div>
  );
};
