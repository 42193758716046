import {connect} from 'react-redux';
import {Formik, Form, Field} from 'formik';
import get from 'lodash/get';
import set from 'lodash/set';
import Classnames from 'classnames';
import {
  CustomFieldEntityTypesEnum,
  Shipment,
  ShipmentReference,
  User
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {FormikTextInput, Title, Rule, FormikSelect, Pill} from '@shipwell/shipwell-ui';
import {createValidationSchema} from 'App/containers/Shipment/components/References/utils/validation';
import type {CustomField} from 'App/containers/Shipment/components/References/utils/validation';
import type {Context, ReferenceFieldName} from 'App/containers/Shipment/components/References/utils/constants';
import EditReferenceFields from 'App/containers/Shipment/components/References/Edit/EditReferenceFields';
import type {State} from 'App/reducers/types';
import EditActionButtons from 'App/containers/Shipment/components/EditActionButtons';
import EditErrorDisplay from 'App/containers/Shipment/components/EditErrorDisplay';
import {isCustomFieldOwner} from 'App/utils/customData';
import {getCustomDataPath} from 'App/utils/customDataPath';
import {CustomFieldsContextConsumer, useUserMe} from 'App/data-hooks';
import omitEmptyKeys from 'App/utils/omitEmptyKeys';
import {isSingleSelectionList} from 'App/containers/orders/utils';

type ReferenceValues = Context & {
  custom_data: unknown;
};

const createDefaultValues = (customFields: CustomField[], referencesArray: ShipmentReference[]): ReferenceValues => {
  const referencesArrayFields = referencesArray?.reduce(
    (prev, curr) => ({...prev, [curr.qualifier as ReferenceFieldName]: curr.value || null}),
    {}
  );

  return {
    bol_number: null,
    pro_number: null,
    pickup_number: null,
    purchase_order_number: null,
    tracking_number: null,
    drayage_booking_number: null,
    drayage_container_number: null,
    drayage_carrier_scac_code: null,
    drayage_house_bol_number: null,
    drayage_seal_number: null,
    drayage_chassis_number: null,
    customer_reference_number: null,
    ...referencesArrayFields,
    custom_data: set(
      {},
      getCustomDataPath(CustomFieldEntityTypesEnum.Shipment),
      customFields.reduce(
        (customDataFields, cf) => ({
          ...customDataFields,
          [cf.id]: cf.default_value
        }),
        {}
      )
    )
  };
};

const ShipmentReferencesUpdateForm = ({
  onSubmit,
  shipment,
  initialValues,
  user,
  shipmentCarrier,
  ...props
}: {
  onSubmit: () => void;
  onCancelEdit: () => void;
  shipment: Shipment;
  initialValues: ReferenceValues;
  user: User;
  shipmentCarrier: {
    carrier_reference_codes: {value: string}[];
  };
}) => {
  const userMeData = useUserMe();
  const hasCarrier = shipment?.relationship_to_vendor?.vendor;
  const isQuotingLimitedUser = user.is_quoting_limited_user;
  const formatDropdownOption = (
    {name, isDefault}: {name: string; isDefault: boolean},
    {context}: {context: string}
  ) => {
    return (
      <div className={Classnames('shipment__references-carrierReference', {selected: context === 'value'})}>
        <span>{name}</span>
        {isDefault ? (
          <Pill variant="default" size="sm">
            DEFAULT
          </Pill>
        ) : null}
      </div>
    );
  };

  return (
    <div className="shipment__edit">
      <CustomFieldsContextConsumer>
        {(consumer) => {
          const {customFields} = consumer as unknown as {customFields: CustomField[]};
          const customFieldsArray: CustomField[] = customFields ?? [];
          const validationSchemaWithCustomFields = createValidationSchema(customFieldsArray, user.company_id);
          const defaultValuesWithCustomFields = createDefaultValues(customFieldsArray, shipment?.references || []);
          const consolidatedInitialValues = {
            ...defaultValuesWithCustomFields,
            ...omitEmptyKeys(initialValues)
          } as ReferenceValues;

          return (
            <Formik
              onSubmit={onSubmit}
              validationSchema={validationSchemaWithCustomFields}
              initialValues={consolidatedInitialValues}
            >
              {(FormikProps) => {
                return (
                  <Form noValidate>
                    <Title variant="sectionTitle">Customers</Title>
                    <div className="field-grid">
                      <Field
                        label="Customer Reference #"
                        name="customer_reference_number"
                        component={FormikTextInput}
                        type="text"
                        placeholder="Customer Reference #"
                        normalize={(value: string) => value || null}
                        disabled={isQuotingLimitedUser}
                      />
                    </div>
                    <Rule />
                    <Title variant="sectionTitle">Reference Numbers</Title>
                    <EditReferenceFields mode={shipment?.mode} isQuotingLimitedUser={isQuotingLimitedUser} />
                    {hasCarrier && (
                      <div className="field-grid grid-1-1">
                        <Field
                          label="Carrier Reference Code #"
                          name="carrier_reference_code"
                          simpleValue
                          simpleValueOption="value"
                          component={FormikSelect}
                          disabled={isQuotingLimitedUser}
                          options={shipmentCarrier?.carrier_reference_codes?.map((code) => {
                            return {...code, label: code.value};
                          })}
                          formatOptionLabel={(
                            opt: {label: string; is_default: boolean},
                            {context}: {context: string}
                          ) =>
                            formatDropdownOption(
                              {
                                name: get(opt, 'label'),
                                isDefault: get(opt, 'is_default')
                              },
                              {context}
                            )
                          }
                        />
                      </div>
                    )}

                    <div className="field-grid grid-1-2">
                      {customFieldsArray
                        .filter(
                          (cf) =>
                            isCustomFieldOwner(cf.company, user.company_id) ||
                            cf.allow_carrier_view ||
                            userMeData.data?.company?.name === 'SYSTEM-WIDE SUPPLIER TENANT'
                        )
                        .map((cf) => {
                          return (
                            <Field
                              simpleValue
                              simpleOptionValue="label"
                              key={cf.id}
                              label={cf.label}
                              name={`custom_data.${getCustomDataPath(CustomFieldEntityTypesEnum.Shipment)}.${cf.id}`}
                              required={cf.required}
                              options={cf.allowed_values}
                              component={isSingleSelectionList(cf.field_type) ? FormikSelect : FormikTextInput}
                              normalize={(value: string) => value || null}
                              disabled={!cf.allow_carrier_edit && !isCustomFieldOwner(cf.company, user.company_id)}
                            />
                          );
                        })}
                    </div>
                    <EditActionButtons {...props} {...FormikProps} />
                    <EditErrorDisplay {...FormikProps} />
                  </Form>
                );
              }}
            </Formik>
          );
        }}
      </CustomFieldsContextConsumer>
    </div>
  );
};

export default connect(({shipmentdetails}: State) => {
  const {shipmentRepRoles, shipment_references, shipmentCarrier} = shipmentdetails;
  return {
    shipmentRepRoles,
    initialValues: shipment_references,
    shipmentCarrier
  };
})(ShipmentReferencesUpdateForm);
