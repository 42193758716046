import {Children, cloneElement, Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import moment from 'moment';
import _ from 'lodash';
import get from 'lodash/get';
import pluralize from 'pluralize';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import {Modal, Toast} from '@shipwell/shipwell-ui';
import DashboardSummary from 'App/components/dashboardSummary';
import * as marketplaceActions from 'App/actions/marketplace';
import * as userActions from 'App/actions/users';
import * as formActions from 'App/actions/forms';
import * as shipmentActions from 'App/actions/shipments';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import GetSpotQuotes from 'App/components/GetSpotQuotes/GetSpotQuotes';
import {leftArrow} from 'App/common/Assets';
import TenderRequestForm from 'App/containers/tendering/create';
import {TenderingUserPermissionFallback} from 'App/components/permissions/PermissionsFallback/TenderingUserPermissionFallback';
import {
  SHIPMENT_CREATE_SPOT_NEGOTIATIONS,
  CREATE_SHIPMENT_DIRECT_TENDER
} from 'App/components/permissions/PermissionsFallback/constants';

import './_styles.scss';

let quotingTimeout;
let quoteInterval = null;

const InstantRatesTooltip = withStyles((theme) => ({
  popper: {
    opacity: 1,
    pointerEvents: 'auto'
  },
  tooltip: {
    background: '#26282b',
    padding: '10px',
    maxWidth: '350px'
  }
}))(Tooltip);

@connect(
  (state) => ({
    selectedShipment: state.shipments.selectedShipment,
    selectedShipmentAuctionStatus: state.shipments.selectedShipmentAuctionStatus,
    shipmentSpotNegotiations: state.marketplace.shipmentSpotNegotiations,
    selectedSpotNegotiation: state.marketplace.selectedSpotNegotiation,
    user: state.auth.user,
    company: state.auth.company,
    featureFlags: state.auth.company && state.auth.company.feature_flags,
    selectedRelationship: state.vendors.selectedRelationship,
    selectedRFQ: state.shipments.selectedRFQ
  }),
  {
    ...shipmentActions,
    ...formActions,
    ...userActions,
    ...marketplaceActions
  }
)
export default class Marketplace extends Component {
  constructor(props, ...args) {
    super(props, ...args);

    this.getSpotNegotiationDetails = this.getSpotNegotiationDetails.bind(this);
    this.fetchShipmentDetails = this.fetchShipmentDetails.bind(this);
    this.toggleRequestBidSuccess = this.toggleRequestBidSuccess.bind(this);
    this.closeCarrierRequestModal = this.closeCarrierRequestModal.bind(this);
    this.state = {
      tabsValue: window.location.pathname.includes('bids') ? 'bids' : 'instant-rates',
      loading: true,
      loadingQuotes: true,
      errorFetchingShipment: false,
      updating: false,
      showCarrierRequestModal: false,
      showFTLInstantRateMessage: false,
      showTenderingModal: false,
      requestBidSuccess: false
    };
  }

  componentDidMount() {
    if (this.props.company && this.props.company.id) {
      //if company doesn't see ftl instant rates, open the modal to request rates immediately
      if (!this.props.company.feature_flags.ftl_instant_rates_enabled) {
        //open modal to request rates
        this.showCarrierRequestModal();
      }
      //this.fetchShipmentDetails();
      this.props.fetchEquipmentTypes();
      this.props.fetchShipmentModes();
      this.props.fetchServiceLevels();

      this.intialize();
    }
  }

  componentDidUpdate(prevProps) {
    const {router} = this.props;
    const requestRates = get(router, 'location.query.requestRates');
    const prevRequestRates = get(prevProps, 'location.query.requestRates');
    if (prevProps.company !== this.props.company && !_.isEmpty(this.props.company)) {
      //get details
      this.intialize();
    }
    if (
      prevProps.selectedRFQ !== this.props.selectedRFQ &&
      this.props.selectedRFQ.autoquote === this.props.selectedRFQ.did_finish_autoquote
    ) {
      //if this is an FTL shipment and there are autoquotes available and we're on bids page, we want to direct user
      const {quotes} = this.props.selectedRFQ;
      if (quotes.length > 0) {
        if (quotes.find((e) => e.mode && e.mode.id === 1) && window.location.pathname.includes('bids')) {
          this.setState({showFTLInstantRateMessage: true});
        } else if (!quotes.find((e) => e.mode && e.mode.id === 1)) {
          this.showCarrierRequestModal();
        }
      } else {
        //open modal to request rates
        this.showCarrierRequestModal();
      }
    } else if (requestRates && requestRates !== prevRequestRates) {
      this.showCarrierRequestModal();
    }
  }

  intialize() {
    this.props.getShipmentDetails(this.props.params.shipment_id).then((response) => {
      if (response && response.status === 200) {
        const parentRFQ = this.getParentRFQ(response.details);
        if (parentRFQ && parentRFQ.autoquote === true) {
          //know whether we are checking for instant rates
          this.getRFQDetails(parentRFQ);
        } else {
          //open modal to request rates
          this.showCarrierRequestModal();
        }
        this.getSpotNegotiationDetails(response.details);
      } else {
        //this 404'd or something similar, show an error
        this.setState({
          loading: false,
          loadingQuotes: false,
          errorFetchingShipment: true
        });
      }
    });
  }

  showCarrierRequestModal(override) {
    if (window.location.pathname.includes('bids') && (this.props.location?.query.requestRates || override)) {
      this.props.getSpotNegotiationEmailPreview(this.props.params.shipment_id, {isCustomerFacing: false});
      this.setState({showCarrierRequestModal: true});
    }
  }

  closeCarrierRequestModal() {
    const {router} = this.props;
    this.setState({showCarrierRequestModal: false});
    // clear query params when closing the request bids modal
    router.push(router.location.pathname);
  }

  fetchShipmentDetails() {
    this.props.getShipmentDetails(this.props.params.shipment_id).then((response) => {
      //if the customer in the response is not the currently logged in person, we want to be masquerading
      this.getSpotNegotiationDetails(response.details);
    });
  }

  getParentRFQ(shipment) {
    let rfqs = JSON.parse(JSON.stringify(shipment.rfqs));
    //if there are no RFQs, maybe show error message?
    if (rfqs.length > 0) {
      //filter down to the parent RFQ on this page
      rfqs = rfqs.filter((e) => e.has_parent_rfq === false);

      //if you are the owner of the shipment, filter down to just RFQs that you own (since you can't see broker's)
      if (shipment.customer.id === this.props.company.id) {
        rfqs = rfqs.filter((e) => e.company_owner_id === this.props.company.id);
      } else {
        //if you are a broker on this shipment (not the root customer), you should also be looking at the customer's RFQ
        //so that you correctly see your markup attached to the quote you are working with
        rfqs = rfqs.filter((e) => e.company_owner_id === shipment.customer.id);
      }

      rfqs = rfqs.sort(function (a, b) {
        return a.updated_at > b.updated_at ? -1 : 1;
      });
      const lastRFQ = rfqs[0];

      return lastRFQ;
    }

    return null;
  }

  pollForQuotes(ms = 1000) {
    const {selectedRFQ} = this.props;
    if (selectedRFQ && selectedRFQ.autoquote && !selectedRFQ.did_finish_autoquote) {
      //get quote details
      quoteInterval = setInterval(() => {
        this.getRFQDetails(selectedRFQ);
      }, ms);
    } else if (selectedRFQ && selectedRFQ.autoquote && selectedRFQ.did_finish_autoquote) {
      clearInterval(quoteInterval);
      quoteInterval = null;
      clearTimeout(quotingTimeout);
    }
  }

  getRFQDetails(RFQ) {
    const opts = {includeFailures: true};
    if (RFQ.company_owner_id !== this.props.company.id) {
      opts['xCompanyId'] = RFQ.company_owner_id;
    }
    this.props.getRFQDetails(RFQ.id, opts).then((response) => {
      if (response && response.details && response.details.autoquote && response.details.did_finish_autoquote) {
        //kill the loop now
        clearInterval(quoteInterval);
        quoteInterval = null;
        clearTimeout(quotingTimeout);
        this.setState({loadingQuotes: false});
      } else {
        if (!quoteInterval) {
          //if we havent started polling yet, do so now
          this.pollForQuotes();
        }
      }
    });
  }

  getSpotNegotiationDetails(shipment) {
    //use the shipmentId to get all the spot negotiations associated with this user for this shipment
    this.props.getAllSpotNegotiationsByShipment(shipment.id).then((response) => {
      if (response.status === 200) {
        this.setState({loading: false, loadingQuotes: false});
      }
    });
  }

  /* Toggle the loadboard status for this shipment */
  toggleLoadboardEnabled(newLoadboardStatus) {
    const {selectedShipment} = this.props;
    this.setState({updating: true});
    this.updateShipment({
      metadata: {
        archived: selectedShipment.metadata.archived,
        open: selectedShipment.metadata.open,
        tags: selectedShipment.metadata.tags,
        load_board_enabled: newLoadboardStatus,
        load_board_id: selectedShipment.metadata.load_board_id
      },
      state: selectedShipment.state
    });
  }

  //open/close the auction
  toggleAuctionStatus() {
    const body = {};
    const myAuctions = this.props.shipmentSpotNegotiations.results.filter((e) => e.vendor.id !== this.props.company.id);
    body.spot_negotiations = myAuctions.map((e) => e.id);
    //toggle to opposite of current state on the negotiations
    body.close = !moment(myAuctions[0].closed_by_customer_at).isValid();
    this.props.openCloseSpotNegotiation(body).then((response) => {
      if (response.status === 200) {
        if (body.close === false) {
          //when reopening an auction, delete any vendor assignments on the shipment
          this.props.unassignCarrier(this.props.params.shipment_id).then((response) => {
            if (response.status === 200) {
              this.fetchShipmentDetails();
            }
          });
        } else {
          //refetch details
          this.fetchShipmentDetails();
        }
      }
    });
  }

  //update the shipment with the new metadata fields
  updateShipment(attrs) {
    const shipmentId = this.props.params.shipment_id;
    const payload = Object.assign(this.props.selectedShipment, attrs);
    return this.props.editShipment(shipmentId, payload, {}).then((response) => {
      if (response.status === 200) {
        this.fetchShipmentDetails();
      }
      this.setState({updating: false});
    });
  }

  handleTabsChange(e, value) {
    //direct to new url, and reset to marketplace details if on a bids detail view
    if (value !== this.state.tabsValue || this.props.params.bid_id) {
      //direct to new page
      this.props.router.push(`/marketplace/${this.props.params.shipment_id}/${value}`);
      this.setState({tabsValue: value});
      // if the user views the instant rates tab at any point, stop showing the tooltip prompt to view instant rates
      if (value === 'instant-rates') {
        this.setState({showFTLInstantRateMessage: false});
      }
    }
  }

  handleToggleTenderingModal = (shipment) => {
    if (shipment) {
      this.setState({showTenderingModal: true});
    } else {
      this.setState({showTenderingModal: false});
    }
  };

  toggleRequestBidSuccess() {
    this.setState({requestBidSuccess: !this.state.requestBidSuccess});
  }

  render() {
    const {
      selectedShipment,
      featureFlags = {},
      selectedShipmentAuctionStatus,
      selectedSpotNegotiation,
      shipmentSpotNegotiations,
      company,
      hideTabs = false,
      hideShipmentInfo = false
    } = this.props;
    const load_board_enabled =
      selectedShipment && selectedShipment.metadata ? selectedShipment.metadata.load_board_enabled : null;

    const {tabsValue, loading, loadingQuotes, updating} = this.state;
    const loadId =
      load_board_enabled && selectedShipment && selectedShipment.metadata && selectedShipment.metadata.load_board_id
        ? '| Load # ' + selectedShipment.metadata.load_board_id
        : '';
    //clone children so we can pass along functions from this parent
    const children = Children.map(this.props.children, (child) => {
      return cloneElement(child, {
        fetchShipmentDetails: this.fetchShipmentDetails
      });
    });

    const isCarrierDetailView = this.props.params.bid_id;
    const selectedRelationship =
      isCarrierDetailView && this.props.selectedRelationship ? this.props.selectedRelationship : null; // make sure this isnt showing if not on the detail view
    const carrierName =
      selectedSpotNegotiation && selectedSpotNegotiation.vendor && selectedSpotNegotiation.vendor.name;

    const tabOptions = ['details'];

    const carrierDetailHeader = (
      <div className="flex">
        <span className="marketplace__header-backArrow" onClick={() => this.handleTabsChange({}, 'bids')}>
          {leftArrow()}
        </span>
        <span>{carrierName}</span>
      </div>
    );

    const hasSpotNegs =
      shipmentSpotNegotiations &&
      shipmentSpotNegotiations.results &&
      shipmentSpotNegotiations.results.length > 0 &&
      shipmentSpotNegotiations.results.filter((e) => e.vendor.id !== company.id).length > 0;
    const hasOpenSpotNegs =
      shipmentSpotNegotiations &&
      shipmentSpotNegotiations.results &&
      shipmentSpotNegotiations.results.length > 0 &&
      shipmentSpotNegotiations.results.filter(
        (e) => !e.closed_by_customer_at || !moment(e.closed_by_customer_at).isValid()
      ).length > 0;

    let instantRatesAvailable = 0;
    if (this.state.showFTLInstantRateMessage && this.props.selectedRFQ && this.props.selectedRFQ.quotes) {
      instantRatesAvailable = this.props.selectedRFQ.quotes.length;
    }

    return (
      <div className="marketplaceWrapper content-wrapper">
        {this.state.errorFetchingShipment ? (
          <h2 className="text-center">
            Sorry, there was an error getting the details of this shipment. Please refresh your page or check the URL.{' '}
          </h2>
        ) : loading || loadingQuotes ? (
          <div style={{height: '100vh'}}>
            <ShipwellLoader loading={loading || loadingQuotes} />
          </div>
        ) : (
          <div className="marketplace">
            {!hideTabs && (
              <Tabs
                classes={{root: 'tabs-root', indicator: 'tabs-container'}}
                value={tabsValue}
                onChange={this.handleTabsChange.bind(this)}
              >
                {(!this.state.showFTLInstantRateMessage || tabsValue === 'instant-rates') && (
                  <Tab
                    value="instant-rates"
                    classes={{
                      root: 'tabs',
                      selected: 'tabs-selected',
                      label: 'tabs-label'
                    }}
                    label={<span className="tabs">Instant Rates</span>}
                  />
                )}
                {this.state.showFTLInstantRateMessage && tabsValue !== 'instant-rates' && (
                  <Tab
                    value="instant-rates"
                    classes={{
                      root: 'tabs',
                      selected: 'tabs-selected',
                      label: 'tabs-label'
                    }}
                    label={
                      <InstantRatesTooltip
                        open
                        placement="bottom-start"
                        title={
                          <span className="marketplace__instantRatesTooltip" onClick={(e) => e.stopPropagation()}>
                            <span className="marketplace__instantRatesTooltip-arrow"></span>
                            {`${instantRatesAvailable} Instant ${pluralize('Rate', instantRatesAvailable)} available. `}
                            <Link
                              onClick={() => {
                                this.setState({showFTLInstantRateMessage: false});
                                this.handleTabsChange({}, 'instant-rates');
                              }}
                            >
                              Go to Instant Rates
                            </Link>

                            <span
                              className="marketplace__instantRatesTooltip-close"
                              onClick={() => this.setState({showFTLInstantRateMessage: false})}
                            >
                              <i className="flaticon-multiply" />
                            </span>
                          </span>
                        }
                      >
                        <span className="tabs">Instant Rates</span>
                      </InstantRatesTooltip>
                    }
                  />
                )}
                <Tab
                  value="bids"
                  classes={{
                    root: 'tabs',
                    selected: 'tabs-selected',
                    label: 'tabs-label'
                  }}
                  label={<span className="tabs">Carrier Bids</span>}
                />
              </Tabs>
            )}

            <div className="marketplace__content p-2">
              <Grid container spacing={2}>
                <Grid className="marketplace__header gap-x-2 pl-2">
                  {!hideShipmentInfo && (
                    <div className="marketplace__header-id">
                      <h3>
                        {isCarrierDetailView
                          ? carrierDetailHeader
                          : `Shipment ${selectedShipment.reference_id} ${loadId}`}
                      </h3>
                    </div>
                  )}
                  {tabsValue === 'instant-rates' && !this.props.hideEditShipment ? (
                    <Link to={`/shipments/${selectedShipment.id}/edit`}>Edit Shipment</Link>
                  ) : null}

                  {tabsValue === 'bids' && !isCarrierDetailView && !hideShipmentInfo && (
                    <Fragment>
                      <div className={`marketplace__header-actions ${updating ? 'disabled' : ''}`}>
                        <Link to={`/shipments/${selectedShipment.id}`}>{`Shipment Details`}</Link>
                        {
                          //only show this link if there are spot negotiations
                          hasSpotNegs && (
                            <TenderingUserPermissionFallback permissions={[SHIPMENT_CREATE_SPOT_NEGOTIATIONS]}>
                              <Link onClick={this.toggleAuctionStatus.bind(this)}>
                                {hasOpenSpotNegs ? 'Close Auction' : 'Reopen Auction'}
                              </Link>
                            </TenderingUserPermissionFallback>
                          )
                        }
                        {featureFlags.load_board_enabled && (
                          <TenderingUserPermissionFallback permissions={[SHIPMENT_CREATE_SPOT_NEGOTIATIONS]}>
                            <Link
                              onClick={() => {
                                this.toggleLoadboardEnabled(!load_board_enabled);
                              }}
                            >
                              {updating
                                ? 'Updating...'
                                : load_board_enabled
                                ? 'Remove from Load Board'
                                : 'Add to Load Board'}
                            </Link>
                          </TenderingUserPermissionFallback>
                        )}
                        <TenderingUserPermissionFallback permissions={[SHIPMENT_CREATE_SPOT_NEGOTIATIONS]}>
                          <Link onClick={() => this.showCarrierRequestModal(true)}>{`Request ${
                            hasSpotNegs ? 'More' : ''
                          } Bids`}</Link>
                        </TenderingUserPermissionFallback>
                        <TenderingUserPermissionFallback permissions={[CREATE_SHIPMENT_DIRECT_TENDER]}>
                          <Link
                            onClick={() => this.handleToggleTenderingModal(selectedShipment)}
                          >{`Tender to Carrier(s)`}</Link>
                        </TenderingUserPermissionFallback>
                      </div>
                      <span className="marketplace__header-actions-smallScreen">
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="bottom"
                          overlay={
                            <Popover id="marketplace-actions">
                              <div>
                                <Link to={`/shipments/${selectedShipment.id}`}>{`Shipment Details`}</Link>
                              </div>
                              {
                                //only show this link if there are spot negotiations
                                hasSpotNegs && (
                                  <TenderingUserPermissionFallback permissions={[SHIPMENT_CREATE_SPOT_NEGOTIATIONS]}>
                                    <div>
                                      <Link onClick={this.toggleAuctionStatus.bind(this)}>
                                        {hasOpenSpotNegs ? 'Close Auction' : 'Reopen Auction'}
                                      </Link>
                                    </div>
                                  </TenderingUserPermissionFallback>
                                )
                              }
                              {featureFlags.load_board_enabled && (
                                <TenderingUserPermissionFallback permissions={[SHIPMENT_CREATE_SPOT_NEGOTIATIONS]}>
                                  <div>
                                    <Link
                                      onClick={() => {
                                        this.toggleLoadboardEnabled(!load_board_enabled);
                                      }}
                                    >
                                      {updating
                                        ? 'Updating...'
                                        : load_board_enabled
                                        ? 'Remove from Load Board'
                                        : 'Add to Load Board'}
                                    </Link>
                                  </div>
                                </TenderingUserPermissionFallback>
                              )}
                              <TenderingUserPermissionFallback permissions={[SHIPMENT_CREATE_SPOT_NEGOTIATIONS]}>
                                <div>
                                  <Link onClick={() => this.showCarrierRequestModal(true)}>{`Request ${
                                    hasSpotNegs ? 'More' : ''
                                  } Bids`}</Link>
                                </div>
                              </TenderingUserPermissionFallback>
                              <TenderingUserPermissionFallback permissions={[CREATE_SHIPMENT_DIRECT_TENDER]}>
                                <div>
                                  <Link
                                    onClick={() => this.handleToggleTenderingModal(selectedShipment)}
                                  >{`Tender to Carrier(s)`}</Link>
                                </div>
                              </TenderingUserPermissionFallback>
                            </Popover>
                          }
                        >
                          <a role="button">
                            <i className="flaticon-more-1 action-link" />
                          </a>
                        </OverlayTrigger>
                      </span>
                    </Fragment>
                  )}
                </Grid>
                {!hideShipmentInfo && (
                  <Grid item xs={12} sm={12} md={4}>
                    <div className="marketplace__header-status flex h-full">
                      <h3 className="flex items-center gap-x-1">
                        <span>{selectedShipment.state.replace(/_/g, ' ')}</span>
                        {selectedShipmentAuctionStatus ? <span>(Auction Running)</span> : null}
                      </h3>
                    </div>
                  </Grid>
                )}

                <Grid item xs={12} sm={12} md={hideShipmentInfo ? 12 : 8}>
                  {children}
                </Grid>
                {!hideShipmentInfo && (
                  <Grid item xs={12} sm={12} md={4}>
                    <Paper>
                      {isCarrierDetailView && carrierName && (
                        <div className="paper__header">
                          <h4>{carrierName}</h4>
                        </div>
                      )}
                      <DashboardSummary
                        featureFlags={featureFlags}
                        shipment={selectedShipment}
                        customer={selectedShipment.customer}
                        company={this.props.company}
                        tabOptions={tabOptions}
                        isLoadBoard={false}
                        isCarrierDetailView={isCarrierDetailView}
                        selectedRelationship={selectedRelationship}
                      />
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </div>

            <Modal
              title="Request Bids"
              show={this.state.showCarrierRequestModal}
              onHide={this.closeCarrierRequestModal}
              onClose={this.closeCarrierRequestModal}
              footerComponent={null}
            >
              <GetSpotQuotes
                handleClose={() => {
                  this.fetchShipmentDetails();
                  this.setState({showCarrierRequestModal: false});
                  this.closeCarrierRequestModal();
                }}
                hideTitle
                toggleRequestBidSuccess={this.toggleRequestBidSuccess}
              />
            </Modal>
            <Toast
              show={this.state.requestBidSuccess}
              variant="success"
              title="Bid Request Created!"
              onClose={() => this.setState({requestBidSuccess: false})}
            >
              Your bid request has been created successfully
            </Toast>
            <TenderRequestForm
              show={this.state.showTenderingModal}
              onClose={() => this.handleToggleTenderingModal()}
              router={this.context.router}
              //this attribute is not needed on shipment details, return true
              submitSucceeded
              shipmentDetailData={selectedShipment}
            />
          </div>
        )}
      </div>
    );
  }
}
