import {CellProps, Column, UseSortByColumnOptions} from 'react-table';
import capitalize from 'lodash/capitalize';
import isNil from 'lodash/isNil';
import {useQuery} from '@tanstack/react-query';
import {Tooltip} from '@shipwell/shipwell-ui';
import {RfpLaneOptResponse} from '@shipwell/backend-core-singlerequestparam-sdk';
import {RoutingGuideCell} from './RoutingGuideCell';
import {ContractsCell} from './ContractsCell';
import Loader from 'App/common/shipwellLoader';
import {toCurrency} from 'App/utils/grammar';
import {RFP_BIDS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {getRfpLaneBids} from 'App/api/rfpOptimized/typed';

const BidsTooltip = ({rfpLaneOptId, rfpBidOptId}: {rfpLaneOptId?: string; rfpBidOptId?: string}) => {
  const rfpBidsQuery = useQuery(
    [RFP_BIDS_QUERY_KEY, rfpLaneOptId, rfpBidOptId],
    async () => {
      const response = await getRfpLaneBids(rfpLaneOptId || '', rfpBidOptId || '');
      return response.data;
    },
    {
      enabled: !!(rfpLaneOptId && rfpBidOptId)
    }
  );

  const bidsSortedByPrice = rfpBidsQuery.data
    //filter out rfpBids without a valid current_bid, which includes the special
    //lane_carriers_without_bids entry
    ?.filter((rfpBid): rfpBid is typeof rfpBid & {current_bid: number} => !isNil(rfpBid.current_bid))
    .sort((a, b) => a.current_bid - b.current_bid);
  const bidCompanyNames =
    bidsSortedByPrice?.map((bid) => ({
      name: bid.carrier_name || '--',
      id: bid.id || ''
    })) || [];

  return (
    <div>
      <span className="whitespace-nowrap font-bold">Lowest Carrier Bids</span>
      {rfpBidsQuery.isInitialLoading ? <Loader loading /> : null}
      {rfpBidsQuery.isSuccess ? (
        <ol className="ml-4 list-decimal">
          {bidCompanyNames.slice(0, 5).map((company) => (
            <li key={company.id}>{company.name}</li>
          ))}
        </ol>
      ) : null}
      {rfpBidsQuery.isError ? <span>An error occured</span> : null}
    </div>
  );
};

export const columns: (Column<RfpLaneOptResponse> & UseSortByColumnOptions<RfpLaneOptResponse> & {label?: string})[] = [
  {
    id: 'lane_id',
    accessor: 'lane_id',
    Header: 'Lane ID',
    label: 'Lane ID',
    width: 100
  },
  {
    id: 'origin_city',
    accessor: 'origin_city',
    Header: 'Origin City',
    label: 'Origin City',
    width: 115
  },
  {
    id: 'origin_state',
    accessor: 'origin_state',
    Header: 'Origin State',
    label: 'Origin State',
    width: 125
  },
  {
    id: 'origin_post_code',
    accessor: 'origin_post_code',
    Header: 'Origin Postal Code',
    label: 'Origin Postal Code',
    width: 165
  },
  {
    id: 'destination_city',
    accessor: 'destination_city',
    Header: 'Dest City',
    label: 'Dest City',
    width: 105
  },
  {
    id: 'destination_state',
    accessor: 'destination_state',
    Header: 'Dest State',
    label: 'Dest State',
    width: 115
  },
  {
    id: 'destination_post_code',
    accessor: 'destination_post_code',
    Header: 'Dest Postal Code',
    label: 'Dest Postal Code',
    width: 160
  },
  {
    id: 'unique_bids',
    accessor: 'unique_bids_received',
    Header: 'Bids',
    label: 'Bids',
    Cell: ({row}: CellProps<RfpLaneOptResponse>) => {
      const {unique_bids_received, id, rfp_bid_opt} = row.original;
      if (unique_bids_received === undefined) {
        return <>--</>;
      }
      return (
        <Tooltip placement="right" tooltipContent={<BidsTooltip rfpLaneOptId={id} rfpBidOptId={rfp_bid_opt} />}>
          <span>{unique_bids_received}</span>
        </Tooltip>
      );
    },
    width: 110
  },
  {
    id: 'lowest_bid',
    accessor: 'lowest_bid',
    Header: 'Lowest Bid',
    label: 'Lowest Bid',
    Cell: ({value}) => <>{value ? toCurrency(value) : '--'}</>
  },
  {
    id: 'highest_bid',
    accessor: 'highest_bid',
    Header: 'Highest Bid',
    label: 'Highest Bid',
    Cell: ({value}) => <>{value ? toCurrency(value) : '--'}</>
  },
  {
    id: 'average_spend',
    accessor: 'average_spend',
    Header: 'Avg Spend',
    label: 'Avg Spend',
    width: 120,
    Cell: ({value}) => <>{value ? toCurrency(value) : '--'}</>
  },
  {
    id: 'price_volatility_text',
    accessor: 'price_volatility_text',
    Header: 'Price Volatility',
    label: 'Price Volatility',
    Cell: ({value}) => <>{value ? capitalize(value) : '--'}</>
  },
  {
    id: 'tender_rejection_rate',
    accessor: 'tender_rejection_rate',
    Header: 'Tender Rejection',
    label: 'Tender Rejection',
    width: 160,
    Cell: ({value}) => <>{value ? `${value}%` : '--'}</>
  },
  {
    id: 'volume',
    accessor: 'volume',
    Header: 'Volume',
    label: 'Volume',
    width: 100,
    Cell: ({value}) => <>{value ?? '--'}</>
  },
  {
    id: 'lane_policy_name',
    accessor: (row) => row.policy,
    Header: 'Routing Guide',
    label: 'Routing Guide',
    Cell: ({row}: CellProps<RfpLaneOptResponse>) => <RoutingGuideCell lane={row.original} />
  },
  {
    id: 'contracts',
    accessor: (row) => row.contracts,
    Header: 'Contracts',
    label: 'Contracts',
    Cell: ({row}: CellProps<RfpLaneOptResponse>) => <ContractsCell lane={row.original} />
  }
];
