import {Configuration, MovementsApi, MovementsApiGetMovementsRequest} from '@shipwell/locus-sdk';
import {getAccessToken} from 'App/api/utils';

const basePath = process.env.SHIPWELL_GENESIS_API_BASE_PATH;

const configuration = new Configuration({
  basePath,
  apiKey: getAccessToken
});

const movementsApi = new MovementsApi(configuration);

export const getMovement = async (params: MovementsApiGetMovementsRequest) => {
  const {data} = await movementsApi.getMovements(params);
  return data;
};
