import {Component} from 'react';
import _ from 'lodash';
import {Field, reduxForm, change, reset} from 'redux-form';
import {Link} from 'react-router';
import {ControlLabel, Button, Form, FormGroup, FormControl, OverlayTrigger, Tooltip, Row, Col} from 'react-bootstrap';
import {Async} from 'react-select';
import {connect} from 'react-redux';
import {Typeahead} from 'react-bootstrap-typeahead';
import ReactTable from 'react-table-6';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import renderField from 'App/formComponents/renderField';
import store from 'App/routes/store';
import * as actions from 'App/actions/shipments';
import * as brokerActions from 'App/actions/brokers';
import * as shipmentDetailsActions from 'App/actions/shipmentdetails';
import InfoModalWrapper from 'App/components/Modals/InfoModalWrapper';
import renderTypeahead from 'App/formComponents/renderTypeahead';
import {bind} from 'App/utils/camelize';
import {transformShipmentToForm} from 'App/utils/globals';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import * as tableHelpers from 'App/utils/shipmentTableHelpers';
import columns from 'App/containers/Dashboard/components/Columns';
import 'react-table-6/react-table.css';
import './_header.scss';
import RecentShipments from 'App/containers/quotes/create/components/recentShipments';

const ReactTableFixedColumns = withFixedColumns(ReactTable);
var Scroll = require('react-scroll');
var Element = Scroll.Element;

const columnSortingMapping = {
  equipment_type: 'equipment'
};

@connect(
  (state) => ({
    user: state.auth.user,
    company: state.auth.company,
    shipperRelationships: state.brokers.shipperRelationshipsForHeader,
    selectedShipment: state.shipments.selectedShipment,
    shipments: state.shipments.all,
    hazmatCodes: state.shipments.hazmatCodes,
    is_quoting_limited_user: state.auth.is_quoting_limited_user,
    isLoading: state.shipments.isLoading
  }),
  {...actions, ...brokerActions, ...shipmentDetailsActions}
)
class Header extends Component {
  constructor(props) {
    super(props);

    bind(this, ['cloneShipment']);

    this.state = {
      showCloneModal: false
    };
  }

  cloneShipment(shipment) {
    //re-fetch the full shipment details before proceeding
    this.props.getShipmentDetails(shipment.id).then((response) => {
      let cloneError = false;
      if (response.status === 200) {
        const shipmentToClone = JSON.parse(JSON.stringify(response.details));

        // don't clone order refs
        shipmentToClone.line_items = shipmentToClone.line_items.map((li) => ({...li, purchase_order: null}));
        shipmentToClone.delayed = false;

        this.props.cloneShipment(shipmentToClone);
      } else {
        cloneError = true;
      }
      this.setState({cloneError: cloneError, showCloneModal: false});
    });
  }

  render() {
    const {label, hideLabel} = this.props;

    return (
      <div className="quotingHeader">
        <div className="quotingHeader__renderInline">
          <span className="quotingHeader__reference">
            {!hideLabel && <h1 className="quotingHeader__reference-title">{label}</h1>}
          </span>
          {this.props.showCloneLink && (
            <span className="quotingHeader__cloneButton">
              <Button
                bsStyle="link"
                className="btn-tertiary"
                onClick={() => {
                  this.setState({showCloneModal: true});
                }}
              >
                <i className="icon icon-Files pad-right" />
                Clone from Previous Shipment
              </Button>
            </span>
          )}

          {this.props.hasCustomer && (
            <span className="shipment-quoting-header__customerName">
              <i className="icon icon-Rolodex" />{' '}
              {this.props.selectedShipment &&
                this.props.selectedShipment.customer &&
                this.props.selectedShipment.customer.name}
            </span>
          )}
        </div>
        <InfoModalWrapper
          show={this.state.showCloneModal}
          extraClass={'quotingHeader__cloneModal'}
          bsSize="large"
          onHide={() => {
            this.setState({showCloneModal: false});
          }}
          title="Clone Shipment"
          error={this.state.showError ? this.state.errors : null}
        >
          {' '}
          <RecentShipments onSelectShipment={this.cloneShipment.bind(this)} />
        </InfoModalWrapper>
      </div>
    );
  }
}
export default reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(Header);
