import {useState, useEffect} from 'react';
import ReactTable from 'react-table-6';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import buildPathParams from 'App/utils/buildPathParams';
import TableFiltersWithSavedViews from 'App/components/_tableFiltersWithSavedViews';
import TableReadback from 'App/components/_tableFiltersWithSavedViews/tableReadback';
import {TableFiltersContextProvider, TableFiltersContextConsumer} from 'App/utils/hooks/useTableFilters';
import {checkFilterObj} from 'App/components/_tableFiltersWithSavedViews/utils';
import {useUpdateUserTableRowDefaults} from 'App/utils/hooks/useUserTableHooks';
import './styles.scss';
import {PROPTYPE_ROUTER} from 'App/utils/propTypeConstants';
import {DEFAULT_TABLE_PAGE_SIZE_OPTIONS} from 'App/utils/tableUtils';

/**
 * Shipwell Table
 * @param {*} props
 */
const ShipwellTable = ({
  location = {},
  router,
  filters,
  tableType = '',
  showTableReadback,
  columns,
  bulkSelectColumns,
  ...props
}) => {
  /**
   * Bulk select columns are kept in a separate array, to ensure that bulk column re-rendering,
   * which occurs when rows are selected/deselected from bulk select column, does not re-initialize
   * other columns selected in the table.
   */
  const [bulkSelectTableColumns, setBulkSelectTableColumns] = useState(bulkSelectColumns);
  const [tableColumns, setTableColumns] = useState(columns);
  const [userUpdatedColumns, setUserUpdatedColumns] = useState(false);
  const updateUserTableRowDefaults = useUpdateUserTableRowDefaults(tableType);

  useEffect(() => {
    setBulkSelectTableColumns(bulkSelectColumns);
  }, [bulkSelectColumns]);

  useEffect(() => {
    //only call set table columns in swTable if user has not toggled columns from the default columns
    if (columns.length > 0 && tableColumns.length > 0 && !userUpdatedColumns) {
      setTableColumns(columns);
    }
  }, [columns, tableColumns.length, userUpdatedColumns]);

  const handlePageChange = (page) => {
    const updatedPath = buildPathParams(location, {page: page + 1});
    router.push(updatedPath);
  };

  const handlePageSizeChange = (pageSize) => {
    updateUserTableRowDefaults(pageSize);
    const updatedPath = buildPathParams(location, {pageSize: pageSize, page: 1});
    router.push(updatedPath);
  };

  const handleSortedChange = (column) => {
    const {id, desc} = column[0];
    const ordering = `${desc ? '-' : ''}${id}`;
    const updatedPath = buildPathParams(location, {ordering: ordering});

    router.push(updatedPath);
  };

  return (
    <TableFiltersContextProvider tableType={tableType} router={router} location={location} columns={columns}>
      <TableFiltersContextConsumer>
        {({activeFilters}) => {
          const hasReadback = checkFilterObj(activeFilters);
          return (
            <div className={classNames('sw-table__container', {'sw-table__container-filters': filters})}>
              {filters && (
                <TableFiltersWithSavedViews
                  router={router}
                  location={location}
                  buildPathParams={buildPathParams}
                  filters={filters}
                  tableType={tableType}
                  tableColumns={tableColumns}
                  setTableColumns={setTableColumns}
                  setUserUpdatedColumns={setUserUpdatedColumns}
                />
              )}
              <div className="sw-table__container-bulkActions_wrapper">
                {showTableReadback && <TableReadback filters={filters} tableType={tableType} />}
                <ReactTable
                  manual
                  className={classNames('sw-table', '-highlight', '-striped', {'sw-table__with-readback': hasReadback})}
                  pageSizeOptions={DEFAULT_TABLE_PAGE_SIZE_OPTIONS || props.pageSizeOptions}
                  LoadingComponent={ShipwellLoader}
                  previousText={<i className="icon icon-Left" />}
                  nextText={<i className="icon icon-Right" />}
                  {...props}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  onSortedChange={handleSortedChange}
                  columns={
                    bulkSelectTableColumns?.length > 0 ? [...bulkSelectTableColumns, ...tableColumns] : tableColumns
                  }
                />
              </div>
            </div>
          );
        }}
      </TableFiltersContextConsumer>
    </TableFiltersContextProvider>
  );
};

ShipwellTable.propTypes = {
  data: PropTypes.array,
  location: PropTypes.object,
  router: PROPTYPE_ROUTER,
  filters: PropTypes.array,
  tableType: PropTypes.string,
  loading: PropTypes.bool,
  showTableReadback: PropTypes.bool,
  columns: PropTypes.array,
  bulkSelectColumns: PropTypes.array,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  noDataText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  page: PropTypes.number,
  pages: PropTypes.number,
  pageSize: PropTypes.number,
  showPagination: PropTypes.bool
};

export default ShipwellTable;
