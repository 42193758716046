import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import pluralize from 'pluralize';
import findLastIndex from 'lodash/findLastIndex';
import some from 'lodash/some';
import {renderToString} from 'react-dom/server';
import {SvgIcon, theme} from '@shipwell/shipwell-ui';
import camelCase from 'lodash/camelCase';
import {
  Shipment,
  Stop,
  ShipmentTimelineEvent,
  AlertDashboardSlimShipment,
  ELDDeviceLocation
} from '@shipwell/backend-core-singlerequestparam-sdk';
import {JSX, ReactElement} from 'react';
import invariant from 'tiny-invariant';
import {MarkerType, createAirRouteLines} from './utils/typed';
import {formatDate, formatDateTime, toTitleCase} from 'App/utils/globals';
import {StopEtaPill} from 'App/components/stopEta';
import withFlags from 'App/utils/withFlags';
import {ShipmentModeEnum} from 'App/utils/globalsTyped';
import {AirportLocation} from '@shipwell/locus-sdk';

export const TRIMBLE_MAPS_API_KEY = 'AFC474F551277E4995DF4FBCE3A4C751';

//CI-416: Always use 53' truck to route around national parks, etc
const TRUCK_CONFIG = TrimbleMaps.Common.TruckConfig.FIFTY_THREE;

type AltLatLong = {address?: {lon?: number; long?: number; lat?: number}};
type MapShipment = AlertDashboardSlimShipment | Shipment;

/**
 * We set the API key when this file is imported so that any utils that are used outside of a rendered  Map component can be invoked.
 * This is especially useful for hooks that query map-related info and don't render the map until that data is retrieved and processed.
 */
TrimbleMaps.APIKey = TRIMBLE_MAPS_API_KEY;

/**
 * @function getStopLngLat - returns a TrimbleMaps.LngLat for a given Stop
 * @param {object} stop
 */
export const getStopLngLat = (stop: Stop & AltLatLong) => {
  const long = stop.address?.lon || stop.location?.address.longitude || stop.address?.long;
  const lat = stop.address?.lat || stop.location?.address.latitude;
  invariant(long);
  invariant(lat);
  return new TrimbleMaps.LngLat(long, lat);
};

/**
 * @function getStopLngLatWithOffset - returns a TrimbleMaps.LngLat and adds an offset to handle multiple stops on the exact same address
 * @param {object} stop
 */
const getStopLngLatWithOffset = (stop: Stop & AltLatLong) => {
  // determines the separation distance between multiple duplicate stop points
  const iconDistanceOffset = 0.0007;
  // generates an angle between 0 and 2PI, to ensure that the offset can be in any direction on the map
  const angle = 2 * Math.PI * Math.random();
  // generates coordinates / X & Y offsets to be added to separate out the overlapped icons
  const offsetX = iconDistanceOffset * Math.cos(angle);
  const offsetY = iconDistanceOffset * Math.sin(angle);
  const long = ((stop.address?.lon || stop.location?.address.longitude || stop.address?.long) as number) + offsetX;
  const lat = ((stop.address?.lat || stop.location?.address.latitude) as number) + offsetY;

  if (long && lat) {
    return new TrimbleMaps.LngLat(long, lat);
  }
};

/**
 * @function getTrackingPointLngLat - returns a TrimbleMaps.LngLat for a given Stop
 * @param {object} stop
 */
const getTrackingPointLngLat = (point: Pick<ELDDeviceLocation, 'lat' | 'lon'>) => {
  invariant(point.lon);
  invariant(point.lat);
  return new TrimbleMaps.LngLat(point.lon, point.lat);
};

/**
 * @function getShipmentsLocations - returns an array of current locations for an array of shipments
 * @param {Array} shipments
 */
export const getShipmentsLocations = (shipments: Array<MapShipment>) => {
  const locations = [] as Array<MapShipment & {coords: TrimbleMaps.LngLat}>;
  shipments.forEach((shipment) => {
    let currentLocation = getShipmentCurrentLocation(shipment);
    if (!currentLocation) {
      //location is the most recently completed stop
      //find the latest stop that has confirmed_arrival_at
      currentLocation = getMostRecentlyCompletedStopLocation(shipment);
    }
    if (currentLocation) {
      locations.push({coords: currentLocation, ...shipment});
    }
  });
  return locations;
};

/**
 * @function getShipmentCurrentLocation - returns the current location of a shipment as a TrimbleMaps.LngLat
 * @param {object} shipment
 */
const getShipmentCurrentLocation = (shipment: MapShipment) => {
  if (shipment?.current_address?.longitude && shipment?.current_address?.latitude) {
    //get the current location from each shipment
    return new TrimbleMaps.LngLat(shipment.current_address.longitude, shipment.current_address.latitude);
  }
  return null;
};

/**
 * @function findDuplicateStops - returns an object with duplicate stops - key is the formatted_address, value is the stop indices where it is being duplicated
 * @param {Array} stops
 */
export const findDuplicateStops = (stops: Stop[] = []) => {
  const addressCounts = stops?.reduce<Record<string, number[]>>((acc, stop, index) => {
    const key = stop?.location?.address?.formatted_address as keyof typeof acc;
    if (acc[key]) {
      acc[key].push(index);
    } else {
      acc[key] = [index];
    }
    return acc;
  }, {});
  const stopsListWithDuplicateEntries = Object.entries(addressCounts).filter(([, indices]) => indices?.length > 1);
  const duplicateStops = Object.fromEntries(stopsListWithDuplicateEntries);
  return duplicateStops;
};

/**
 * @function getShipmentStopLocations - returns the location of all a shipment's stops as TrimbleMaps.LngLats
 * @param {object} shipment
 */
export const getShipmentStopLocations = (shipment: Shipment) => {
  const duplicateStops = findDuplicateStops(shipment?.stops);
  return shipment?.stops?.map((stop, stopIndex) => {
    if (some(duplicateStops, (value) => value?.includes(stopIndex))) {
      return {coords: getStopLngLatWithOffset(stop), ...stop};
    }
    return {coords: getStopLngLat(stop), ...stop};
  });
};

/**
 * @function getShipmentTrackingPointLocations - returns the location of all a shipment's tracking points as TrimbleMaps.LngLats
 * @param {object} shipment
 */
export const getShipmentTrackingPointLocations = (
  trackingPoints: Array<Omit<ELDDeviceLocation, 'update_time'> & {update_time?: string}> = []
) => {
  return trackingPoints.map((point) => ({coords: getTrackingPointLngLat(point), ...point}));
};

/**
 * @function getMostRecentlyCompletedStopIndex - returns the most recently completed stop index of a shipment
 * @param {object} shipment
 */
const getMostRecentlyCompletedStopIndex = (shipment: MapShipment) => {
  const lastIndex = findLastIndex(shipment?.stops, (stop) => Boolean(stop.confirmed_arrival_at));
  return lastIndex;
};

/**
 * @function getMostRecentlyCompletedStopLocation - returns the location of the most recently completed stop as a LngLat
 * @param {object} shipment
 */
const getMostRecentlyCompletedStopLocation = (shipment: MapShipment) => {
  if (shipment.stops && getMostRecentlyCompletedStopIndex(shipment) > -1) {
    return getStopLngLat(shipment.stops[getMostRecentlyCompletedStopIndex(shipment)]);
  }
  return null;
};

export const getShipmentRoute = (
  shipment: Omit<Shipment, 'preferred_currency'>,
  _showTrimbleCompletedRoute: boolean,
  showTrimbleFutureRoute = true,
  elevationLimit?: number
) => {
  if (shipment.mode?.code === ShipmentModeEnum.AIR) {
    return getShipmentAirRoute(shipment);
  }
  return getShipmentLandRoute(shipment, _showTrimbleCompletedRoute, showTrimbleFutureRoute, elevationLimit);
};

/**
 * @function getShipmentLandRoute - returns 3 TrimbleMaps.Routes which represent the entire shipment, the completed route so far, and the future route
 * @param {object} shipment
 * @param {boolean} _showTrimbleCompletedRoute - true when we don't have shipment locations to show and want the trimble estimated route
 * @param {boolean} showTrimbleFutureRoute - true when we want to show the truck's route line between stops
 * @param {number} elevationLimit - optional elevation limit param
 */
export const getShipmentLandRoute = (
  shipment: Omit<Shipment, 'preferred_currency'>,
  //unused while we test always showing the completed route
  _showTrimbleCompletedRoute: boolean,
  showTrimbleFutureRoute = true,
  elevationLimit?: number
) => {
  invariant(shipment.stops);
  const completeStopsArray = shipment.stops?.slice(0, getMostRecentlyCompletedStopIndex(shipment) + 1).map((stop) => {
    return getStopLngLat(stop);
  });

  const incompleteStopsArray =
    getMostRecentlyCompletedStopIndex(shipment) === shipment.stops?.length && shipment.stops?.length - 1 // all stops complete
      ? []
      : getMostRecentlyCompletedStopIndex(shipment) > 0
      ? shipment.stops?.slice(getMostRecentlyCompletedStopIndex(shipment)).map((stop) => {
          return getStopLngLat(stop);
        })
      : shipment.stops?.map((stop) => {
          return getStopLngLat(stop);
        });
  const completedRoute = new TrimbleMaps.Route({
    routeId: `completed_${shipment.id}`,
    routeColor: theme.colors.swLabel,
    stops: completeStopsArray,
    truckConfig: TRUCK_CONFIG,
    showStops: false,
    elevLimit: elevationLimit,
    frameRoute: false
  });
  const futureRoute =
    //CI-363: do not show blue line if mode is rail
    shipment.mode?.code !== ShipmentModeEnum.RAIL && showTrimbleFutureRoute
      ? new TrimbleMaps.Route({
          routeId: `future_${shipment.id}`,
          routeColor: theme.colors.swActive,
          stops: incompleteStopsArray,
          truckConfig: TRUCK_CONFIG,
          elevLimit: elevationLimit,
          showStops: false,
          frameRoute: false
        })
      : null;
  const entireRoute = new TrimbleMaps.Route({
    routeId: `entire_${shipment.id}`,
    routeColor: theme.colors.white,
    stops: completeStopsArray?.concat(incompleteStopsArray),
    truckConfig: TRUCK_CONFIG,
    elevLimit: elevationLimit,
    showStops: false,
    frameRoute: false
  });

  return {completedRoute, futureRoute, entireRoute};
};

export const getShipmentAirRoute = (shipment: Omit<Shipment, 'preferred_currency'>) => {
  invariant(shipment.stops);
  const completeStopsArray = shipment.stops?.slice(0, getMostRecentlyCompletedStopIndex(shipment) + 1).map((stop) => {
    return getStopLngLat(stop);
  });

  const incompleteStopsArray =
    getMostRecentlyCompletedStopIndex(shipment) === shipment.stops?.length && shipment.stops?.length - 1 // all stops complete
      ? []
      : getMostRecentlyCompletedStopIndex(shipment) > 0
      ? shipment.stops?.slice(getMostRecentlyCompletedStopIndex(shipment)).map((stop) => {
          return getStopLngLat(stop);
        })
      : shipment.stops?.map((stop) => {
          return getStopLngLat(stop);
        });

  const completedRoute = createAirRouteLines(completeStopsArray);
  const futureRoute = createAirRouteLines(incompleteStopsArray);
  const entireRoute = createAirRouteLines(completeStopsArray?.concat(incompleteStopsArray));

  return {completedRoute, futureRoute, entireRoute};
};

/**
 * @function createHTMLElementFromComponent - returns an HTMLNode of the component
 * @param {React.Component} component
 */
export const createHTMLElementFromComponent = (component: ReactElement) => {
  const view = renderToString(component);
  const div = document.createElement('div');
  div.innerHTML = view.trim();
  return div.firstChild;
};

interface RailDescription {
  etaAtDestination?: string;
  railCarStatusUpdated?: string;
  railroad?: string;
  state?: string;
  stationName?: string;
}

const parseRailDescription = (description: string) => {
  const result = description
    .split('|')
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore This regular expression flag is only available when targeting 'es2018' or later.ts(1501)
    .map((splitDescription) => splitDescription.split(/:(.*)/s).map((entries) => entries.trim()))
    .map(([key, value]) => [camelCase(key), value]);
  return Object.fromEntries(result) as RailDescription;
};

/**
 * @component ShipmentTooltip - returns default tooltip content for a shipment
 * @param {object} shipment
 */
export const ShipmentTooltip = ({
  shipment,
  alertTypes,
  timelineEvents,
  latestBreadcrumb,
  locusApiLocation
}: {
  shipment: MapShipment;
  alertTypes?: string[];
  timelineEvents?: ShipmentTimelineEvent[];
  latestBreadcrumb?:
    | (ELDDeviceLocation & {
        coords?: TrimbleMaps.LngLat | undefined;
      })
    | null;
  locusApiLocation?: AirportLocation | null;
}) => {
  // TODO: move this to a more general place
  const formatAirportLocation = (airportLocation?: AirportLocation | null) => {
    if (!airportLocation) {
      return '--';
    }
    return airportLocation.region
      ? `${airportLocation.name}, ${airportLocation.region} (${airportLocation.code})`
      : `${airportLocation.name} (${airportLocation.code})`;
  };

  const isRail = 'mode' in shipment && shipment?.mode?.code === ShipmentModeEnum.RAIL;
  const mostCurrentTimelineEvent = timelineEvents?.[0];

  const parsedRailDescription =
    isRail && mostCurrentTimelineEvent?.description ? parseRailDescription(mostCurrentTimelineEvent.description) : null;

  let formattedRailLocation;

  if (parsedRailDescription?.stationName && parsedRailDescription?.state) {
    formattedRailLocation = `${parsedRailDescription.stationName}, ${parsedRailDescription.state}`;
  } else if (parsedRailDescription?.stationName) {
    formattedRailLocation = parsedRailDescription.stationName;
  } else if (parsedRailDescription?.state) {
    formattedRailLocation = parsedRailDescription.state;
  }

  const eta = parsedRailDescription?.etaAtDestination
    ? parsedRailDescription.etaAtDestination
    : shipment.next_planned_stop_id
    ? shipment.stops?.find((e) => e.id === shipment.next_planned_stop_id)?.display_eta_window
    : '--';

  let currentLocation;
  if ('mode' in shipment && shipment?.mode?.code === ShipmentModeEnum.AIR && true) {
    currentLocation = formatAirportLocation(locusApiLocation);
  } else {
    currentLocation = shipment.current_address?.city
      ? `${shipment.current_address?.city}, ${shipment.current_address?.state_province || ''}`
      : formattedRailLocation || '--';
  }

  const nextStop = shipment.stops?.find((e) => !e.confirmed_arrival_at && !e.confirmed_departure_at);
  invariant(shipment.reference_id);
  invariant(shipment.stops);

  return (
    <div className="trimbleMap__markerContent">
      <div className="trimbleMap__markerContent-bold">{`SHIPMENT ${shipment.reference_id}`}</div>
      <div>{`${shipment.stops?.map((stop) => getAbbreviatedStopString(stop)).join(' > ')}`}</div>
      <div>{getShipmentStopDates(shipment.stops)}</div>
      {latestBreadcrumb && (
        <div>
          <span className="trimbleMap__markerContent-bold">Description: </span>
          {latestBreadcrumb?.event_description}
        </div>
      )}
      <div>
        <span className="trimbleMap__markerContent-bold">Current Location: </span>
        {currentLocation}
      </div>
      {!isRail ? (
        <div>
          <span className="trimbleMap__markerContent-bold">Next Stop: </span>
          {nextStop ? getAbbreviatedStopString(nextStop) : '--'}
        </div>
      ) : null}
      <div>
        <span className="trimbleMap__markerContent-bold">Est. Arrival: </span>
        {eta}
      </div>
      <div>
        <span className="trimbleMap__markerContent-bold">Last Update: </span>
        {formatDateTime(shipment.current_address?.updated_at)}
      </div>
      {alertTypes && alertTypes?.length > 0 && (
        <div>
          <span className="trimbleMap__markerContent-bold">{pluralize('Alert', alertTypes.length)}: </span>
          {alertTypes.join(' | ')}
        </div>
      )}
    </div>
  );
};

/**
 * @function getShipmentStopDates - returns string in format <FirstStop Date> - <LastStop Date>
 * @param {Array} stops
 */
export const getShipmentStopDates = (stops: Stop[]) => {
  let dateString = '';
  if (stops[0]?.planned_date) {
    dateString += formatDate(stops[0]?.planned_date);
  }
  if (stops[stops.length - 1]?.planned_date) {
    dateString += ` - ${formatDate(stops[stops.length - 1].planned_date)}`;
  }
  return dateString.length > 0 ? dateString : '--';
};

/**
 * @function getAbbreviatedStopString - returns string in format <City>, <State>
 * @param {object} stop
 */
export const getAbbreviatedStopString = (stop: Stop) => {
  return stop?.location?.address?.city && stop?.location?.address?.state_province
    ? `${stop?.location?.address?.city}, ${stop?.location?.address?.state_province}`
    : stop?.location?.address?.city
    ? stop?.location?.address?.city
    : stop?.location?.address?.state_province || '--';
};

/**
 * @function getPickupDelivery - returns string specifying whether a stop is pickup or delivery
 * @param {object} stop
 */
export const getPickupDelivery = (stop: Stop, inProgress = false) => {
  let string = '';
  if (inProgress) {
    string += 'Truck at ';
  }
  if (stop.is_pickup) {
    string += 'Pickup';
    if (stop.is_dropoff) {
      string += '/';
    }
  }
  if (stop.is_dropoff) {
    string += 'Delivery';
  }
  return string;
};

export const getShipmentPickupDeliveryCounts = (stops: Stop[]) => {
  return `${stops.filter((stop) => stop.is_pickup).length} PU, ${stops.filter((stop) => stop.is_dropoff).length} DEL`;
};

/**
 * @component StopTooltip - returns default tooltip content for a stop
 * @param {object} stop
 */
const StopTooltipBase = ({
  stop,
  index,
  tripManagementPredictedEta,
  hasLTL
}: {
  stop: Stop;
  index: number;
  tripManagementPredictedEta: unknown;
  hasLTL: boolean;
}) => {
  const stopInProgress = Boolean(stop.confirmed_arrival_at && !stop.confirmed_departure_at);
  return (
    <div className="trimbleMap__markerContent">
      <div className="trimbleMap__markerContent-bold trimbleMap__markerContent-title">
        {index < 20 && <SvgIcon name={`Num${index + 1}Filled`} color="$sw-text-reverse" className="mr-1" />}
        {`${getPickupDelivery(stop, stopInProgress)}${index < 20 ? '' : ` ${index + 1}`}`}
      </div>
      <div>
        <span className="trimbleMap__markerContent-bold">Company Name: </span>
        <span>{stop.location?.company_name || '--'}</span>
      </div>
      <div>
        <span className="trimbleMap__markerContent-bold">Address: </span>
        <span>{stop.location?.address?.formatted_address || '--'}</span>
      </div>
      <div>
        <span className="trimbleMap__markerContent-bold">Dock/Receiving Hours: </span>
        <span className="flex items-center">
          <span className="mr-1">{stop.display_planned_window || '--'}</span>
          {tripManagementPredictedEta && !hasLTL ? <StopEtaPill stop={stop} size="xs" duration="hours" /> : ''}
        </span>
      </div>
      {(stop.confirmed_arrival_at || stop.confirmed_departure_at) && (
        <>
          <div>
            <span className="trimbleMap__markerContent-bold">Check In: </span>
            <span>{stop.confirmed_arrival_at ? formatDateTime(stop.confirmed_arrival_at) : '--'}</span>
          </div>
          <div>
            <span className="trimbleMap__markerContent-bold">Check Out: </span>
            <span>{stop.confirmed_departure_at ? formatDateTime(stop.confirmed_departure_at) : '--'}</span>
          </div>
        </>
      )}
      {stop.alerts && stop.alerts.length > 0 ? (
        <div>
          {stop.alerts.map((alert) => {
            return (
              <div className="trimbleMap__markerContent-flag" key={alert.id}>
                <SvgIcon name="FlagFilled" color="$sw-error" />
                <span>
                  {toTitleCase(alert.alert_type)}: {toTitleCase(alert.reason_code)}
                </span>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export const StopTooltip = withFlags('tripManagementPredictedEta')(StopTooltipBase);

export const TrackingPointTooltip = ({
  point
}: {
  point: Partial<Pick<ELDDeviceLocation, 'update_time'>> & {
    tracking_source?: string;
  };
}) => {
  return (
    <div>
      <div className="trimbleMap__markerContent">
        <span className="trimbleMap__markerContent-bold">Tracking Source: </span>
        <span>{trackingSourceMap[point.tracking_source as keyof typeof trackingSourceMap]}</span>
      </div>
      <div className="trimbleMap__markerContent">
        <span>{formatDateTime(point.update_time)}</span>
      </div>
    </div>
  );
};

const trackingSourceMap = {
  eld: 'ELD',
  mobile_app: 'Mobile App',
  unknown: 'Unknown'
};

export const getZoomFilterDistance = (zoom: number) => {
  let filterDistance = 1; //default

  if (zoom < 3) {
    filterDistance = 120;
  } else if (zoom < 5) {
    filterDistance = 60;
  } else if (zoom < 6) {
    filterDistance = 50;
  } else if (zoom < 8) {
    filterDistance = 40;
  } else if (zoom < 10) {
    filterDistance = 30;
  } else if (zoom < 12) {
    filterDistance = 20;
  } else if (zoom < 14) {
    filterDistance = 10;
  } else if (zoom < 15) {
    filterDistance = 8;
  } else if (zoom < 16) {
    filterDistance = 5;
  } else if (zoom < 17) {
    filterDistance = 3;
  } else if (zoom < 18) {
    filterDistance = 1;
  } else if (zoom < 19) {
    filterDistance = 0.8;
  } else if (zoom < 22) {
    filterDistance = 0.5;
  }
  return filterDistance;
};

const getStopMarkerLocationType = (stop: Stop, index: number) => {
  //use oil well svg if location type is oil well (these values are not available in be-core-sdk as enums)
  if (stop.location?.location_type?.id === 18) {
    return MarkerType.OilWell;
  }
  return `${MarkerType.Stop}_${index + 1}`;
};

/**
 * @function getShipmentMarkersForMap - put together all the relevant markers for displaying a single shipment on the map
 */
export const getShipmentMarkersForMap = ({
  shipment,
  trackingPoints,
  includeShipmentTooltips = true,
  timelineEvents = [],
  locusApiLocation
}: {
  shipment: Omit<Shipment, 'preferred_currency'>;
  trackingPoints: (ELDDeviceLocation & {
    coords?: TrimbleMaps.LngLat;
  })[];
  includeShipmentTooltips?: boolean;
  timelineEvents?: ShipmentTimelineEvent[];
  locusApiLocation?: AirportLocation | null;
}) => {
  const isAirShipment = shipment?.mode?.code === 'AIR';

  //if shipment is drayage all tracking points prior to stop 1 should use the boat icon
  const locationMarkerType =
    'mode' in shipment && shipment?.mode?.code === ShipmentModeEnum.RAIL
      ? MarkerType.RailMarker
      : 'mode' in shipment && shipment?.mode?.code === ShipmentModeEnum.AIR
      ? MarkerType.AirMarker
      : shipment?.mode?.code === ShipmentModeEnum.DRAYAGE &&
        !shipment.stops?.[0]?.confirmed_arrival_at &&
        !shipment.stops?.[0]?.confirmed_departure_at
      ? MarkerType.BoatMarker
      : MarkerType.TruckMarker;

  // if air mode, provide the latest update description, otherwise, pass only the other props
  const latestBreadcrumb = isAirShipment
    ? trackingPoints.sort((a, b) => new Date(b.update_time).getTime() - new Date(a.update_time).getTime())[0] || null
    : null;

  const shipmentCurrentLocation = getShipmentsLocations([shipment])?.map((loc) =>
    formatLocationData(
      loc,
      locationMarkerType,
      includeShipmentTooltips ? (
        <ShipmentTooltip
          shipment={loc}
          alertTypes={[]}
          timelineEvents={timelineEvents}
          latestBreadcrumb={latestBreadcrumb}
          locusApiLocation={locusApiLocation}
        />
      ) : null
    )
  );
  const shipmentStopLocations = getShipmentStopLocations(shipment)?.map((loc, index) => {
    return formatLocationData(
      loc,
      index < 20 ? getStopMarkerLocationType(loc, index) : MarkerType.Stop,
      includeShipmentTooltips ? <StopTooltip stop={loc} index={index} /> : null
    );
  });
  const trackingPointLocations = getShipmentTrackingPointLocations(trackingPoints)?.map((loc, index, array) =>
    formatLocationData(
      loc,
      index === array.length - 1 ? MarkerType.DispatchPoint : MarkerType.TrackingPoint,
      includeShipmentTooltips ? <TrackingPointTooltip point={loc} /> : null
    )
  );
  if (shipmentCurrentLocation && shipmentStopLocations && trackingPointLocations) {
    return [...shipmentStopLocations, ...trackingPointLocations, ...shipmentCurrentLocation];
  }
};

/**
 * @function formatLocationData - util to format data for display on the map - coords, marker type, and tooltip
 */
const formatLocationData = (
  location: MapShipment & {
    coords?: TrimbleMaps.LngLat;
  },
  type: string,
  details: JSX.Element | null = null,
  onClick?: () => void
) => ({...location, type, details, onClick});

export const isStyleFunction = (
  arg?: string | TrimbleMaps.Expression | TrimbleMaps.StyleFunction | number
): arg is TrimbleMaps.StyleFunction => {
  return !!arg && typeof arg !== 'string' && typeof arg !== 'number' && 'stops' in arg;
};
