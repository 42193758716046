import {
  Configuration,
  CreateRateTable,
  DispatchApi,
  RateTablesApi,
  RatingApi,
  CarrierTariffEffectiveApi,
  FreightAllKindsMappingApi,
  CreateFreightAllKindsMapping,
  ProvidersApi,
  ProviderCode,
  TransportationMode,
  UpdateRateTable,
  CreateCapacityProviderAccountRequestPayloadSchema,
  CarrierTariffEffectiveApiGetAllCarrierTariffEffectiveTariffsGetRequest,
  RateTablesApiGetAllRateTablesRatesRateTablesGetRequest,
  FreightAllKindsMappingApiGetAllFakMappingsRatesFakMappingsGetRequest,
  RateTablesApiGetRateTableRowsRatesRateTablesRateTableIdRowsGetRequest,
  CarrierTariffEffectiveApiUpdateCarrierTariffEffectiveTariffsTariffEffectiveIdPutRequest,
  CarrierTariffEffectiveApiCreateCarrierTariffEffectiveTariffsPostRequest,
  CreateCapacityProviderSchemaAccountsInner,
  CreateSmc3AccountSchema,
  RatingApiCreateRateRequestRequest,
  RatingApiGetRateRequestByRequestIdRequest,
  RatingApiListRateRequestsRequest
} from '@shipwell/genesis-sdk';
import {RatingApiCreateFtlRateRequestRatesFtlPostRequest} from '@shipwell/genesis-sdk/api';
import {getAccessToken} from 'App/api/utils';

const basePath = process.env.SHIPWELL_GENESIS_API_BASE_PATH;

const rateTablesApi = new RateTablesApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

const ratingApi = new RatingApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

const dispatchApi = new DispatchApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

const carrierTariffEffectiveApi = new CarrierTariffEffectiveApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

const freightAllKindsMappingApi = new FreightAllKindsMappingApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

const providersApi = new ProvidersApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

const ratingClient: RatingApi = new RatingApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

export const createServiceLevel = (createRateTable: CreateRateTable) => {
  return rateTablesApi.createRateTableRatesRateTablesPost({
    createRateTable
  });
};

export const getRateTableRows = ({
  rateTableId,
  page,
  limit
}: RateTablesApiGetRateTableRowsRatesRateTablesRateTableIdRowsGetRequest) => {
  return rateTablesApi.getRateTableRowsRatesRateTablesRateTableIdRowsGet({
    rateTableId,
    page,
    limit
  });
};

export const getFAKTableData = ({
  page,
  limit,
  originalNmfcCode,
  mappedNmfcCode,
  carrierReferenceQualifier,
  carrierReferenceValue
}: FreightAllKindsMappingApiGetAllFakMappingsRatesFakMappingsGetRequest) => {
  return freightAllKindsMappingApi.getAllFakMappingsRatesFakMappingsGet({
    page,
    limit,
    originalNmfcCode,
    mappedNmfcCode,
    carrierReferenceQualifier,
    carrierReferenceValue
  });
};

export const upsertFAKTableData = (fakData: Array<CreateFreightAllKindsMapping>) => {
  return freightAllKindsMappingApi.bulkUpsertRatesFakMappingsBulkPost({
    createFreightAllKindsMapping: fakData
  });
};

export const getRateTables = ({
  page,
  limit,
  serviceLevel,
  carrierReferenceQualifier,
  carrierReferenceValue
}: RateTablesApiGetAllRateTablesRatesRateTablesGetRequest) => {
  return rateTablesApi.getAllRateTablesRatesRateTablesGet({
    page,
    limit,
    serviceLevel,
    carrierReferenceQualifier,
    carrierReferenceValue
  });
};

export const deleteRateTable = (rateTableId: string) => {
  return rateTablesApi.deleteRateTableRatesRateTablesRateTableIdDelete({
    rateTableId
  });
};

export const updateRateTable = (rateTableId: string, updateRateTable: UpdateRateTable) => {
  return rateTablesApi.updateRateTableRatesRateTablesRateTableIdPut({
    rateTableId,
    updateRateTable
  });
};

export const getRatesByRequestId = (requestId: string) => {
  return ratingApi.getRateRequestDispatchesRateRequestsRateRequestIdGet({
    rateRequestId: requestId,
    include: ['dispatches', 'failures']
  });
};

export const getDispatchByDispatchId = (dispatchId: string) => {
  return dispatchApi.getFtlDispatchDispatchFtlDispatchIdGet({
    dispatchId
  });
};

export const getAllCarrierTariffs = ({
  page,
  limit,
  tariffName,
  tariffType,
  carrierReferenceQualifier,
  carrierReferenceValue
}: CarrierTariffEffectiveApiGetAllCarrierTariffEffectiveTariffsGetRequest) => {
  return carrierTariffEffectiveApi.getAllCarrierTariffEffectiveTariffsGet({
    page,
    limit,
    tariffName,
    tariffType,
    carrierReferenceQualifier,
    carrierReferenceValue
  });
};

export const createCarrierTariff = ({
  createCarrierTariffEffective
}: CarrierTariffEffectiveApiCreateCarrierTariffEffectiveTariffsPostRequest) => {
  return carrierTariffEffectiveApi.createCarrierTariffEffectiveTariffsPost({
    createCarrierTariffEffective
  });
};

export const updateCarrierTariff = ({
  createCarrierTariffEffective,
  tariffEffectiveId
}: CarrierTariffEffectiveApiUpdateCarrierTariffEffectiveTariffsTariffEffectiveIdPutRequest) => {
  return carrierTariffEffectiveApi.updateCarrierTariffEffectiveTariffsTariffEffectiveIdPut({
    createCarrierTariffEffective,
    tariffEffectiveId
  });
};

// Providers
export const connectSmc3 = (values: CreateSmc3AccountSchema['account']) => {
  return providersApi.createProviderProvidersPost({
    createCapacityProviderSchema: {
      provider_code: ProviderCode.Smc3,
      modes: [TransportationMode.Ltl],
      accounts: [
        {
          provider_code: ProviderCode.Smc3,
          account: {
            username: values.username,
            password: values.password,
            license_key: values.license_key
          }
        }
      ]
    }
  });
};

export const getSmc3Account = () => {
  return providersApi.getProviderProvidersProviderCodeGet({
    providerCode: ProviderCode.Smc3
  });
};

export const disconnectSmc3 = () => {
  return providersApi.deleteProviderProvidersProviderCodeDelete({
    providerCode: ProviderCode.Smc3
  });
};

export const createCapacityProviderWithAccount = async (values: CreateCapacityProviderSchemaAccountsInner) =>
  (
    await providersApi.createProviderProvidersPost({
      createCapacityProviderSchema: {
        provider_code: (
          values as {
            provider_code: ProviderCode;
          }
        ).provider_code,
        accounts: [values]
      }
    })
  ).data;

export const createProviderAccount = async ({
  providerCode,
  createCapacityProviderAccountRequestPayloadSchema
}: {
  providerCode: ProviderCode;
  createCapacityProviderAccountRequestPayloadSchema: CreateCapacityProviderAccountRequestPayloadSchema;
}) =>
  (
    await providersApi.addProviderAccountProvidersProviderCodePost({
      providerCode,
      createCapacityProviderAccountRequestPayloadSchema
    })
  ).data;

export const getProviderCapacityProvider = async (providerCode: ProviderCode) =>
  (await providersApi.getProviderProvidersProviderCodeGet({providerCode})).data;

export const deleteProviderAccount = async ({providerCode, id}: {providerCode: ProviderCode; id: string}) =>
  (
    await providersApi.deleteProviderAccountProvidersProviderCodeIdDelete({
      providerCode,
      id
    })
  ).data;

export const deleteCapacityProvider = async (providerCode: ProviderCode) =>
  (await providersApi.deleteProviderProvidersProviderCodeDelete({providerCode})).data;

export const updateCapacityProviderAccount = async ({
  providerCode,
  accountId,
  createCapacityProviderAccountRequestPayloadSchema
}: {
  providerCode: ProviderCode;
  accountId: string;
  createCapacityProviderAccountRequestPayloadSchema: CreateCapacityProviderAccountRequestPayloadSchema;
}) =>
  (
    await providersApi.updateProviderAccountProvidersProviderCodeAccountIdPut({
      providerCode,
      accountId,
      createCapacityProviderAccountRequestPayloadSchema
    })
  ).data;

export const createRateRequest = async ({createParcelRateRequest}: RatingApiCreateRateRequestRequest) =>
  (
    await ratingApi.createRateRequest({
      createParcelRateRequest
    })
  ).data;

export const getRateRequestDetails = async ({rateRequestId}: RatingApiGetRateRequestByRequestIdRequest) =>
  (
    await ratingApi.getRateRequestByRequestId({
      rateRequestId
    })
  ).data;
export const createFtlRates = (createFtlRateRequest: RatingApiCreateFtlRateRequestRatesFtlPostRequest) => {
  return ratingClient.createFtlRateRequestRatesFtlPost(createFtlRateRequest);
};

export const getRates = (rateRequestId: string) => {
  return ratingClient.getRatesRatesRateRequestIdGet({rateRequestId});
};

export const listRatesByRequestId = async (rateRequestId: string) =>
  (await ratingClient.listRatesByRequestId({rateRequestId}))?.data;

export const listRateRequests = async (requestParams: RatingApiListRateRequestsRequest) =>
  (await ratingClient.listRateRequests(requestParams)).data;
