import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {FieldArray, Field, useFormikContext} from 'formik';
import uniqBy from 'lodash/uniqBy';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {DeprecatedButton, Card, CollapsibleCardContent, FormikSelect, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import QuoteAndTenderFields from './quoteAndTenderFields';
import TenderFields from 'App/formComponents/formSections/routingGuideActionsFields/tenderFields';
import EmailFields from 'App/formComponents/formSections/routingGuideActionsFields/emailFields';
import LoadboardFields from 'App/formComponents/formSections/routingGuideActionsFields/loadboardFields';
import BroadcastFields from 'App/formComponents/formSections/routingGuideActionsFields/broadcastFields';
import RateTypeField from 'App/formComponents/formSections/rateTypeField';
import ExpirationField from 'App/formComponents/formSections/expirationField';
import SpecialInstructionsField from 'App/formComponents/formSections/specialInstructionsField';
import StepTimerField from 'App/formComponents/formSections/stepTimerField';
import {formatCurrency} from 'App/utils/internationalConstants';
import {ShipmentModeEnum} from 'App/utils/globalsTyped';
import withFlags from 'App/utils/withFlags';

const defaultTypeValues = {
  SEND_EMAIL: {type: {label: 'Email', value: 'SEND_EMAIL'}, recipients: [], message: '', subject: ''},
  TENDER: {
    type: {label: 'Tender', value: 'TENDER'},
    tender_to_company: '',
    involved_tender_to_company_users: [],
    rate: '',
    rate_type: 'FLAT_RATE',
    expires_after_seconds: '',
    mode: '',
    equipment_type: '',
    contract: ''
  },
  POST_TO_LOADBOARD: {
    type: {value: 'POST_TO_LOADBOARD', label: 'Add to Load Board'},
    step_timer: {label: '0 Minutes', value: 0}
  },
  CREATE_SPOT_NEGOTIATIONS: {
    type: {value: 'CREATE_SPOT_NEGOTIATIONS', label: 'Broadcast'},
    step_timer: {label: '0 Minutes', value: 0},
    carriers: []
  },
  QUOTE_AND_TENDER_LTL: {
    type: {label: 'Quote & Tender', value: 'QUOTE_AND_TENDER_LTL'}
  }
};

const RoutingGuideActionsDefaultFields = () => (
  <div className="field-grid">
    <div className="grid-item-12">
      <RateTypeField name="routingGuideActions.rate_type" label="Tender Rate Type" />
    </div>
    <div className="grid-item-12">
      <ExpirationField name="routingGuideActions.expires_after_seconds" label="Tender Expiration" />
    </div>
    <div className="grid-item-12">
      <SpecialInstructionsField name="routingGuideActions.info_message" />
    </div>
    <div className="grid-item-12">
      <StepTimerField name="routingGuideActions.step_timer" />
    </div>
  </div>
);
export {RoutingGuideActionsDefaultFields};

const RoutingGuideActionsFields = (props) => {
  const {modes, equipmentTypes, defaultFormValues, selectedPolicy, errors, company, ciLtlAutoQuoteTender} = props;
  const {values, setFieldValue} = useFormikContext();

  const showQuoteAndTender =
    ciLtlAutoQuoteTender &&
    values.modes?.length === 1 &&
    values.modes?.every((mode) => mode?.code === ShipmentModeEnum.LTL);

  const generateCardTitle = (index, values, dragHandleProps) => {
    const actionType =
      values.actions && values.actions[index] && values.actions[index].type ? values.actions[index].type.label : null;
    const tenderToCompany =
      values.actions &&
      values.actions[index] &&
      values.actions[index].involved_tender_to_company_users &&
      values.actions[index].involved_tender_to_company_users.length > 0
        ? values.actions[index].involved_tender_to_company_users[0].carrierName
        : null;
    const subject = values.actions && values.actions[index] && values.actions[index].subject;
    const bookNowRate =
      values.actions && values.actions[index] && values.actions[index].buy_it_now_amount
        ? formatCurrency(values.actions[index].buy_it_now_amount, values.actions[index].buy_it_now_amount_currency)
        : null;
    const numCarriers =
      values.actions && values.actions[index] && values.actions[index].carriers
        ? uniqBy(values.actions[index].carriers, 'carrier').length
        : null;
    return (
      <>
        <span className="routingGuides__form-action-header gap-2">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <div {...dragHandleProps}>
            <SvgIcon height={18} width={18} name="Drag" />
          </div>
          {`Step ${index + 1} ${actionType ? ` - ${actionType}` : ``} ${
            tenderToCompany ? ` - ${tenderToCompany}` : ``
          } ${subject ? ` - ${subject.replace(/(.{25})..+/, '$1…')}` : ''} ${
            numCarriers ? ` - ${numCarriers} Carrier${numCarriers > 1 ? 's' : ''}` : ''
          } ${bookNowRate ? ` - ${bookNowRate}` : ''}`}
        </span>
        {values?.actions?.[index]?.error?.detail ? (
          <Tooltip
            tooltipContent={values.actions[index].error.detail}
            wrapperClassname="routingGuides__form-action-errorIcon"
            trigger="hover"
            placement="top"
          >
            <SvgIcon name="ErrorOutlined" color="$sw-error" />
          </Tooltip>
        ) : null}
      </>
    );
  };

  const getTooltipContent = (type) => {
    if (type === 'POST_TO_LOADBOARD') {
      return (
        <div>
          <p>
            <b>Add to Load Board</b>
          </p>
          <p>
            This will put the shipment on your loadboard for carriers to bid on or book now. This will be removed when a
            carrier is matched.
          </p>
        </div>
      );
    }
    if (type === 'TENDER') {
      return (
        <div>
          <p>
            <b>Tender</b>
          </p>
          <p>This will tender the shipment to the specified carrier at the price you enter.</p>
        </div>
      );
    }
    if (type === 'SEND_EMAIL') {
      return (
        <div>
          <p>
            <b>Email</b>
          </p>
          <p>This will send an email.</p>
        </div>
      );
    }
    if (type === 'CREATE_SPOT_NEGOTIATIONS') {
      return (
        <div>
          <p>
            <b>Broadcast</b>
          </p>
          <p>Broadcast allows you to request bids from one or more specific carriers at a time.</p>
        </div>
      );
    }
    if (type === 'QUOTE_AND_TENDER_LTL') {
      return (
        <div>
          <p>
            <b>Quote & Tender</b>
          </p>
          <p>Automatically quote and tender to the lowest priced carrier.</p>
        </div>
      );
    }
    return null;
  };

  return (
    <FieldArray
      name="actions"
      render={(arrayHelpers) => (
        <DragDropContext onDragEnd={(result) => arrayHelpers.move(result?.source?.index, result?.destination?.index)}>
          <div className="routingGuides__form">
            <Card title="Actions" className="routingGuides__form-card routingGuides__form-parentCard">
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    className={`draggable-item ${snapshot.isDraggingOver ? 'dragover' : ''}`}
                  >
                    {values.actions?.map((action, index) => {
                      return (
                        <Draggable key={index} index={index} draggableId={index}>
                          {(provided, snapshot) => (
                            <div
                              id={'routingGuides__form-action-' + index}
                              {...provided.draggableProps}
                              // {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              className="draggable-option routingGuides__form-action"
                              style={{
                                background: snapshot.isDragging ? '#ddd' : '#fff',
                                ...provided.draggableProps.style
                              }}
                            >
                              <Card
                                title={generateCardTitle(index, values, provided.dragHandleProps)}
                                isCollapsible
                                className={'routingGuides__form-card'}
                                actions={
                                  <DeprecatedButton
                                    variant="icon"
                                    icon={<SvgIcon name="TrashOutlined" />}
                                    onClick={() => arrayHelpers.remove(index)}
                                  />
                                }
                              >
                                <CollapsibleCardContent>
                                  <div className="routingGuides__form-action-expanded">
                                    <div className="field-grid grid-1-12">
                                      <div className="grid-item-1-11">
                                        <Field
                                          label="Action"
                                          required
                                          clearable={false}
                                          name={`actions[${index}].type`}
                                          options={[
                                            {value: 'TENDER', label: 'Tender'},
                                            ...(showQuoteAndTender
                                              ? [{value: 'QUOTE_AND_TENDER_LTL', label: 'Quote & Tender'}]
                                              : []),
                                            {value: 'POST_TO_LOADBOARD', label: 'Add to Load Board'},
                                            {value: 'CREATE_SPOT_NEGOTIATIONS', label: 'Broadcast'},
                                            {value: 'SEND_EMAIL', label: 'Email'}
                                          ]}
                                          component={FormikSelect}
                                          onChange={(val) => {
                                            setFieldValue(`actions[${index}]`, {
                                              ...defaultTypeValues[val.value],
                                              ...defaultFormValues[val.value]
                                            });
                                          }}
                                        />
                                      </div>
                                      <div className="grid-item-12-12 routingGuides__form-action-tooltips-container">
                                        <Tooltip
                                          placement="left"
                                          tooltipClassname="routingGuides__form-action-tooltips"
                                          tooltipContent={getTooltipContent(action.type.value)}
                                        >
                                          <span className="material-icons">info_outline</span>
                                        </Tooltip>
                                      </div>
                                      {action.type.value === 'TENDER' && (
                                        <TenderFields
                                          index={index}
                                          setFieldValue={setFieldValue}
                                          values={values}
                                          modes={modes}
                                          equipmentTypes={equipmentTypes}
                                          selectedPolicy={selectedPolicy}
                                        />
                                      )}
                                      {action.type.value === 'CREATE_SPOT_NEGOTIATIONS' && (
                                        <BroadcastFields index={index} setFieldValue={setFieldValue} values={values} />
                                      )}
                                      {action.type.value === 'SEND_EMAIL' && <EmailFields index={index} />}
                                      {action.type.value === 'POST_TO_LOADBOARD' && <LoadboardFields index={index} />}
                                      {action.type.value === 'QUOTE_AND_TENDER_LTL' && (
                                        <QuoteAndTenderFields index={index} company={company} />
                                      )}
                                    </div>
                                  </div>
                                </CollapsibleCardContent>
                              </Card>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  </div>
                )}
              </Droppable>
              <div className="routingGuides__form-action">
                <Card
                  title={
                    <DeprecatedButton
                      variant="tertiary"
                      onClick={() => {
                        arrayHelpers.push(defaultFormValues['TENDER']);
                        setTimeout(() => {
                          //scroll to the new action
                          const formContainer = document.querySelector(
                            '#routingGuides__form-action-' + values.actions.length
                          );
                          if (formContainer) {
                            formContainer.scrollIntoView({behavior: 'smooth'});
                          }
                        }, 250);
                      }}
                    >
                      <i className="material-icons pad-right">add_circle_outline</i>Step
                    </DeprecatedButton>
                  }
                  className="routingGuides__form-card collapsed routingGuides__form-add"
                >
                  <div className="routingGuides__form-action-empty"></div>
                </Card>
              </div>
            </Card>
          </div>
        </DragDropContext>
      )}
    />
  );
};

export default compose(
  withFlags('ciLtlAutoQuoteTender'),
  connect((state) => ({
    modes: state.shipments.shipmentModes,
    equipmentTypes: state.shipments.equipmentTypes,
    company: state.auth.company
  }))
)(RoutingGuideActionsFields);
