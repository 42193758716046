import {useReducer, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import classnames from 'classnames';
import moment from 'moment';
import get from 'lodash/get';
import findLast from 'lodash/findLast';
import {Dropdown, SvgIcon} from '@shipwell/shipwell-ui';
import StopStatus from 'App/containers/Shipment/components/StopStatus';
import StopFlags from 'App/containers/Shipment/components/StopFlags';
import StopEventsCreate, {SimpleStopEventsCreate} from 'App/containers/Shipment/components/StopEvents/Create';
import withStatusToasts, {
  WithStatusToastsPropTypes,
  WithStatusToastsDefaultProps
} from 'App/components/withStatusToasts';
import withConditionalFallback from 'App/components/withConditionalFallback';
import {dispatchCarrierEvent, markAsArrivedEvent, markAsDepartedEvent, markAsCompleteEvent} from 'App/utils/stopEvent';
import useStopFlags from 'App/utils/hooks/useStopFlags';
import './styles.scss';
import {
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';

const ADD_FLAG = 'add-flag';
const ADD_EVENT = 'add-event';
const SIMPLE_EVENT = 'simple-event';

const initialActionState = {activeAction: null};
function actionReducer(state, action) {
  return {activeAction: action.type, ...action};
}

function isValidDateProperty(stop, prop) {
  return moment(get(stop, prop, null)).isValid();
}

function stopHasArrival(stop) {
  return isValidDateProperty(stop, 'confirmed_arrival_at') || isValidDateProperty(stop, 'unconfirmed_arrival_at');
}

function stopHasDeparture(stop) {
  return isValidDateProperty(stop, 'confirmed_departure_at') || isValidDateProperty(stop, 'unconfirmed_departure_at');
}

/**
 * We are purposefully ignoring `stop.is_pickup` here because button placement is based on stop
 * index only, and shouldn't account for or skip over multiple pickups in a single shipment.
 */
function stopIsPickup(stop, shipment) {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const lowestOrdinalIndex = Math.min(...shipment?.stops?.map((stop) => stop.ordinal_index));
  return get(stop, 'ordinal_index') === lowestOrdinalIndex;
}

/**
 * We are puposefully ignoring `stop.is_dropoff` here because button placement is based on stop
 * index only, and shouldn't account for or skip over multiple dropoffs in a single shipment.
 */
function stopIsDropoff(stop, shipment) {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const highestOrdinalIndex = Math.max(...shipment?.stops?.map((stop) => stop.ordinal_index));
  return get(stop, 'ordinal_index') === highestOrdinalIndex;
}

/**
 * Find the index of the stop that should have the simple event button.
 *
 * - In Transit shipments shouldn't place the button on pickup stops.
 * - At Delivery shipments should only place the button dropoff stops.
 * - Delivered shipments shouldn't display any quick action.
 *
 * From the stops that _are_ considered, we find the last arrived stop, fall back to the last
 * departed/completed stop, and fall back to the first stop from there.
 *
 * @param {Object} shipment The shipment whose stops we are inspecting
 *
 * @return {int}
 */
export function getIndexOfStopWithButton(shipment) {
  const shipmentStatus = get(shipment, 'state');

  const relevantStops = get(shipment, 'stops', []).filter((stop) =>
    shipmentStatus === 'in_transit'
      ? !stopIsPickup(stop, shipment)
      : shipmentStatus === 'at_delivery'
      ? stopIsDropoff(stop, shipment)
      : ['delivered', 'reconciled', 'cancelled'].indexOf(shipmentStatus) === -1
  );

  const lastArrivedStop = findLast(relevantStops, (stop) => stopHasArrival(stop) && !stopHasDeparture(stop));
  const lastDepartedStop = findLast(relevantStops, (stop) => stopHasDeparture(stop));

  const lastArrivedOrdinal = lastArrivedStop ? get(lastArrivedStop, 'ordinal_index') : Number.NEGATIVE_INFINITY;
  const lastDepartedOrdinal = lastDepartedStop ? get(lastDepartedStop, 'ordinal_index') : Number.NEGATIVE_INFINITY;

  if (stopIsDropoff(lastDepartedStop, shipment) && isValidDateProperty(lastDepartedStop, 'confirmed_departure_at')) {
    return get(lastDepartedStop, 'ordinal_index');
  }

  if (lastArrivedStop || lastDepartedStop) {
    return Math.max(0, lastArrivedOrdinal, lastDepartedOrdinal + 1);
  }

  return get(relevantStops, '0.ordinal_index', -1);
}

/**
 * Get the appropriate event for the given stop.
 *
 * - Dispatched shipments show the Departed event if there's an arrival time or the arrival event if
 *   there isn't _for pickup stops only_ (usually just the first stop.)
 * - At Pickup shipments show the Departed event for pickup stops (usually just the first stop.)
 *
 * For stops in any other status:
 *
 * - Show the Dispatch event for pickup stops (usually just the first stop) whose status is null
 * - Show the Arrived event for stops without an arrival time.
 * - Show the Departed event for stops with an arrival time, which displays as the Completed event
 *   for the last stop.
 *
 * @param {Object} stop The stop whose event we are calculating
 * @param {Object} shipment The shipment that contains this stop
 *
 * @return {Object} The event to display on the stop
 */
export function getSimpleEventForStop(stop, shipment) {
  const shipmentStatus = get(shipment, 'state');
  const hasArrival = stopHasArrival(stop);
  const isLTL = ['LTL', 'VLTL'].includes(shipment?.mode?.code);
  if (shipmentStatus === 'dispatched' && stopIsPickup(stop, shipment)) {
    if (hasArrival) {
      return markAsDepartedEvent;
    }
    if (!isLTL) {
      return markAsArrivedEvent;
    }
  }

  if (shipmentStatus === 'at_pickup' && stopIsPickup(stop, shipment)) {
    return markAsDepartedEvent;
  }

  if (
    (shipmentStatus === 'at_delivery' || isValidDateProperty(stop, 'confirmed_departure_at')) &&
    stopIsDropoff(stop, shipment)
  ) {
    return markAsCompleteEvent;
  }

  if (!hasArrival && !isLTL) {
    if (stopIsPickup(stop, shipment) && get(stop, 'status', null) === null) {
      return dispatchCarrierEvent;
    }
    return markAsArrivedEvent;
  }

  return get(stop, 'ordinal_index') === get(shipment, 'stops.length', 0) - 1
    ? markAsCompleteEvent
    : markAsDepartedEvent;
}

const SimpleStopActionBase = ({shipment, stop, user, onError, onSubmit, setSuccess, className}) => {
  const [showSimpleStopAction, setShowSimpleStopAction] = useState(false);
  const [event, setEvent] = useState(null);

  const canEdit =
    get(user, 'permissions', []).includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
    get(user, 'permissions', []).includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION);

  // simple stop event action button is shown on the stop after the last completed stop
  // if there are no completed stop, the action button appears on the first stop
  // We do not render the button if the shipment is in Draft, Quoting, or Quote Accepted
  useEffect(() => {
    const stopWithButtonIndex = getIndexOfStopWithButton(shipment);
    setShowSimpleStopAction(
      !['quoting', 'quote_accepted', 'draft'].includes(shipment.state) &&
        stopWithButtonIndex === get(stop, 'ordinal_index')
    );
  }, [shipment, stop]);

  // the simple stop event action is 'mark as arrived' for stops without arrival times,
  // 'mark as departed' otherwise (unless last stop, which will be 'mark as complete')
  // unless the first stop's status is empty (i.e. not yet en route), in which case it's 'dispatch carrier'
  useEffect(() => {
    const simpleEvent = getSimpleEventForStop(stop, shipment);
    setEvent(simpleEvent);
  }, [shipment, stop]);

  return canEdit && showSimpleStopAction ? (
    <Dropdown
      className={classnames('shipment__stop-simpleevent', className)}
      variant="secondary"
      indicator={false}
      title={event.label}
    >
      {({onClick}) => (
        <SimpleStopEventsCreate
          onCancel={() => {
            onClick();
          }}
          onSubmit={({title, message}) => {
            onSubmit();
            setSuccess(title, message);
            onClick();
          }}
          onError={onError}
          stop={stop}
          event={event}
          shipment={shipment}
        />
      )}
    </Dropdown>
  ) : null;
};

SimpleStopActionBase.propTypes = {
  shipment: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  stop: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  user: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  onError: PropTypes.func,
  ...WithStatusToastsPropTypes
};

SimpleStopActionBase.defaultProps = {
  onError: () => {},
  onSubmit: () => {},
  ...WithStatusToastsDefaultProps
};

const SimpleStopActionConnected = compose(
  connect((state) => ({
    user: state.userProfile.user
  })),
  withStatusToasts
)(SimpleStopActionBase);

const SimpleStopAction = compose(
  connect((state) => ({
    stopStatusEnabled: get(state, 'userCompany.company.feature_flags.stop_status_enabled', false)
  })),
  withConditionalFallback(({stopStatusEnabled}) => !stopStatusEnabled)
)(SimpleStopActionConnected);

export {SimpleStopAction};

const StopHeader = ({user, shipment, stop, title, setError, setSuccess}) => {
  const [{activeAction}, dispatch] = useReducer(actionReducer, initialActionState);
  const [alerts, retrieveStopAlerts] = useStopFlags(stop, shipment);
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const simpleEvent = getSimpleEventForStop(stop, shipment);
    setEvent(simpleEvent);
  }, [shipment, stop]);

  const clearActiveAction = () => dispatch({type: null});

  const canEdit =
    get(user, 'permissions', []).includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
    get(user, 'permissions', []).includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION);

  const handleError = ({title, error}) => {
    setError(title, error, undefined, {delay: 10000});
  };

  return (
    <div className="shipment__stop-cardHeader">
      <div className="shipment__stop-cardHeader-primary">
        <span className="shipment__stop-cardHeader-title">{title}</span>
        <StopStatus stop={stop} />
      </div>
      <div className="shipment__stop-cardHeader-secondary">
        {canEdit ? (
          <SimpleStopAction
            stop={stop}
            shipment={shipment}
            onError={handleError}
            onSubmit={retrieveStopAlerts}
            className="tw-hidden lg:flex"
          />
        ) : null}
        <StopFlags stop={stop} alerts={alerts} onRemoveFlag={retrieveStopAlerts} />
        {canEdit && (
          <div className="shipment__stop-cardHeader-action">
            <Dropdown
              className="stop-status-edit"
              icon={<SvgIcon name="Overflow" />}
              variant="activeIcon"
              indicator={false}
              onToggle={clearActiveAction}
              alignEnd
              popperConfig={{
                modifiers: {
                  preventOverflow: {
                    enabled: [ADD_FLAG, ADD_EVENT, SIMPLE_EVENT].includes(activeAction)
                  },
                  flip: {
                    enabled: [ADD_FLAG, ADD_EVENT, SIMPLE_EVENT].includes(activeAction)
                  }
                }
              }}
            >
              {({onClick}) => (
                <>
                  {activeAction === ADD_EVENT ? (
                    <StopEventsCreate
                      shipment={shipment}
                      onCancel={() => {
                        onClick();
                        clearActiveAction();
                      }}
                      onSubmit={({title, message}) => {
                        onClick();
                        clearActiveAction();
                        retrieveStopAlerts();
                        setSuccess(title, message);
                      }}
                      onError={handleError}
                      stop={stop}
                    />
                  ) : activeAction === SIMPLE_EVENT ? (
                    <SimpleStopEventsCreate
                      onCancel={() => {
                        onClick();
                      }}
                      onError={handleError}
                      onSubmit={retrieveStopAlerts}
                      stop={stop}
                      event={event}
                      shipment={shipment}
                    />
                  ) : (
                    <>
                      <li onClick={() => dispatch({type: SIMPLE_EVENT})} className="lg:hidden">
                        {event.label}
                      </li>
                      <li onClick={() => dispatch({type: ADD_EVENT})}>Add Stop Event</li>
                    </>
                  )}
                </>
              )}
            </Dropdown>
          </div>
        )}
      </div>
    </div>
  );
};

StopHeader.propTypes = {
  user: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  shipment: PropTypes.shape({
    id: PropTypes.string,
    state: PropTypes.string
  }).isRequired,
  stop: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  title: PropTypes.string.isRequired,
  ...WithStatusToastsPropTypes
};

StopHeader.defaultProps = {
  ...WithStatusToastsDefaultProps
};

const ConnectedStopHeader = compose(
  withStatusToasts,
  connect((state) => ({
    user: state.userProfile.user,
    shipment: state.shipmentdetails.one
  }))
)(StopHeader);

const NoStopStatus = ({title}) => (
  <div className="shipment__stop-cardHeader">
    <span className="shipment__stop-cardHeader-title">{title}</span>
  </div>
);

NoStopStatus.propTypes = {
  title: PropTypes.string
};

const StopHeaderWithFallback = compose(
  connect((state) => ({
    stopStatusEnabled: get(state, 'userCompany.company.feature_flags.stop_status_enabled', false)
  })),
  withConditionalFallback(({stopStatusEnabled}) => !stopStatusEnabled, NoStopStatus)
)(ConnectedStopHeader);

export default StopHeaderWithFallback;
