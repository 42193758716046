import {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import upperFirst from 'lodash/upperFirst';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import {
  Card,
  DisplayValue,
  Tooltip,
  Pill,
  DeprecatedButton,
  SvgIcon,
  Dropdown,
  Modal,
  IconButton
} from '@shipwell/shipwell-ui';
import {compose} from 'recompose';
import {DashboardSummaryTabs} from './DashboardSummaryTabs';
import DashboardSummaryBids from './DashboardSummaryBids';
import ShipwellLoader from 'App/common/shipwellLoader/index';
import {
  formatDayOfWeekDateTime,
  renderLineItemString,
  formatDateTime,
  reeferTypes,
  formatMileage
} from 'App/utils/globals';
import {checkShipmentModes, calculateShipmentTotals} from 'App/utils/globalsTyped';
import {getPhoneHyperlink} from 'App/utils/getPhoneHyperlink';
import {renderFinancialsTable} from 'App/utils/shipmentTableHelpers/typed';
import {getBreadcrumbs} from 'App/actions/shipmentdetails';
import CarrierDetailSidebar from 'App/containers/Settings/ManageVendors/CarrierDetailSidebar';
import './styles.scss';
import Messages from 'App/components/Messages';
import TenderRejectForm from 'App/containers/LoadBoard/components/TenderRejectForm';
import TenderRequestWarning from 'App/containers/LoadBoard/components/TenderRequestWarning';
import {getMissingDataByStop, getEdiDataErrors} from 'App/containers/LoadBoard/utils';
import {
  METRIC,
  convertFahrenheitToCelsius,
  formatCurrency,
  convertMilesToKilometers,
  displayTotalWeight
} from 'App/utils/internationalConstants';
import {startCaseToLower} from 'App/utils/startCaseToLower';
import getItineraryAddressString from 'App/utils/getItineraryAddressString';
import getNil from 'App/utils/getNil';
import {SimpleStopAction} from 'App/containers/Shipment/components/StopHeader';
import {tenderStatuses} from 'App/containers/tendering/create/utils/constants';
import {StopEtaLastUpdated, StopEtaPill} from 'App/components/stopEta';
import withFlags from 'App/utils/withFlags';
import {DETAILS, MESSAGES, MY_BIDS, TIMELINE, WORKFLOWS, STAGES, CARRIER_BIDS} from 'App/utils/dashboardConstants';
import LegacyTimelineContainer from 'App/components/ShipmentTrackingOverview/TimelineContainer';
import LegacyMapContainer from 'App/components/ShipmentTrackingOverview/MapContainer';
import OriginAndDestinationBanner from 'App/common/OriginAndDestinationBanner';
import {StagesViewWithShipmentId} from 'App/containers/Shipment/components/Stages/Stages';
import ShipmentStopSchedulesForm from 'App/formComponents/forms/shipmentStopSchedules';
import withStatusToasts, {WithStatusToastsPropTypes} from 'App/components/withStatusToasts';
import PlaceBidModal from 'App/containers/LoadBoard/components/PlaceBidModal';
import QuoteDocumentModal from 'App/containers/LoadBoard/components/QuoteDocument';
import {DashboardSummaryWorkflow} from 'App/components/dashboardSummary/DashboardSummaryWorkflows';
import {FlexBox} from 'App/components/Box';
import {FinancialTenderingUserPermissionFallback} from 'App/components/permissions/PermissionsFallback/FinancialTenderingUserPermissionFallback';
import {VIEW_SHIPMENT_FINANCIALS_USER_PERMISSION} from 'App/components/permissions/PermissionsFallback/constants';

let filterDistance = -1;

const DashboardSummaryStopBase = ({
  shipment,
  stop,
  isLoadBoard,
  allowStopAction,
  onSubmit,
  tripManagementPredictedEta,
  hasLTL,
  handleShowAppointmentModal,
  canEditShipment,
  showEditStopDropdown
}) => {
  const timezone = getNil(stop, 'location.address.timezone', moment.tz.guess());
  const hasConfirmedArrival = stop.confirmed_arrival_at && moment(stop.confirmed_arrival_at).isValid();
  const hasUnconfirmedArrival = stop.unconfirmed_arrival_at && moment(stop.unconfirmed_arrival_at).isValid();
  const hasConfirmedDeparture = stop.confirmed_departure_at && moment(stop.confirmed_departure_at).isValid();
  const hasUnconfirmedDeparture = stop.confirmed_departure_at && moment(stop.unconfirmed_departure_at).isValid();

  const arrivalTimeToShow = hasConfirmedArrival
    ? formatDayOfWeekDateTime(stop.confirmed_arrival_at, true, timezone)
    : hasUnconfirmedArrival
    ? formatDayOfWeekDateTime(stop.unconfirmed_arrival_at, true, timezone)
    : hasConfirmedDeparture || hasUnconfirmedDeparture
    ? '--'
    : null;
  const departureTimeToShow = hasConfirmedDeparture
    ? formatDayOfWeekDateTime(stop.confirmed_departure_at, true, timezone)
    : hasUnconfirmedDeparture
    ? formatDayOfWeekDateTime(stop.unconfirmed_departure_at, true, timezone)
    : hasConfirmedArrival || hasUnconfirmedArrival
    ? '--'
    : null;

  const addressToShow = getItineraryAddressString(stop, isLoadBoard, true);
  return (
    <>
      <div className="dashboard-summary__all-row mb-3 grid grid-cols-12 rounded border-sw-background bg-sw-background px-3 pt-2">
        <div className={showEditStopDropdown ? 'col-span-7' : 'col-span-8'}>
          <DisplayValue
            label={stop.is_pickup ? (stop.is_dropoff ? 'Pickup/Delivery' : 'Pickup') : 'Delivery'}
            className="mb-3"
          >
            <div className="dashboard-summary__stop-address">{addressToShow}</div>
            <div>{arrivalTimeToShow ? 'Arrival: ' + arrivalTimeToShow : null}</div>
            <div>{departureTimeToShow ? 'Departure: ' + departureTimeToShow : null}</div>
            <div>
              {arrivalTimeToShow || departureTimeToShow
                ? null
                : stop.trip_management_eta && !hasLTL
                ? formatDayOfWeekDateTime(stop.trip_management_eta, true, timezone)
                : stop.display_eta_window}
            </div>
            {allowStopAction ? (
              <SimpleStopAction shipment={shipment} stop={stop} onSubmit={onSubmit} className="mt-3" />
            ) : null}
          </DisplayValue>
          <DisplayValue label="Stop Accessorials" className="mb-0">
            {stop?.accessorials?.length > 0
              ? stop.accessorials.map((item, i) => <div key={i}>{item.description}</div>)
              : '--'}
          </DisplayValue>
        </div>
        {tripManagementPredictedEta && !hasLTL ? (
          <div className="col-span-4">
            <FlexBox justify="center">
              <StopEtaPill stop={stop} duration="hours" size="sm" />
            </FlexBox>
            <div className="flex justify-center">
              <StopEtaLastUpdated
                stop={stop}
                isExpandedLastUpdateDisplay={false}
                warningIconHeight="16"
                warningIconWidth="16"
              />
            </div>
          </div>
        ) : null}
        {showEditStopDropdown ? (
          <div className="col-span-2 col-start-11 justify-self-end">
            <Dropdown indicator={false} variant="icon" icon={<SvgIcon color="$sw-icon" name="Overflow" />}>
              {({onClick}) => [
                <li
                  className={`${canEditShipment ? null : 'pointer-events-none opacity-60'}`}
                  key="edit-times"
                  onClick={() => {
                    onClick();
                    handleShowAppointmentModal();
                  }}
                >
                  Edit Times
                </li>
              ]}
            </Dropdown>
          </div>
        ) : null}
      </div>
    </>
  );
};

DashboardSummaryStopBase.propTypes = {
  shipment: PropTypes.object,
  stop: PropTypes.shape({
    id: PropTypes.string,
    trip_management_eta: PropTypes.string,
    display_eta_window: PropTypes.string,
    is_pickup: PropTypes.bool,
    is_dropoff: PropTypes.bool,
    accessorials: PropTypes.array,
    confirmed_arrival_at: PropTypes.string,
    confirmed_departure_at: PropTypes.string,
    unconfirmed_arrival_at: PropTypes.string,
    unconfirmed_departure_at: PropTypes.string
  }).isRequired,
  isLoadBoard: PropTypes.bool,
  allowStopAction: PropTypes.bool,
  onSubmit: PropTypes.func,
  tripManagementPredictedEta: PropTypes.bool,
  hasLTL: PropTypes.bool,
  handleShowAppointmentModal: PropTypes.func,
  canEditShipment: PropTypes.bool,
  showEditStopDropdown: PropTypes.bool
};

DashboardSummaryStopBase.defaultProps = {
  isLoadBoard: false,
  allowStopAction: false,
  onSubmit: () => {},
  tripManagementPredictedEta: false,
  hasLTL: false,
  handleShowAppointmentModal: () => {},
  canEditShipment: false,
  showEditStopDropdown: false
};

const DashboardSummaryStop = withFlags('tripManagementPredictedEta')(DashboardSummaryStopBase);

class DashboardSummary extends Component {
  static defaultProps = {
    closeBidForm: () => {},
    onShipmentUpdate: () => {},
    showEditStopDropdown: false
  };

  constructor(props) {
    super(props);
    this.state = {
      tabsValue: DETAILS,
      breadcrumbs: [],
      dashboardSummaryTabs: [
        ...(this.props.tabOptions?.includes(DETAILS) ? [{id: DETAILS, label: 'Details'}] : []),
        ...(this.props.tabOptions?.includes(TIMELINE) ? [{id: TIMELINE, label: 'Timeline'}] : []),
        ...(this.props.tabOptions?.includes(MY_BIDS) && !this.props.isTendered
          ? [{id: MY_BIDS, label: 'My Bids'}]
          : []),
        ...(this.props.tabOptions?.includes(MESSAGES) ? [{id: MESSAGES, label: 'Messages'}] : []),
        ...(this.props.tabOptions?.includes(WORKFLOWS) ? [{id: WORKFLOWS, label: 'Workflows'}] : []),
        ...(this.props.tabOptions?.includes(STAGES) ? [{id: STAGES, label: 'Stages'}] : []),
        ...(this.props.tabOptions?.includes(CARRIER_BIDS) ? [{id: CARRIER_BIDS, label: 'Bids'}] : [])
      ],
      isLoadingRoutingGuides: false,
      routingGuides: null,
      workflowExecutionErrorDetails: null,
      showAppointmentModal: false,
      documentPreviewQuote: null
    };
    this._count = 1;
    this.handleTabsChange = this.handleTabsChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.shipment && this.props.shipment) ||
      (prevProps.shipment && this.props.shipment && prevProps.shipment.id !== this.props.shipment.id)
    ) {
      //reset to the details view when shipment changes
      if (this.props.showBidForm) {
        this.props.closeBidForm();
      }
      this.setState({tabsValue: 'details'});
      this.setState({showRejectForm: false});
      filterDistance = -1;
    }
    if (prevProps.showBidForm !== this.props.showBidForm && this.props.showBidForm) {
      this.setState({tabsValue: 'myBids'});
    }
    if (prevProps.showRejectedToast !== this.props.showRejectedToast && this.props.showRejectedToast) {
      this.setState({showRejectForm: false});
    }
  }

  componentWillUnmount() {
    filterDistance = -1;
  }

  handleTabsChange(newIndex) {
    const selectedTabId = this.state.dashboardSummaryTabs[newIndex].id;
    this.setState({tabsValue: selectedTabId});

    if (selectedTabId !== 'myBids' && this.props.showBidForm) {
      this.props.closeBidForm();
    }
  }

  async fetchBreadcrumbs(distance) {
    distance = distance || null;
    const {shipment = {}} = this.props;
    // fetch on zoom or on interval using existing zoom
    if (shipment && ((distance && filterDistance !== distance) || (!distance && filterDistance))) {
      const newDistance = distance ? distance : filterDistance;
      filterDistance = newDistance;
      const response = await this.props.getBreadcrumbs(
        shipment.id,
        {
          filterDistance: newDistance
        },
        this._count
      );
      if (response) {
        this._count++;
      }
    }
  }

  getTemperatureString = (temp, unitPreferences) => {
    if (temp || temp === 0) {
      return unitPreferences?.system === METRIC ? `${Math.round(convertFahrenheitToCelsius(temp))}\xB0 C` : `${temp} ℉`;
    }
    return '--';
  };

  renderStatusTooltip = (carrierStatus) => {
    return (
      <>
        <Tooltip
          tooltipContent={
            <div>
              <span>The Carrier Status within the network of the Company assigning this Shipment.</span>
            </div>
          }
          wrapperClassname="tooltipWrapper"
          tooltipClassname="tooltipContent"
          trigger="hover"
        >
          <Pill
            variant={carrierStatus === 'ACTIVE' ? 'primary' : carrierStatus === 'INACTIVE' ? 'inactive' : 'warning'}
          >
            {startCaseToLower(carrierStatus)}
          </Pill>
        </Tooltip>
      </>
    );
  };

  getMostRecentOpenTender(tenders) {
    const {EXPIRED, REJECTED, REVOKED} = tenderStatuses;
    //use the tender amount from the most recently created open tender
    let openTenders = tenders.filter((tender) => ![EXPIRED, REJECTED, REVOKED].includes(tender.status));
    openTenders = openTenders.sort((a, b) => {
      return a.created_at > b.created_at ? -1 : 1;
    });
    return openTenders[0];
  }

  handleUpdateSuccess(updatedShipment) {
    this.props.setSuccess(
      'Success!',
      `Stop dates and times for Shipment ${updatedShipment.reference_id} have been updated.`
    );
    this.props.onShipmentUpdate();
    this.setState({showAppointmentModal: false});
  }

  render() {
    const {
      shipment: shipmentProp,
      isLoading,
      company,
      shipmentInvoices,
      shipmentPOC,
      tabOptions = [],
      isLoadBoard,
      isCarrierDetailView,
      selectedRelationship,
      featureFlags,
      loadBoardShipmentDetails,
      openBidForm,
      closeBidForm,
      unitPreferences,
      router,
      performingTenderAction,
      shipmentDetails,
      onShipmentUpdate,
      allowStopAction,
      showEditStopDropdown,
      handlePlaceBidSuccess,
      setError,
      loadingSpotNegotationDetails,
      isDeletingQuote,
      drawerHeader
    } = this.props;
    // sometimes `shipment` is provided directly, but we fall back to shipment details in redux if it's not
    const shipment = shipmentProp || shipmentDetails;

    const {tabsValue, documentPreviewQuote} = this.state;
    //grab the lat/lng of the addresses if they exist to put on the map
    const markerArray = [];
    let stopsArr = [];
    let quotes = [];

    if (shipment?.stops?.length > 0) {
      stopsArr = shipment.stops;
      for (var i = 0; i < stopsArr.length; i++) {
        //construct the markerArray for the map
        if (stopsArr[i].location?.address?.latitude && stopsArr[i].location?.address?.longitude) {
          markerArray.push({
            show: true,
            position: {
              lat: stopsArr[i].location.address.latitude,
              lng: stopsArr[i].location.address.longitude
            },
            markerNumber: i + 1
          });
        }
      }
    }

    const shouldShowMap =
      shipment?.stops?.length > 0 &&
      shipment.stops.every((stop) => stop?.location?.address?.latitude && stop?.location?.address?.longitude);
    const hasAlert = shipment?.metadata?.alert_level;
    const maxBuyAmount = shipment?.metadata?.max_buy_amount;
    const buyNow = shipment?.metadata?.buy_it_now_amount;
    const yourBid = shipment?.lowest_bid_amount;
    const {hasFTL, hasLTL, hasDrayage, hasParcel} = checkShipmentModes(shipment?.mode);
    const ediDataErrors =
      isLoadBoard &&
      loadBoardShipmentDetails?.tenders?.some((t) => t.external_edi_system_shipment_id) &&
      getEdiDataErrors(getMissingDataByStop(loadBoardShipmentDetails.stops));
    const hasOpenAuction = shipment?.metadata?.has_open_auction;
    const isTendered = shipment?.tenders?.length > 0;
    const multipleTenders = shipment?.tenders?.length > 1;
    const mostRecentOpenTender = shipment?.tenders?.length > 0 ? this.getMostRecentOpenTender(shipment.tenders) : null;
    const hasReefer = shipment?.equipment_types?.filter((e) => reeferTypes.includes(e.id)).length > 0;

    if (this.props.spotNegotiationQuotes?.results?.length > 0) {
      quotes = JSON.parse(JSON.stringify(this.props.spotNegotiationQuotes.results));
      quotes.sort((a, b) => {
        return a.created_at > b.created_at ? -1 : 1;
      });
    }

    let declaredValue = '--';
    if (shipment?.total_declared_value) {
      declaredValue = formatCurrency(shipment.total_declared_value, shipment.total_declared_value_currency);
    } else if (shipment?.line_items?.some((e) => e.value_per_piece && e.total_pieces)) {
      const totals = calculateShipmentTotals({
        line_items: shipment.line_items,
        unitPreferences,
        totalWeight: shipment.total_weight_override
      });
      declaredValue = totals.value ? formatCurrency(totals.value, totals.currency) : '--';
    }

    return (
      <div className="shipment-drawer-body bg-sw-bg-component flex h-full flex-row rounded-sm shadow">
        {!isLoading && shipment && !this.state.showRejectForm && this.state.dashboardSummaryTabs?.length > 1 ? (
          <DashboardSummaryTabs
            options={this.props.tabOptions}
            onClick={this.handleTabsChange}
            selectedIndex={this.state.dashboardSummaryTabs.findIndex((tab) => tab.id === this.state.tabsValue)}
          />
        ) : null}
        <div className="flex w-full flex-col">
          {drawerHeader ||
            (this.props.shipmentLoaded ? (
              <div className="drawer-header flex items-center justify-between ">
                <span>
                  <div className="text-black text-lg font-bold">
                    {this.props.shipmentDetails?.metadata?.v3_shipment_reference_id}
                  </div>
                </span>
                <div className="flex items-center p-2">
                  <Link
                    to={`/shipments/${this.props.shipmentDetails?.id}`}
                    target="_blank"
                    className="mr-2 whitespace-nowrap"
                  >
                    View Shipment Details
                  </Link>
                  <Dropdown
                    indicator={false}
                    drop="bottom"
                    variant="icon"
                    className="mr-1"
                    icon={<SvgIcon color="$sw-icon" name="Overflow" />}
                  >
                    {this.props.renderSidebarDropdownContent}
                  </Dropdown>
                  <IconButton
                    iconName="Close"
                    onClick={this.props.onCloseSidebarDropdown}
                    aria-label="Close dashboard summary"
                  />
                </div>
              </div>
            ) : null)}
          {!isLoading && shipment ? (
            <div className="dashboard-summary__details-map h-auto">
              {!(tabsValue === STAGES) ? (
                <>
                  {shouldShowMap ? (
                    <div className="shipment-map h-[300px]">
                      <LegacyMapContainer
                        shipmentId={shipment.id}
                        one={shipment}
                        isLoadBoard={isLoadBoard} // on loadboard we prevent zoom and hide street view option
                      />
                    </div>
                  ) : null}
                  {stopsArr?.length > 1 ? (
                    <OriginAndDestinationBanner
                      origin={
                        stopsArr[0].location.address &&
                        [stopsArr[0].location.address.city, stopsArr[0].location.address.state_province]
                          .filter((a) => a)
                          .join(', ')
                      }
                      destination={
                        stopsArr[stopsArr.length - 1].location.address &&
                        [
                          stopsArr[stopsArr.length - 1].location.address.city,
                          stopsArr[stopsArr.length - 1].location.address.state_province
                        ]
                          .filter((a) => a)
                          .join(', ')
                      }
                    />
                  ) : null}
                </>
              ) : null}
            </div>
          ) : null}

          <Modal
            show={this.state.showAppointmentModal}
            title="Edit Shipment Stops"
            footerComponent={null}
            onClose={() => this.setState({showAppointmentModal: false})}
          >
            <ShipmentStopSchedulesForm
              onSuccess={this.handleUpdateSuccess.bind(this)}
              values={shipment}
              onCancel={() => this.setState({showAppointmentModal: false})}
            />
          </Modal>

          <div
            className={classNames('dashboard-summary overflow-x-hidden overflow-y-auto', {
              'overflow-y-hidden': isLoading
            })}
          >
            {isCarrierDetailView && selectedRelationship ? (
              <div className="dashboard-summary__details overflow-y-auto">
                <CarrierDetailSidebar selectedRelationship={selectedRelationship} featureFlags={featureFlags} />
              </div>
            ) : null}

            {!isCarrierDetailView ? (
              <>
                {isLoading ? <ShipwellLoader loading={isLoading} /> : null}
                {!isLoading && shipment ? (
                  <div className="dashboard-summary__details overflow-y-auto">
                    {tabsValue === STAGES ? (
                      <div className="px-2">
                        <StagesViewWithShipmentId resourceId={shipment?.id} />
                      </div>
                    ) : (
                      <>
                        {(maxBuyAmount > 0 || buyNow > 0) && shipment.bidding_status !== 'won' ? (
                          <div className="dashboard-summary__buyAmounts">
                            {maxBuyAmount > 0 && (
                              <div className="maxbuy-bar">
                                <strong>Max Buy:</strong>{' '}
                                <span className="text-primary">
                                  {' '}
                                  {formatCurrency(maxBuyAmount, shipment.preferred_currency)}
                                </span>
                              </div>
                            )}
                            {buyNow > 0 && shipment.bidding_status !== 'won' ? (
                              <div className="buynow-bar">
                                <strong>Book Now:</strong>{' '}
                                <span className="text-success">
                                  {formatCurrency(buyNow, shipment.preferred_currency)}
                                </span>
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                        {isLoadBoard &&
                        this.props.goToShipment &&
                        this.props.showBuyItNow &&
                        !['won', 'closed'].includes(shipment.bidding_status) ? (
                          <div className="loadBoard__options">
                            {!isEmpty(ediDataErrors) ? (
                              <TenderRequestWarning
                                errors={ediDataErrors}
                                loadBoardId={loadBoardShipmentDetails?.load_board_id}
                              />
                            ) : null}
                            <div className="dashboard-summary__buyAmounts justify-center">
                              {yourBid > 0 ? (
                                <div className="maxbuy-bar">
                                  <strong>Your Bid:</strong>{' '}
                                  <span>{formatCurrency(yourBid, shipment.preferred_currency)}</span>
                                </div>
                              ) : null}
                              {isTendered ? (
                                <div className="maxbuy-bar">
                                  <strong>Tendered:</strong>{' '}
                                  <span>
                                    {mostRecentOpenTender
                                      ? formatCurrency(
                                          mostRecentOpenTender.charge_line_items.reduce(
                                            (total, item) => total + item.unit_amount * item.unit_quantity,
                                            0
                                          ),
                                          shipment.preferred_currency
                                        )
                                      : null}
                                  </span>
                                </div>
                              ) : null}

                              {!isTendered && shipment.buy_it_now_amount > 0 && shipment.bidding_status !== 'closed' ? (
                                <div className="buynow-bar">
                                  <strong>Book Now:</strong>{' '}
                                  <span className="text-success">
                                    {formatCurrency(shipment.buy_it_now_amount, shipment.preferred_currency)}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                            {isTendered && !multipleTenders ? (
                              //when shipment is tendered, give the option to accept or reject unless it has already been rejected
                              <div className="loadBoard__options-buttons mx-4">
                                {!['rejected', 'expired'].includes(shipment.bidding_status) ? (
                                  <DeprecatedButton
                                    onClick={() => {
                                      this.handleTabsChange(
                                        this.state.dashboardSummaryTabs.findIndex((tab) => tab.id === 'myBids')
                                      );
                                      this.setState({showRejectForm: true});
                                    }}
                                    variant="secondary"
                                    className="loadBoard__options-buttons btn"
                                  >
                                    Reject
                                  </DeprecatedButton>
                                ) : null}
                                {mostRecentOpenTender?.charge_line_items.reduce(
                                  (total, item) => total + item.unit_amount * item.unit_quantity,
                                  0
                                ) > 0 && !['rejected', 'accepted', 'expired'].includes(shipment.bidding_status) ? (
                                  <DeprecatedButton
                                    onClick={(e) => this.props.acceptTender(e, shipment)}
                                    variant="primary"
                                    disabled={
                                      performingTenderAction || mostRecentOpenTender?.carrier_status !== 'ACTIVE'
                                    }
                                    className="loadBoard__options-buttons btn"
                                  >
                                    Accept
                                  </DeprecatedButton>
                                ) : null}
                              </div>
                            ) : multipleTenders ? (
                              <div className="loadBoard__options-buttons mx-4">
                                {isTendered && (
                                  <DeprecatedButton
                                    className="loadBoard__options-buttons btn"
                                    onClick={() => router.push(`/load-board/${shipment.load_board_id}`)}
                                  >
                                    View All
                                  </DeprecatedButton>
                                )}
                              </div>
                            ) : (
                              <div className="mx-4 flex justify-between gap-x-4">
                                <DeprecatedButton
                                  onClick={openBidForm}
                                  className="w-full"
                                  disabled={shipment.spot_negotiation_carrier_status === 'DO_NOT_USE'}
                                  loading={loadingSpotNegotationDetails}
                                >
                                  Place a Bid
                                </DeprecatedButton>
                                <PlaceBidModal
                                  loadboardShipment={loadBoardShipmentDetails}
                                  handleClose={closeBidForm}
                                  showPlaceBidModal={
                                    this.props.showBidForm && shipment?.spot_negotiation_carrier_status !== 'DO_NOT_USE'
                                  }
                                  handlePlaceBidSuccess={handlePlaceBidSuccess}
                                  setError={setError}
                                />
                                {shipment.buy_it_now_amount > 0 && (
                                  <DeprecatedButton
                                    onClick={(e) => this.props.showBuyItNow(shipment, e)}
                                    className="w-full"
                                    variant="action"
                                    disabled={['INACTIVE', 'DO_NOT_USE'].includes(
                                      shipment.spot_negotiation_carrier_status
                                    )}
                                  >
                                    Book Now
                                  </DeprecatedButton>
                                )}
                              </div>
                            )}
                          </div>
                        ) : null}

                        {this.state.showRejectForm ? (
                          <div className="mx-4">
                            <DisplayValue label="Reject Tender" className="mx-2 mb-4">
                              <TenderRejectForm
                                rejectingShipment={shipment}
                                performingTenderAction={performingTenderAction}
                                rejectTender={this.props.rejectTender}
                                handleCancel={() => this.setState({showRejectForm: false})}
                              />
                            </DisplayValue>
                          </div>
                        ) : null}
                        {tabsValue === DETAILS ? (
                          <div className="dashboard-summary__all mx-4 mt-4">
                            {stopsArr.map((stop) => (
                              <DashboardSummaryStop
                                key={stop.id}
                                shipment={shipment}
                                stop={stop}
                                isLoadBoard={isLoadBoard}
                                allowStopAction={allowStopAction}
                                onSubmit={onShipmentUpdate}
                                hasLTL={hasLTL}
                                handleShowAppointmentModal={() => this.setState({showAppointmentModal: true})}
                                canEditShipment={this.props.canEditShipment}
                                showEditStopDropdown={showEditStopDropdown}
                              />
                            ))}

                            {hasAlert ? (
                              <DisplayValue label="Alerts" className="mx-2 mb-3">
                                <div className="flex items-center">
                                  <svg x="0px" y="0px" viewBox="0 0 512 512" width="20px" height="20px">
                                    <g>
                                      <g>
                                        <path
                                          className={'dashboard__priority ' + shipment.metadata.alert_level}
                                          d="M507.494,426.066L282.864,53.537c-5.677-9.415-15.87-15.172-26.865-15.172c-10.995,0-21.188,5.756-26.865,15.172
                              L4.506,426.066c-5.842,9.689-6.015,21.774-0.451,31.625c5.564,9.852,16.001,15.944,27.315,15.944h449.259
                              c11.314,0,21.751-6.093,27.315-15.944C513.508,447.839,513.336,435.755,507.494,426.066z M256.167,167.227
                              c12.901,0,23.817,7.278,23.817,20.178c0,39.363-4.631,95.929-4.631,135.292c0,10.255-11.247,14.554-19.186,14.554
                              c-10.584,0-19.516-4.3-19.516-14.554c0-39.363-4.63-95.929-4.63-135.292C232.021,174.505,242.605,167.227,256.167,167.227z
                              M256.498,411.018c-14.554,0-25.471-11.908-25.471-25.47c0-13.893,10.916-25.47,25.471-25.47c13.562,0,25.14,11.577,25.14,25.47
                              C281.638,399.11,270.06,411.018,256.498,411.018z"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                  <span className="ml-1">{shipment.metadata.alert_message}</span>
                                </div>
                              </DisplayValue>
                            ) : null}

                            <DisplayValue label="Status" className="mx-2 mb-3">
                              {isLoadBoard
                                ? upperFirst(shipment.bidding_status)
                                : shipment.state && !hasParcel
                                ? upperFirst(shipment.state.split('_').join(' ').toLowerCase())
                                : null}
                              {hasParcel && shipment ? (
                                <>
                                  {shipment.state === 'carrier_confirmed'
                                    ? 'Carrier Confirmed'
                                    : shipment.parcel_pickup_status === 'NOT_SCHEDULED'
                                    ? 'Quoting'
                                    : shipment.parcel_pickup_status === 'SCHEDULED'
                                    ? 'Scheduled'
                                    : shipment.parcel_pickup_status === 'IN_TRANSIT'
                                    ? 'In Transit'
                                    : shipment.parcel_pickup_status === 'DELAYED'
                                    ? 'Delayed'
                                    : shipment.parcel_pickup_status === 'DELIVERED'
                                    ? 'Delivered'
                                    : null}
                                </>
                              ) : null}
                              {
                                //on dashboard, include link directly to bids page if auction is open
                                hasOpenAuction && tabOptions.includes('timeline') ? (
                                  <Link className="ml-1" to={`/marketplace/${shipment.id}/bids`}>
                                    View Bids
                                  </Link>
                                ) : null
                              }
                            </DisplayValue>

                            {company?.feature_flags?.invoicing_enabled ? (
                              <DisplayValue label="Invoice" className="mx-2 mb-3">
                                {shipmentInvoices?.results?.length
                                  ? shipmentInvoices.results.map((el) => {
                                      return (
                                        <div key={el.id}>
                                          #{el.doc_number} created on {moment(el.created_at).format('MM/DD/YY')}
                                        </div>
                                      );
                                    })
                                  : 'None'}
                              </DisplayValue>
                            ) : null}

                            {hasDrayage ? (
                              <>
                                <DisplayValue label="Estimated Port Arrival" className="mx-2 mb-3">
                                  {moment(shipment.drayage_estimated_arrival_date).isValid()
                                    ? moment(shipment.drayage_estimated_arrival_date).format('MM/DD/YYYY')
                                    : '--'}
                                </DisplayValue>
                                <DisplayValue label="Release Date" className="mx-2 mb-3">
                                  {moment(shipment.drayage_release_date).isValid()
                                    ? moment(shipment.drayage_release_date).format('MM/DD/YYYY')
                                    : '--'}
                                </DisplayValue>
                                <DisplayValue label="Last Free Date" className="mx-2 mb-3">
                                  {moment(shipment.drayage_last_free_date).isValid()
                                    ? moment(shipment.drayage_last_free_date).format('MM/DD/YYYY')
                                    : '--'}
                                </DisplayValue>
                                <DisplayValue label="Container Return Date" className="mx-2 mb-3">
                                  {moment(shipment.drayage_container_return_date).isValid()
                                    ? moment(shipment.drayage_container_return_date).format('MM/DD/YYYY')
                                    : '--'}
                                </DisplayValue>
                              </>
                            ) : null}

                            <DisplayValue label="Shipment Accessorials" className="mx-2 mb-3">
                              {shipment.accessorials && Array.isArray(shipment.accessorials)
                                ? shipment.accessorials.map((item, i) => <div key={i}>{item.description}</div>)
                                : '--'}
                            </DisplayValue>

                            <DisplayValue label="Notes for Carrier" className="mx-2 mb-3">
                              {shipment.notes_for_carrier ?? '--'}
                            </DisplayValue>

                            <DisplayValue label={`${!hasFTL ? 'Estimated ' : ''}Distance`} className="mx-2 mb-3">
                              {shipment.total_miles && !isNaN(shipment.total_miles)
                                ? unitPreferences.system === METRIC
                                  ? `${convertMilesToKilometers(shipment.total_miles)} km`
                                  : `${formatMileage(shipment.total_miles)} mi`
                                : '--'}
                            </DisplayValue>

                            <DisplayValue label="Customer" className="mx-2 mb-3">
                              {isLoadBoard
                                ? shipment?.customer?.name
                                  ? shipment.customer.name
                                  : '--'
                                : shipment?.relationship_to_customer?.customer?.name
                                ? shipment.relationship_to_customer.customer?.name
                                : '--'}
                            </DisplayValue>

                            <DisplayValue label="Mode" className="mx-2 mb-3">
                              {isLoadBoard
                                ? shipment?.modes?.length > 0
                                  ? shipment.modes.map((mo, i) => {
                                      return mo.code + (i === shipment.modes.length - 1 ? '' : ', ');
                                    })
                                  : '--'
                                : shipment?.mode?.code
                                ? shipment.mode.code
                                : '--'}
                            </DisplayValue>

                            <DisplayValue label="Equipment" className="mx-2 mb-3">
                              {isLoadBoard
                                ? shipment?.equipment_types?.length > 0
                                  ? shipment.equipment_types.map((eq, i) => {
                                      return eq.name + (i === shipment.equipment_types.length - 1 ? '' : ', ');
                                    })
                                  : '--'
                                : shipment?.equipment_type?.name
                                ? shipment.equipment_type.name
                                : '--'}
                            </DisplayValue>

                            {hasReefer && isLoadBoard ? (
                              <>
                                <DisplayValue label="Minimum Temperature" className="mx-2 mb-3">
                                  {this.getTemperatureString(shipment.temperature_lower_limit)}
                                </DisplayValue>
                                <DisplayValue label="Maximum Temperature" className="mx-2 mb-3">
                                  {this.getTemperatureString(shipment.temperature_upper_limit)}
                                </DisplayValue>
                              </>
                            ) : null}

                            <DisplayValue label="Items" className="mx-2 mb-3">
                              {shipment.line_items?.length > 0
                                ? shipment.line_items.map((item, i) => {
                                    return (
                                      <div key={i} className="w-[300px] overflow-hidden text-ellipsis">
                                        {renderLineItemString(item, i)}
                                      </div>
                                    );
                                  })
                                : '--'}
                              {displayTotalWeight({
                                shipment,
                                unitPreferences,
                                returnNullWeight: false,
                                returnWeightLabel: true
                              })}
                            </DisplayValue>

                            <DisplayValue label="Declared Value" className="declaredValue mx-2 mb-3">
                              {declaredValue ?? '--'}
                            </DisplayValue>

                            {this.props.canViewCarrier && shipment.relationship_to_vendor?.vendor ? (
                              <DisplayValue label="Carrier" className="mx-2 mb-3">
                                {shipment.mode?.code === 'LTL' || shipment.mode?.code === 'VLTL' ? (
                                  <>
                                    {shipment.current_carrier?.name}
                                    {shipment.relationship_to_vendor?.carrier_status
                                      ? this.renderStatusTooltip(shipment.relationship_to_vendor.carrier_status)
                                      : null}
                                    {shipment.service_level ? <div>{shipment.service_level.description}</div> : null}
                                  </>
                                ) : (
                                  <>
                                    <div className="sidepanel-carrier-status">
                                      {shipment.relationship_to_vendor?.vendor?.name ? (
                                        <div>{shipment.relationship_to_vendor.vendor.name}</div>
                                      ) : null}
                                      {shipment.relationship_to_vendor?.carrier_status
                                        ? this.renderStatusTooltip(shipment.relationship_to_vendor.carrier_status)
                                        : null}
                                    </div>
                                    {shipmentPOC?.first_name ? (
                                      <div>{`Contact: ${shipmentPOC.first_name} ${shipmentPOC.last_name || ''}`}</div>
                                    ) : null}
                                    {shipmentPOC?.phone_number ? (
                                      <div>{getPhoneHyperlink(shipmentPOC.phone_number)}</div>
                                    ) : null}
                                    {shipmentPOC?.email ? <div>{shipmentPOC.email}</div> : null}
                                  </>
                                )}
                              </DisplayValue>
                            ) : null}

                            {!isLoadBoard ? (
                              <FinancialTenderingUserPermissionFallback
                                permissions={[VIEW_SHIPMENT_FINANCIALS_USER_PERMISSION]}
                              >
                                <DisplayValue label="Financials" className="mx-2 mb-3">
                                  {renderFinancialsTable(shipment)}
                                </DisplayValue>
                              </FinancialTenderingUserPermissionFallback>
                            ) : null}

                            {!isLoadBoard ? (
                              <>
                                <DisplayValue label="BOL #" className="mx-2 mb-3">
                                  {shipment.bol_number ?? '--'}
                                </DisplayValue>
                                <DisplayValue label="PO #" className="dashboard-summary-po mx-2 mb-3">
                                  {shipment.purchase_order_number ?? '--'}
                                </DisplayValue>
                                <DisplayValue label="Pickup #" className="mx-2 mb-3">
                                  {shipment.pickup_number ?? '--'}
                                </DisplayValue>
                                <DisplayValue label="PRO #" className="mx-2 mb-3">
                                  {shipment.pro_number ?? '--'}
                                </DisplayValue>

                                {hasParcel ? (
                                  <DisplayValue label="Tracking #" className="mx-2 mb-3">
                                    {shipment.tracking_number ?? '--'}
                                  </DisplayValue>
                                ) : null}
                                {hasDrayage ? (
                                  <>
                                    <DisplayValue label="Booking #" className="mx-2 mb-3">
                                      {shipment.drayage_booking_number ?? '--'}
                                    </DisplayValue>
                                    <DisplayValue label="Container #" className="mx-2 mb-3">
                                      {shipment.drayage_container_number ?? '--'}
                                    </DisplayValue>
                                    <DisplayValue label="Seal #" className="mx-2 mb-3">
                                      {shipment.drayage_seal_number ?? '--'}
                                    </DisplayValue>
                                    <DisplayValue label="Chassis #" className="mx-2 mb-3">
                                      {shipment.drayage_chassis_number ?? '--'}
                                    </DisplayValue>
                                  </>
                                ) : null}
                              </>
                            ) : null}
                          </div>
                        ) : null}
                        {tabsValue === TIMELINE ? (
                          <div className="mt-4 max-w-md overflow-y-auto px-2 h-screen-minus-56">
                            <LegacyTimelineContainer shipmentId={shipment.id} />
                          </div>
                        ) : null}

                        {!this.state.showRejectForm && tabsValue === MY_BIDS ? (
                          <div>
                            {quotes.length > 0 ? (
                              <div>
                                <Card
                                  className="bids__card mx-2 w-auto"
                                  title="My Bids"
                                  bodyClassName="bids__card-body px-4"
                                >
                                  {quotes.map((el) => {
                                    let equipmentModeDisplay;
                                    if (el.mode?.code && el.equipment_type?.name) {
                                      equipmentModeDisplay = el.mode.code + ', ' + el.equipment_type.name;
                                    } else if (el.mode?.code) {
                                      equipmentModeDisplay = el.mode.code;
                                    } else if (el.equipment_type?.name) {
                                      equipmentModeDisplay = el.equipment_type.name;
                                    }
                                    const {documents} = el.spot_negotiation_quote;

                                    return (
                                      <div key={el.id} className="loadBoard__bid flex-col border-b-sw-border">
                                        {el.reference_number ? (
                                          <strong className="flex w-full">Quote #{el.reference_number}</strong>
                                        ) : null}
                                        <div className="grid grid-cols-7">
                                          <div className="loadBoard__bid-info col-span-3 flex items-center">
                                            <span>{equipmentModeDisplay}</span>
                                          </div>
                                          <div className="loadBoard__bid-rate col-span-3 flex w-full pl-2">
                                            <span className="w-full">
                                              {formatCurrency(el.total, shipment.preferred_currency)}
                                            </span>
                                          </div>
                                          <div className="flex justify-end">
                                            {documents?.length ? (
                                              <DeprecatedButton
                                                variant="icon"
                                                onClick={() => this.setState({documentPreviewQuote: el})}
                                                icon={<SvgIcon name="Document" />}
                                              />
                                            ) : null}
                                            <DeprecatedButton
                                              variant="icon"
                                              disabled={isDeletingQuote}
                                              onClick={() => this.props.handleDeleteQuote(el)}
                                              icon={<SvgIcon name="TrashOutlined" />}
                                            />
                                          </div>
                                        </div>
                                        <div className="loadBoard__bid-date">
                                          Submitted {formatDateTime(el.created_at)}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </Card>
                                <QuoteDocumentModal
                                  spotNegotiationId={documentPreviewQuote?.spot_negotiation_quote.spot_negotiation}
                                  spotNegotiationQuoteId={documentPreviewQuote?.id}
                                  spotNegotiationQuoteDocumentId={
                                    documentPreviewQuote?.spot_negotiation_quote.documents[0]?.id
                                  }
                                  onClose={() => this.setState({documentPreviewQuote: null})}
                                />
                              </div>
                            ) : (
                              <div className="loadBoard__bid-none">
                                <h3>No Bids Submitted</h3>
                              </div>
                            )}
                          </div>
                        ) : null}
                        {tabsValue === 'messages' ? (
                          <div className="loadBoard__messages">
                            <Messages
                              shipmentResource={this.props.loadBoardShipmentDetails}
                              onSubmit={() =>
                                this.props.triggerReload(this.props.loadBoardShipmentDetails.load_board_id)
                              }
                              resourceType="SPOT_NEGOTIATION"
                              shouldPoll
                            />
                          </div>
                        ) : null}
                        {tabsValue === WORKFLOWS ? <DashboardSummaryWorkflow shipmentId={shipment?.id} /> : null}
                        {tabsValue === CARRIER_BIDS ? (
                          <DashboardSummaryBids
                            company={company}
                            featureFlags={featureFlags}
                            selectedShipment={shipmentDetails}
                          />
                        ) : null}
                      </>
                    )}
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

DashboardSummary.propTypes = {
  shipmentDetails: PropTypes.shape({
    id: PropTypes.string,
    metadata: PropTypes.shape({
      v3_shipment_reference_id: PropTypes.string
    })
  }),
  renderSidebarDropdownContent: PropTypes.func,
  shipmentLoaded: PropTypes.bool,
  onCloseSidebarDropdown: PropTypes.func,
  showEditStopDropdown: PropTypes.bool,
  ...{WithStatusToastsPropTypes}
};

export default compose(
  connect(
    (state) => ({
      breadcrumbs: state.shipmentdetails.breadcrumbs,
      unitPreferences: state.userCompany.unitPreferences
    }),
    {
      getBreadcrumbs
    }
  )
)(withStatusToasts(DashboardSummary));
