/* eslint-disable @typescript-eslint/no-unused-expressions */

import {useEffect, useState, useRef, useCallback} from 'react';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import {Formik, Form, Field} from 'formik';
import {object, mixed, number, string} from 'yup';
import classnames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  Title,
  FormikSelect,
  Rule,
  FormikPhoneInput,
  FormikTextInput,
  FormikTextarea,
  SvgIcon,
  Tooltip,
  Pill,
  InlineNotification,
  InlineNotificationVariantEnum
} from '@shipwell/shipwell-ui';
import {bindActionCreators} from 'redux';
import useCarrierConfigContract from './useCarrierConfigContract';
import {fetchServiceLevels} from 'App/actions/_shipments';
import {postCarrierConfig} from 'App/api/shipment';
import {WORKFLOW_WARNING_OVER_CAPACITY} from 'App/containers/workflows/workflowConstants';
import {
  getCarrierRelationshipsPromise,
  getCarrierPowerUnits,
  getCarrierLocationCapacityAvailability,
  putCarrierRelationshipsCarrierRelationshipIdPromise
} from 'App/api/carriers';
import {unpackErrors, cleanPayload, toTitleCase, permViewCarriers} from 'App/utils/globals';
import {checkShipmentModes} from 'App/utils/globalsTyped';
import getCellPhoneServiceProvider from 'App/utils/getCellPhoneServiceProvider';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';
import EditErrorDisplay from 'App/containers/Shipment/components/EditErrorDisplay';
import withFlags from 'App/utils/withFlags';
import {
  driverAllowedModes,
  powerUnitAllowedModes,
  trailerAllowedModes,
  temperatureRequiredEquipment,
  hasInstantRateBooked,
  getCurrentCarrier
} from 'App/containers/Shipment/components/CarrierEquipment/utils';
import {validatePhoneNumber} from 'App/components/forms/utils';
import {METRIC, convertFahrenheitToCelsius, convertCelsiusToFahrenheit} from 'App/utils/internationalConstants';
import {carrierEquipmentContexts} from 'App/containers/Shipment/components/CarrierEquipment/View';
import EditDriver from 'App/containers/Shipment/components/CarrierEquipment/Edit/EditDriver';
import ModeEquipmentFields from 'App/containers/Shipment/components/CarrierEquipment/Edit/components/modeEquipmentFields';
import {searchForCarrierByID} from 'App/actions/vendors';
import {ContractSelect} from 'App/formComponents/formSections/tenderRequest/ContractSelect';
import {SelectedContract} from 'App/formComponents/formSections/tenderRequest/SelectedContract';
import WarningNotification from 'App/containers/Book/FormSections/InlineNotification';
import useCheckIsInsuranceValid from 'App/data-hooks/carriers/useCheckIsInsuranceValid';
import {
  SHIPMENT_ASSIGN_CARRIER,
  CREATE_SHIPMENT_DIRECT_TENDER
} from 'App/components/permissions/PermissionsFallback/constants';
import {TenderingUserPermissionFallback} from 'App/components/permissions/PermissionsFallback/TenderingUserPermissionFallback';

const createValidationSchema = (platNewDriverWorkflow, hasInstantRate) =>
  object().shape({
    vendor: object().nullable(),
    temperature_lower_limit: mixed().when('equipment_type', {
      is: (val) => val && temperatureRequiredEquipment.includes(val.machine_readable),
      then: number()
        .nullable()
        .required('Minimum Temperature is required.')
        .typeError('Minimum Temperature must be a number.'),
      otherwise: number().nullable()
    }),
    temperature_upper_limit: mixed().when('equipment_type', {
      is: (val) => val && temperatureRequiredEquipment.includes(val.machine_readable),
      then: number()
        .nullable()
        .required('Maximum Temperature is required.')
        .typeError('Maximum Temperature must be a number.'),
      otherwise: number().nullable()
    }),
    vendor_point_of_contact: mixed().when('vendor', {
      is: (val) => !hasInstantRate && val,
      then: object().nullable().required('Point of contact is required.')
    }),
    driver: object()
      .shape({
        full_name: string().nullable(),
        phone_number: string()
          .nullable()
          .test('phone_number', 'A valid phone number is required.', function (value) {
            if (value) {
              return validatePhoneNumber(value);
            }
            if (!value && platNewDriverWorkflow && this.parent.full_name) {
              return false;
            }
            return true;
          })
      })
      .nullable()
  });

const Edit = ({
  dispatch,
  carrierConfig,
  serviceLevels,
  onCancelEdit,
  handleSuccess,
  handleWarning,
  initialCarrierDetails,
  shipment,
  setError,
  unitPreferences,
  isQuotingLimitedUser,
  user,
  userCompany,
  platNewDriverWorkflow,
  wfaLocationCapacity,
  context,
  searchForCarrierByID,
  fiCarrierComplianceInsurance
}) => {
  const [pocOptions, setPocOptions] = useState(initialCarrierDetails.point_of_contacts);
  const [driverOptions, setDriverOptions] = useState(initialCarrierDetails.drivers || []);
  const [cellValidationStatus, setCellValidationStatus] = useState(null);
  const [showLocationCapacityWarning, setShowLocationCapacityWarning] = useState(false);
  const [isCurrentCarrier, setIsCurrentCarrier] = useState(
    initialCarrierDetails.shipwell_vendor?.id === userCompany?.id
  );
  const [powerUnits, setPowerUnits] = useState([]);
  const [selectedPowerUnit, setSelectedPowerUnit] = useState({});
  const [selectedCarrier, setSelectedCarrier] = useState();
  //can be used to access Formik props
  const formRef = useRef();

  const shouldInputMetric = unitPreferences.system === METRIC;
  const hasInstantRate = hasInstantRateBooked(shipment);
  const {hasLTL} = checkShipmentModes(shipment?.mode);

  const {preferred_currency: currencyOfRecord, id: shipmentId, stops} = shipment;
  const stopAddresses = stops?.map((stop) => stop.location.address);

  const {
    selectedApplicableContract,
    handleContractChange,
    contractCarrierRelationship,
    handleUpdateFinancialsFromContract
  } = useCarrierConfigContract({shipment, setError});
  const selectedContract = selectedApplicableContract?.contract;

  const selectedContractRef = useRef(selectedContract);

  const {isInsuranceInvalid} = useCheckIsInsuranceValid(shipment, fiCarrierComplianceInsurance, [selectedCarrier]);

  useEffect(() => {
    const {setFieldValue} = formRef.current;
    if (setFieldValue) {
      if (selectedContract && !!contractCarrierRelationship?.id) {
        selectedContractRef.current = selectedContract;
        handleCarrierChange(contractCarrierRelationship, setFieldValue);
      } else if (selectedContractRef.current && !selectedContract) {
        selectedContractRef.current = undefined;
        handleCarrierChange(null, setFieldValue);
      }
    }
  }, [contractCarrierRelationship, handleCarrierChange, selectedContract]);

  useEffect(() => {
    dispatch(fetchServiceLevels());
  }, [dispatch]);

  const fetchCarriers = async (input) => {
    try {
      const response = await getCarrierRelationshipsPromise({
        q: input,
        carrierStatus: ['ACTIVE', 'INACTIVE', 'DO_NOT_USE']
      });
      return response.body.results;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchPowerUnits = async (company, input) => {
    try {
      if (company?.carrier || isCurrentCarrier) {
        const response = await getCarrierPowerUnits(isCurrentCarrier ? userCompany.carrier.id : company.carrier.id, {
          powerUnitNameContains: input
        });
        setPowerUnits(response.body?.results);
        return response.body?.results;
      }
      return [];
    } catch (error) {
      console.error(error);
    }
  };

  const handleCarrierChange = useCallback(
    async (val, setFieldValue) => {
      setPocOptions(val?.point_of_contacts || []);
      setDriverOptions(val?.drivers || []);
      setCellValidationStatus(null);

      setSelectedCarrier(val);

      if (val?.point_of_contacts.length === 1) {
        setFieldValue('vendor_point_of_contact', val.point_of_contacts[0]);
      } else {
        setFieldValue('vendor_point_of_contact', null);
      }
      setFieldValue('driver', null);
      setFieldValue('power_unit_name', null);
      setFieldValue('trailer_name', null);
      if (val) {
        setFieldValue('vendor', val.shipwell_vendor);
        setIsCurrentCarrier(false);
        if (wfaLocationCapacity) {
          const capacityResponse = await getCarrierLocationCapacityAvailability(shipmentId);
          setShowLocationCapacityWarning(!capacityResponse?.body?.capacity_available);
        }
      } else {
        setFieldValue('vendor', null);
        //back to initial state for carrier
        setIsCurrentCarrier(initialCarrierDetails.shipwell_vendor?.company?.id === userCompany?.id);
        setShowLocationCapacityWarning(false);
      }
    },
    [initialCarrierDetails.shipwell_vendor?.company?.id, shipmentId, userCompany?.id, wfaLocationCapacity]
  );

  const handlePowerUnitChange = (val, setFieldValue) => {
    setFieldValue('power_unit_name', val);
    const targetPowerUnit = powerUnits.find((powerUnit) => powerUnit.name === val);
    setSelectedPowerUnit(targetPowerUnit);
  };

  const handleSubmit = async (values, {setSubmitting, setErrors}) => {
    const payload = {...values};
    // if there's a name and phone but no id, this is a new driver
    const isNewDriver =
      platNewDriverWorkflow && payload.driver?.phone_number && payload.driver?.full_name && !payload.driver.id;
    // driver prop is an object, but we only set one field, the phone_number
    if (!payload.driver?.phone_number) {
      delete payload.driver;
    }
    if (payload?.power_unit_name) {
      // If user selects an existing power unit, assign its id, skip otherwise.
      if (selectedPowerUnit) {
        payload.power_unit_pk = selectedPowerUnit.id;
      }
    }
    if (!payload.equipment_type || !temperatureRequiredEquipment.includes(payload.equipment_type?.machine_readable)) {
      //delete any temps if not reefer
      payload.temperature_lower_limit = null;
      payload.temperature_upper_limit = null;
    }
    if (shouldInputMetric && payload.temperature_lower_limit && payload.temperature_upper_limit) {
      payload.temperature_lower_limit = convertCelsiusToFahrenheit(payload.temperature_lower_limit);
      payload.temperature_upper_limit = convertCelsiusToFahrenheit(payload.temperature_upper_limit);
    }
    //some carriers like LTL instant rates don't have a proper vendor POC. in that case just delete the field
    if (!payload.vendor_point_of_contact?.id) {
      delete payload.vendor_point_of_contact;
    }
    if (hasLTL && hasInstantRate) {
      payload.vendor = shipment.relationship_to_vendor.vendor;
    }

    try {
      // get the carrier relationship, needed for both driver work flow and real-time status checking
      const isQuotingLimitedUser = user?.is_quoting_limited_user;
      if (!isQuotingLimitedUser) {
        const relationshipsResponse = await getCarrierRelationshipsPromise({vendorId: payload.vendor?.id});
        // the first result should always be correct since we look up by ID
        const carrierRelationship =
          relationshipsResponse?.body?.results.length > 0 ? relationshipsResponse.body?.results?.[0] : {};
        if (isNewDriver && !isEmpty(carrierRelationship)) {
          // create the new driver on the carrier relationship
          await putCarrierRelationshipsCarrierRelationshipIdPromise(carrierRelationship.id, {
            ...carrierRelationship,
            drivers: [...(carrierRelationship.drivers || []), payload.driver]
          });
        }
      }
      const response = await postCarrierConfig(shipmentId, cleanPayload(payload));
      if (response?.ok) {
        if (selectedContract) {
          await handleUpdateFinancialsFromContract();
        }
        wfaLocationCapacity && showLocationCapacityWarning
          ? handleWarning('Location Capacity Exceeded!', WORKFLOW_WARNING_OVER_CAPACITY)
          : await handleSuccess();

        if (!isQuotingLimitedUser) {
          searchForCarrierByID(payload.vendor?.id);
        }
      }
    } catch (error) {
      console.error(error);
      setError('Error!', error.error_description);
      setErrors(unpackErrors(error.field_errors, {}));
    }
    setSubmitting(false);
  };

  const formatDropdownOptionWithStatus = ({name, status, isCustomOption = false}, {context}) => {
    /* upon selection, show name but not status */
    if (context === 'value' || isCustomOption) {
      return name;
    }
    return (
      <div className="shipment__carrierEquipment-carrierDisplay">
        <span>{name}</span>
        <Pill variant={status && ['ACTIVE'].includes(status.toUpperCase()) ? 'primary' : 'inactive'}>
          {status ? toTitleCase(status) : 'Missing'}
        </Pill>
      </div>
    );
  };

  const lookupPhoneNumber = async (event, {setErrors}) => {
    const {value} = event.target;
    if (!value) {
      setCellValidationStatus(null);
    } else if (value && validatePhoneNumber(value)) {
      try {
        const response = await getCellPhoneServiceProvider(value);
        if (response?.carrier_name) {
          setCellValidationStatus(response.carrier_name);
        }
      } catch (error) {
        setCellValidationStatus(null);
        setErrors({'driver.phone_number': error.message});
      }
    }
  };
  let initialMinTemp = carrierConfig.temperature_lower_limit;
  let initialMaxTemp = carrierConfig.temperature_upper_limit;
  if (shouldInputMetric) {
    initialMinTemp =
      carrierConfig.temperature_lower_limit && convertFahrenheitToCelsius(carrierConfig.temperature_lower_limit);
    initialMaxTemp =
      carrierConfig.temperature_upper_limit && convertFahrenheitToCelsius(carrierConfig.temperature_upper_limit);
  }

  let initialCarrierName = carrierConfig.vendor;
  if (hasInstantRate) {
    initialCarrierName = getCurrentCarrier(shipment);
  }

  const canEditCarrier = get(user, 'permissions', []).includes(permViewCarriers);

  return (
    <Formik
      initialValues={{
        ...carrierConfig,
        vendor: initialCarrierName,
        temperature_lower_limit: initialMinTemp,
        temperature_upper_limit: initialMaxTemp
      }}
      onSubmit={handleSubmit}
      validationSchema={createValidationSchema(platNewDriverWorkflow, hasInstantRate)}
      innerRef={formRef}
      enableReinitialize
    >
      {({values, setFieldValue, setTouched, errors, dirty, ...FormikProps}) => (
        <Form
          noValidate
          className={classnames('shipment__carrierEquipment-form', {
            'mb-24': context === carrierEquipmentContexts.ALERTS_DASHBOARD
          })}
        >
          {!isQuotingLimitedUser && context === carrierEquipmentContexts.SHIPMENT_DETAILS && (
            <>
              <Title variant="sectionTitle">Carrier</Title>
              <div className="field-grid grid-1-12">
                <TenderingUserPermissionFallback permissions={[CREATE_SHIPMENT_DIRECT_TENDER]}>
                  <div className="grid-item-1-12">
                    <ContractSelect
                      disabled={FormikProps.isSubmitting}
                      label="Load from contract"
                      contract={selectedContract}
                      onChange={handleContractChange}
                      modes={[values.mode?.code || shipment?.mode?.code]}
                      equipment={[
                        values.equipment_type?.machine_readable || shipment?.equipment_type?.machine_readable
                      ]}
                      stopAddresses={stopAddresses}
                      currencyOfRecord={currencyOfRecord}
                      shipmentId={shipmentId}
                    />
                  </div>
                </TenderingUserPermissionFallback>
                {selectedContract ? (
                  <div className="grid-item-1-12 mb-4 space-y-4">
                    <SelectedContract
                      applicableContractWithCharges={selectedApplicableContract}
                      modes={[shipment?.mode?.description]}
                      equipment={[shipment?.equipment_type?.name]}
                      shipmentId={shipmentId}
                    />
                  </div>
                ) : (
                  <TenderingUserPermissionFallback permissions={[SHIPMENT_ASSIGN_CARRIER]}>
                    <div className="grid-item-1-12 mb-4 space-y-4">
                      <Field
                        component={FormikSelect}
                        name="vendor"
                        async
                        disabled={!canEditCarrier || hasInstantRate}
                        defaultOptions
                        loadOptions={fetchCarriers}
                        formatOptionLabel={(opt, {context}) =>
                          formatDropdownOptionWithStatus(
                            {
                              name: get(opt, 'name') || get(opt, 'shipwell_vendor.name'),
                              status: get(opt, 'carrier_status')
                            },
                            {context}
                          )
                        }
                        label="Carrier"
                        onChange={(val) => handleCarrierChange(val, setFieldValue)}
                        isOptionDisabled={(option) => option.carrier_status === 'DO_NOT_USE'}
                      />
                    </div>
                  </TenderingUserPermissionFallback>
                )}
                {isInsuranceInvalid ? (
                  <div className="grid-item-1-12 mb-4 space-y-4">
                    <WarningNotification
                      type="error"
                      title="Carrier Insurance Limit Not Met!"
                      message="The carriers assigned to this shipment do not meet the insurance limit required for this shipment."
                    />
                  </div>
                ) : null}
              </div>
              {wfaLocationCapacity && showLocationCapacityWarning ? (
                <div className="-mt-3 mb-2">
                  <InlineNotification
                    title={WORKFLOW_WARNING_OVER_CAPACITY}
                    variant={InlineNotificationVariantEnum.warning}
                  />
                </div>
              ) : null}
              {!hasInstantRate && (
                <div className="field-grid grid-1-12">
                  <div className="grid-item-1-12">
                    <Field
                      component={FormikSelect}
                      name="vendor_point_of_contact"
                      options={pocOptions}
                      disabled={!canEditCarrier}
                      required={Boolean(values.vendor)}
                      clearable={false}
                      getOptionLabel={(option) => `${get(option, 'first_name')} ${get(option, 'last_name') || ''}`}
                      getOptionValue={(option) => get(option, 'id')}
                      label="Point of Contact"
                    />
                  </div>
                </div>
              )}
              <Rule />
            </>
          )}

          {driverAllowedModes.includes(values.mode?.code) && (
            <>
              <Title variant="sectionTitle">Driver</Title>
              {platNewDriverWorkflow ? (
                <EditDriver
                  carrierConfig={carrierConfig}
                  lookupPhoneNumber={lookupPhoneNumber}
                  cellValidationStatus={cellValidationStatus}
                  driverOptions={driverOptions}
                  setDriverOptions={setDriverOptions}
                />
              ) : (
                <div className="field-grid grid-1-12">
                  <div className="grid-item-1-12">
                    <Field
                      component={FormikPhoneInput}
                      successMessage={!errors?.driver?.phone_number && cellValidationStatus}
                      name="driver.phone_number"
                      label="Driver"
                      onChange={(e) => {
                        setFieldValue('driver.phone_number', e.target.value);
                        lookupPhoneNumber(e, FormikProps);
                      }}
                      onBlur={(e) => {
                        setTouched({'driver.phone_number': true});
                        lookupPhoneNumber(e, FormikProps);
                      }}
                    />
                  </div>
                </div>
              )}
              <Rule />
            </>
          )}
          <Title variant="sectionTitle">Equipment</Title>
          <ModeEquipmentFields
            disabled={isQuotingLimitedUser || context === carrierEquipmentContexts.ALERTS_DASHBOARD}
          />
          <div className="field-grid grid-1-12">
            {temperatureRequiredEquipment.includes(values.equipment_type?.machine_readable) && (
              <>
                <div className="grid-item-1-6">
                  <Field
                    component={FormikTextInput}
                    name="temperature_lower_limit"
                    label={`Minimum Temperature ${shouldInputMetric ? '(C)' : '(F)'}`}
                    required
                    disabled={isQuotingLimitedUser || context === carrierEquipmentContexts.ALERTS_DASHBOARD}
                  />
                </div>
                <div className="grid-item-7-12">
                  <Field
                    component={FormikTextInput}
                    name="temperature_upper_limit"
                    label={`Maximum Temperature ${shouldInputMetric ? '(C)' : '(F)'}`}
                    required
                    disabled={isQuotingLimitedUser || context === carrierEquipmentContexts.ALERTS_DASHBOARD}
                  />
                </div>
              </>
            )}
            {powerUnitAllowedModes.includes(values.mode?.code) && values.vendor?.carrier && (
              //show the power unit and trailer fields if a carrier is selected as the vendor
              <>
                {trailerAllowedModes.includes(values.mode?.code) && (
                  <div className="grid-item-1-6">
                    <Field component={FormikTextInput} name="trailer_name" label="Trailer" />
                  </div>
                )}
                <div
                  className={classnames(
                    trailerAllowedModes.includes(values.mode?.code) ? `grid-item-7-11` : `grid-item-1-11`
                  )}
                >
                  <Field
                    async
                    allowCreate
                    component={FormikSelect}
                    simpleValue
                    defaultOptions
                    defaultValue={{name: values.power_unit_name}}
                    simpleOptionValue="name"
                    formatOptionLabel={(opt, {context}) =>
                      formatDropdownOptionWithStatus(
                        {
                          name: get(opt, 'name'),
                          status: get(opt, 'status'),
                          isCustomOption: get(opt, 'isCustomOption')
                        },
                        {context}
                      )
                    }
                    getNewOptionData={(inputValue, label) => {
                      return {isCustomOption: true, name: label};
                    }}
                    formatCreateLabel={(label) => `Assign unrecognized power unit: ${label}`}
                    loadOptions={(input) => fetchPowerUnits(values.vendor, input)}
                    name="power_unit_name"
                    label="Power Unit"
                    onChange={(val) => handlePowerUnitChange(val, setFieldValue)}
                  />
                </div>
                <div className="grid-item-12-12 flex h-10 items-center justify-end">
                  <Tooltip
                    tooltipContent="Tracking begins two hours before planned pickup. You may change this field if the power unit changes."
                    tooltipClassname="shipment__carrierEquipment-standardTooltip "
                  >
                    <SvgIcon color="$sw-icon" name="InfoOutlined" />
                  </Tooltip>
                </div>
              </>
            )}
            {context === carrierEquipmentContexts.SHIPMENT_DETAILS && (
              <div className="grid-item-1-12">
                <Field
                  component={FormikSelect}
                  options={serviceLevels}
                  getOptionLabel={(option) => option.description}
                  getOptionValue={(option) => option.id}
                  name="service_level"
                  label="Service Level"
                  clearable={false}
                  disabled={isQuotingLimitedUser}
                />
              </div>
            )}
          </div>
          {context === carrierEquipmentContexts.SHIPMENT_DETAILS && (
            <>
              <Rule />
              <Title variant="sectionTitle">Notes for Carrier</Title>
              <div className="field-grid grid-1-12">
                <div className="grid-item-1-12">
                  <Field
                    component={FormikTextarea}
                    name="notes_for_carrier"
                    label="Notes for Carrier"
                    disabled={isQuotingLimitedUser}
                  />
                </div>
              </div>
            </>
          )}
          {values.vendor?.carrier && !values.vendor?.carrier.eld_providers?.length > 0 && (
            <div className="mt-2">
              Want to connect this carrier&apos;s fleet? Contact us at{' '}
              <a href="mailto:eld@shipwell.com">eld@shipwell.com</a>
            </div>
          )}
          <ModalFormFooter {...FormikProps} isValid={dirty || Boolean(selectedContract)} onCancel={onCancelEdit} />
          <EditErrorDisplay {...FormikProps} />
        </Form>
      )}
    </Formik>
  );
};

Edit.propTypes = {
  dispatch: PropTypes.func,
  serviceLevels: PropTypes.array,
  onCancelEdit: PropTypes.func,
  carrierConfig: PropTypes.object.isRequired,
  handleSuccess: PropTypes.func,
  handleWarning: PropTypes.func,
  shipment: PropTypes.object,
  setError: PropTypes.func,
  initialCarrierDetails: PropTypes.object,
  unitPreferences: PropTypes.object,
  context: PropTypes.string,
  isQuotingLimitedUser: PropTypes.bool,
  user: PropTypes.object,
  userCompany: PropTypes.object,
  platNewDriverWorkflow: PropTypes.bool,
  wfaLocationCapacity: PropTypes.bool,
  searchForCarrierByID: PropTypes.func,
  fiCarrierComplianceInsurance: PropTypes.bool
};

Edit.defaultProps = {
  dispatch: () => {},
  serviceLevels: [],
  onCancelEdit: () => {},
  carrierConfig: {},
  handleSuccess: () => {},
  handleWarning: () => {},
  shipment: {},
  setError: () => {},
  initialCarrierDetails: {},
  unitPreferences: {},
  searchForCarrierByID: () => {}
};

export default compose(
  withFlags('platNewDriverWorkflow', 'wfaLocationCapacity', 'fiCarrierComplianceInsurance'),
  connect(
    (state) => ({
      user: state.userProfile.user,
      serviceLevels: state.shipments.serviceLevels,
      isQuotingLimitedUser: state.userProfile.user.is_quoting_limited_user,
      unitPreferences: state.userCompany.unitPreferences,
      userCompany: state.userCompany.company
    }),
    (dispatch) => ({
      dispatch,
      ...bindActionCreators(
        {
          searchForCarrierByID
        },
        dispatch
      )
    })
  )
)(Edit);
